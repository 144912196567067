import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import assets from "./assets";
import bets from "./bets";
import users from "./users";
import profitability from "./profitability";
import bankRoller from "./bankRoller";
import currentUser from "./currentUser";
import affiliates from "./affiliates";
import durations from "./durations";
import instruments from "./instruments";
import riskAnalysis from "./riskAnalysis";

export default combineReducers({
  assets,
  bets,
  users,
  profitability,
  bankRoller,
  currentUser,
  affiliates,
  durations,
  instruments,
  riskAnalysis,
  routing: routerReducer,
});
