import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const ENTRY_POINT = "root";

Sentry.init({
  dsn: "https://b40d22108bfff205e0ea7bfe73c866d8@o415134.ingest.us.sentry.io/4507345953947656",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,
});

ReactDOM.render(<App />, document.getElementById(ENTRY_POINT));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
