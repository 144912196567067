import * as React from "react";
import * as S from "./styles";

const TableSwitcher = ({ isYear, handleTableSwitch }) => {
  return (
    <S.TableSwitcher active={!isYear}>
      <input
        type="checkbox"
        onClick={e => handleTableSwitch(e.target.checked)}
        checked={isYear}
      />
      <S.TableSwitcherText>Year</S.TableSwitcherText>
      <S.TableSwitcherText>Month</S.TableSwitcherText>
    </S.TableSwitcher>
  )
};

export default TableSwitcher;
