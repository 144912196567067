import styled from "styled-components";
import Input from "@material-ui/core/Input";

import { white } from "../../styles/colors";

export const MUIInput = styled(Input)`
  && {
    input {
      text-transform: lowercase;
    }
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  background: ${white};
`;

export const SignInWrapper = styled.div`
  width: 400px;
  height: 400px;
  padding-top: 20%;
`;

export const Error = styled.div`
  color: red;
  text-align: center;
  width: 100%;
  text-transform: capitalize;
  padding-top: 20px;
  font-weight: bold;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
`;

export const FormControls = styled.div`
  padding: 15px 0 50px 0;
`;

export const Form = styled.form`
  width: 100%;
`;
