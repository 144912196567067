import { createAction } from "redux-actions";

const actionTypeName = "@bo/riskAnalusis/";

export const GET_MANAGEMENT_REQUEST = `${actionTypeName}GET_MANAGEMENT_REQUEST`;
export const GET_MANAGEMENT_SUCCESS = `${actionTypeName}GET_MANAGEMENT_SUCCESS`;
export const GET_MANAGEMENT_FAIL = `${actionTypeName}GET_MANAGEMENT_FAIL`;

export const getManagementRequest = createAction(GET_MANAGEMENT_REQUEST);
export const getManagementSuccess = createAction(GET_MANAGEMENT_SUCCESS);
export const getManagementFail = createAction(GET_MANAGEMENT_FAIL);

export const GET_MANAGEMENT_DURATION_REQUEST = `${actionTypeName}GET_MANAGEMENT_DURATION_REQUEST`;
export const GET_MANAGEMENT_DURATION_SUCCESS = `${actionTypeName}GET_MANAGEMENT_DURATION_SUCCESS`;
export const GET_MANAGEMENT_DURATION_FAIL = `${actionTypeName}GET_MANAGEMENT_DURATION_FAIL`;

export const getManagementDurationRequest = createAction(GET_MANAGEMENT_DURATION_REQUEST);
export const getManagementDurationSuccess = createAction(GET_MANAGEMENT_DURATION_SUCCESS);
export const getManagementDurationFail = createAction(GET_MANAGEMENT_DURATION_FAIL);
