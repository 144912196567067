import { createAction } from "redux-actions";

const actionTypeName = "@bo/users/";

// Get Users

export const GET_USERS_REQUEST = `${actionTypeName}GET_USERS_REQUEST`;
export const GET_USERS_SUCCESS = `${actionTypeName}GET_USERS_SUCCESS`;
export const GET_USERS_FAIL = `${actionTypeName}GET_USERS_FAIL`;

export const getUsersRequest = createAction(GET_USERS_REQUEST);
export const getUsersSuccess = createAction(GET_USERS_SUCCESS);
export const getUsersFail = createAction(GET_USERS_FAIL);

// Delete Session

export const DELETE_USER_SESSION_REQUEST = `${actionTypeName}DELETE_USER_SESSION_REQUEST`;
export const DELETE_USER_SESSION_SUCCESS = `${actionTypeName}DELETE_USER_SESSION_SUCCESS`;
export const DELETE_USER_SESSION_FAIL = `${actionTypeName}DELETE_USER_SESSION_FAIL`;

export const deleteUserSessionRequest = createAction(
  DELETE_USER_SESSION_REQUEST
);
export const deleteUserSessionSuccess = createAction(
  DELETE_USER_SESSION_SUCCESS
);
export const deleteUserSessionFail = createAction(DELETE_USER_SESSION_FAIL);

// Delete All Sessions

export const DELETE_ALL_USERS_SESSION_REQUEST = `${actionTypeName}DELETE_ALL_USERS_SESSION_REQUEST`;
export const DELETE_ALL_USERS_SESSION_SUCCESS = `${actionTypeName}DELETE_ALL_USERS_SESSION_SUCCESS`;
export const DELETE_ALL_USERS_SESSION_FAIL = `${actionTypeName}DELETE_ALL_USERS_SESSION_FAIL`;

export const deleteAllUsersSessionRequest = createAction(
  DELETE_ALL_USERS_SESSION_REQUEST
);
export const deleteAllUsersSessionSuccess = createAction(
  DELETE_ALL_USERS_SESSION_SUCCESS
);
export const deleteAllUsersSessionFail = createAction(
  DELETE_ALL_USERS_SESSION_FAIL
);

// Delete User

export const DELETE_USER_REQUEST = `${actionTypeName}DELETE_USER_REQUEST`;

export const deleteUserRequest = createAction(DELETE_USER_REQUEST);
