import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";
import {
  GET_PROFITABILITY_SUCCESS,
  GET_PROFITABILITY_SUMMARY_SUCCESS,
  GET_PROFITABILITY_FAIL,
} from "../actions/profitability";

export default handleActions(
  {
    [GET_PROFITABILITY_SUCCESS]: (state, action) => {
      const type = action.payload.type;
      const profit = action.payload.profit;
      state[`${type}`] = profit;

      return { ...state };
    },
    [GET_PROFITABILITY_SUMMARY_SUCCESS]: (state, action) => {
      return {
        ...state,
        profitabilitySummary: action.payload, 
      };
    },
    [GET_PROFITABILITY_FAIL]: () => initialState.profits,
  },
  initialState.bets
);
