export default (mtm) => {
  let i = 0;
  // eslint-disable-next-line no-param-reassign
  mtm = parseFloat(mtm);
  while (mtm !== Math.round(mtm)) {
    // eslint-disable-next-line no-param-reassign
    mtm *= 10;
    i++;
  }
  return i;
};
