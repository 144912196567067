import SignIn from "../pages/SignIn";
import Assets from "../pages/Assets";
import Bets from "../pages/Bets";
import Users from "../pages/Users";
import Profitability from "../pages/Profitability";
import Bankroller from "../pages/Bankroller";
import Maintenance from '../pages/Maintenance';
import Affiliates from '../pages/Affiliates';
import RiskAnalysisManagement from '../pages/RiskAnalysisManagement';


export const SIGN_IN_PAGE = "/",
  ASSETS_PAGE = "/assets",
  BETS_PAGE = "/bets",
  USERS_PAGE = "/users",
  PROFITABILITY_PAGE = "/profitability",
  MAINTENANCE_PAGE = "/maintenance",
  BANKROLLER_PAGE = "/syndicate",
  RISK_ANALYSIS_MANAGMENT_PAGE = "/risk";

export default [
  {
    path: SIGN_IN_PAGE,
    component: SignIn,
    exact: true,
  },
  {
    path: ASSETS_PAGE,
    component: Assets,
    exact: true,
  },
  {
    path: BETS_PAGE,
    component: Bets,
    exact: true,
  },
  {
    path: USERS_PAGE,
    component: Users,
    exact: true,
  },
  {
    path: PROFITABILITY_PAGE,
    component: Profitability,
    exact: true,
  },
  {
    path: BANKROLLER_PAGE,
    component: Bankroller,
    exact: true,
  },
  {
    path: MAINTENANCE_PAGE,
    component: Maintenance,
    exact: true,
  },
  {
    path: RISK_ANALYSIS_MANAGMENT_PAGE,
    component: RiskAnalysisManagement,
    exact: true,
  }
];
