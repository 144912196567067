import styled, { css } from "styled-components";

export const TableSwitcher = styled.label`
  position: relative;
  background-color: rgba(151,161,173,0.8);
  border-radius: 24px;
  display: flex;
  min-width: 150px;
  width: 150px;
  cursor: pointer;
  padding: 0;
  font-size: 13px;
  line-height: 18px;
  height: 24px;
  color: #fff;

  &:before {
    content: '';
    position: absolute;
    left: -4px;
    top: -4px;
    bottom: -4px;
    width: calc(50% + 8px);
    background-color: #3f51b5;
    border-radius: 24px;
  }
  
  ${({ active }) => active && css`
    &:before {
      left: calc(50% - 4px);
    }
  `}

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }
`;

export const TableSwitcherText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 10px;
  position: relative;
  z-index: 2;
  width: 50%;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
`;

