import React, { Component } from "react";
import { connect } from "react-redux";
import { DatePicker } from 'material-ui-pickers';

import Paper from "@material-ui/core/Paper";
import Card from "./components/Card";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import {
  startOfMonth, endOfMonth, parseISO, addMonths, getDaysInMonth, format,
} from 'date-fns';

import {
  getLastDayPeriod,
  getLastWeekPeriod,
  getLastMonthPeriod,
} from "../../utils/timePeriods";
import ProfitabilityChart from './components/ProfitabilityChart';
import objToQueary from "../../utils/objToQueary";
import { getProfitabilityRequest, getProfitabilitySummaryRequest } from "../../store/actions/profitability";
import Layout from "../../components/Layout";
import { SIGN_IN_PAGE } from "../../config/routes";

import "./styles.scss";

const now = new Date();
const datePattern = 'yyyy-MM-dd';

class Profitability extends Component {
  constructor(props) {
    super(props);
    const { profitability } = props;

    this.state = {
      idOfEditableItem: null,
      profitability,
      selectedMonth: format(now, datePattern),
      summaryTimeRange: null,
      summaryStagesCount: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      profitability: nextProps.profitability,
    });
  }

  componentDidMount() {
    const { getProfitability, getProfitabilitySummary, history } = this.props;

    // Get profitability data for info-cards
    const queries = [];

    const dayPeriod = getLastDayPeriod();
    const dayQuery = objToQueary(dayPeriod);
    queries.push({ type: "last_day", query: dayQuery });

    const monthPeriod = getLastMonthPeriod();
    const monthQuery = objToQueary(monthPeriod);
    queries.push({ type: "last_month", query: monthQuery });

    const weekPeriod = getLastWeekPeriod();
    const weekQuery = objToQueary(weekPeriod);
    queries.push({ type: "last_week", query: weekQuery });

    queries.forEach(({ query, type }) =>
      getProfitability({
        onError: () => history.push(SIGN_IN_PAGE),
        params: query,
        type,
      })
    );

    // Get profitability month summary, required for chart to be built.
    const desiredStagesCount = getDaysInMonth(now);

    getProfitabilitySummary({
      onError: (e) => console.log(e),
      params: objToQueary({
        ...monthPeriod,
        stages: desiredStagesCount,
      }),
    });

    this.setState({ summaryTimeRange: monthPeriod, summaryStagesCount: desiredStagesCount });
  }

  handleDateChange = date => {
    const { getProfitabilitySummary } = this.props;
    
    this.setState(
      {
        selectedMonth: format(date, datePattern),
        summaryStagesCount: getDaysInMonth(date),
        summaryTimeRange: {
          date_from: startOfMonth(date),
          date_to: endOfMonth(date),
        },
      }, () => {
        getProfitabilitySummary({
          onError: (e) => console.log(e),
          params: objToQueary({
            ...this.state.summaryTimeRange,
            stages: getDaysInMonth(date),
          }),
        });
      }
    );
  }

  handleMonthChange = (direction = 'forward') => {
    const { selectedMonth } = this.state;
    const newDate = addMonths(parseISO(selectedMonth), direction === 'forward' ? 1 : -1);
    this.handleDateChange(newDate);
  };

  render() {
    const { profitability, selectedMonth, summaryTimeRange, summaryStagesCount } = this.state;
    const { profitabilitySummary } = this.props;

    const cardsNaming = {
      last_day: "Yesterday",
      last_week: "Last week",
      last_month: "This Month",
    };
    const cards = [];

    for (const key in cardsNaming) {
      cards.push(<Card title={cardsNaming[key]} amount={profitability[key]} />);
    }

    return (
      <Layout title="Profitability">
        <Paper>
          <div className="cards">{[...cards]}</div>
        </Paper>

        <Paper style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20}}>

        Display profitability chart for: 

        <DatePicker
            value={selectedMonth}
            onChange={this.handleDateChange}
            views={['year', 'month']}
            TextFieldComponent={({ onClick }) => (
              <>
                <Button onClick={() => this.handleMonthChange('back')}>
                  <ArrowLeftIcon />
                </Button>
                <Typography
                  onClick={onClick}
                  component="h6"
                  variant="h6"
                  style={{ fontWeight: 100, alignSelf: 'center', cursor: 'pointer' }}
                >
                  {format(new Date(selectedMonth), 'MMM yyyy')}
                </Typography>
                <Button onClick={() => this.handleMonthChange('forward')}>
                  <ArrowRightIcon />
                </Button>
              </>
            )}
          />
        </Paper>
        <ProfitabilityChart
          selectedMonth={selectedMonth}
          timeRange={summaryTimeRange}
          stagesCount={summaryStagesCount}
          profitabilitySummary={profitabilitySummary}
        />
      </Layout>
    );
  }
}

const mapState = (state) => ({
  profitability: state.profitability,
  profitabilitySummary: state.profitability.profitabilitySummary,
});

const mapDispatch = {
  getProfitability: getProfitabilityRequest,
  getProfitabilitySummary: getProfitabilitySummaryRequest,
};

export default connect(mapState, mapDispatch)(Profitability);
