import { createAction } from "redux-actions";

const actionTypeName = "@bo/user/";

// Get Current User

export const GET_CURRENT_USER_REQUEST = `${actionTypeName}GET_CURRENT_USER_REQUEST`;
export const GET_CURRENT_USER_SUCCESS = `${actionTypeName}GET_CURRENT_USER_SUCCESS`;
export const GET_CURRENT_USER_FAIL = `${actionTypeName}GET_CURRENT_USER_FAIL`;

export const getCurrentUserRequest = createAction(GET_CURRENT_USER_REQUEST);
export const getCurrentUserSuccess = createAction(GET_CURRENT_USER_SUCCESS);
export const getCurrentUserFail = createAction(GET_CURRENT_USER_FAIL);

// Update Current User

export const UPDATE_CURRENT_USER_REQUEST = `${actionTypeName}UPDATE_CURRENT_USER_REQUEST`;
export const UPDATE_CURRENT_USER_SUCCESS = `${actionTypeName}UPDATE_CURRENT_USER_SUCCESS`;
export const UPDATE_CURRENT_USER_FAIL = `${actionTypeName}UPDATE_CURRENT_USER_FAIL`;

export const updateCurrentUserRequest = createAction(UPDATE_CURRENT_USER_REQUEST);
export const updateCurrentUserSuccess = createAction(UPDATE_CURRENT_USER_SUCCESS);
export const updateCurrentUserFail = createAction(UPDATE_CURRENT_USER_FAIL);

/*
  getting investment actions:
*/
export const GET_INVESTMENT_REQUEST = `${actionTypeName}GET_INVESTMENT_REQUEST`,
  GET_INVESTMENT_SUCCESS = `${actionTypeName}GET_INVESTMENT_SUCCESS`,
  GET_INVESTMENT_ERROR = `${actionTypeName}GET_INVESTMENT_ERROR`;

export const getInvestmentRequestAction = createAction(GET_INVESTMENT_REQUEST),
  getInvestmentSuccess = createAction(GET_INVESTMENT_SUCCESS),
  getInvestmentError = createAction(GET_INVESTMENT_ERROR);

/*
  create investment actions:
*/
export const CREATE_INVESTMENT_REQUEST = `${actionTypeName}CREATE_INVESTMENT_REQUEST`,
  CREATE_INVESTMENT_SUCCESS = `${actionTypeName}CREATE_INVESTMENT_SUCCESS`,
  CREATE_INVESTMENT_ERROR = `${actionTypeName}CREATE_INVESTMENT_ERROR`;

export const createInvestmentRequestAction = createAction(
    CREATE_INVESTMENT_REQUEST
  ),
  createInvestmentSuccessAction = createAction(CREATE_INVESTMENT_SUCCESS),
  createInvestmentErrorAction = createAction(CREATE_INVESTMENT_ERROR);

/*
  update investment actions:
*/
export const UPDATE_INVESTMENT_REQUEST = `${actionTypeName}UPDATE_INVESTMENT_REQUEST`,
  UPDATE_INVESTMENT_SUCCESS = `${actionTypeName}UPDATE_INVESTMENT_SUCCESS`,
  UPDATE_INVESTMENT_ERROR = `${actionTypeName}UPDATE_INVESTMENT_ERROR`;

export const updateInvestmentRequestAction = createAction(
    UPDATE_INVESTMENT_REQUEST
  ),
  updateInvestmentSuccessAction = createAction(UPDATE_INVESTMENT_SUCCESS),
  updateInvestmentErrorAction = createAction(UPDATE_INVESTMENT_ERROR);

// Expiry timer

export const SET_TIMER_ID = `${actionTypeName}SET_TIMER_ID`;
export const REMOVE_TIMER_ID = `${actionTypeName}REMOVE_TIMER_ID`;

export const setTimerIdAction = createAction(SET_TIMER_ID);
export const removeTimerIdAction = createAction(REMOVE_TIMER_ID);

// Timer

export const ENABLE_TIMER = `${actionTypeName}ENABLE_TIMER`;
export const DISABLE_TIMER = `${actionTypeName}DISABLE_TIMER`;

export const enableTimerAction = createAction(ENABLE_TIMER);
export const disableTimerAction = createAction(DISABLE_TIMER);
