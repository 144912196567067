import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { store } from "react-notifications-component";

import { logOut } from "../../store/actions/signIn";
import {
  enableTimerAction,
  disableTimerAction,
  setTimerIdAction,
  removeTimerIdAction,
} from "../../store/actions/currentUser";

import * as ROUTES from "../../config/routes";

import checkTockenExpiry from "../../helpers/checkTockenExpiry";

import { getCurrentUserRequest } from "../../store/actions/currentUser";
import { extendExpiryDateRequest } from "../../store/actions/signIn";

// import MUI
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SettingsIcon from '@material-ui/icons/Settings';
import ChartIcon from "@material-ui/icons/Equalizer";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Menu from "@material-ui/icons/Menu";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import Group from "@material-ui/icons/Group";
import DomainIcon from "@material-ui/icons/Domain";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

import Link from "../Link";
import User from "../User";

import "react-notifications-component/dist/theme.css";

import {
  MUIDrawer,
  Content,
  LayoutWrapper,
  PageTitle,
  HeadContent,
  MUINavButton,
  NavLogo,
} from "./styles";

class Layout extends Component {
  state = {
    openNav: false,
  };

  componentDidMount() {
    const {
      getCurrentUser,
      enableTimer,
      setTimerId,
      removeTimerId,
      extendExpiryDate,
      isTimerEnabled,
      timerId,
    } = this.props;

    if (!isTimerEnabled) {
      const timeoutId = checkTockenExpiry(
        setTimerId,
        removeTimerId,
        extendExpiryDate,
        timerId,
        enableTimer
      );
      timeoutId && this.setState({ timeoutId });
    }

    getCurrentUser({});
  }

  handleLogOut = () => {
    const { logOut, history, timerId } = this.props;
    const { timeoutId } = this.state;
    clearInterval(timeoutId);

    store.removeNotification(timerId);

    logOut({
      onSuccess: () => {
        history.push(ROUTES.SIGN_IN_PAGE);
      },
    });
  };

  render() {
    const {
      fullScreen,
      children,
      title,
      currentUser,
      match: { path },
    } = this.props;

    return (
      <LayoutWrapper>
        <MUIDrawer variant="permanent" open={this.state.openNav}>
          <MUINavButton
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => this.setState({ openNav: !this.state.openNav })}
          >
            {this.state.openNav ? (
              <>
                <CancelPresentationIcon style={{ marginRight: 5 }} /> Close
              </>
            ) : (
              <Menu />
            )}
          </MUINavButton>

          <Divider />

          <Link to={ROUTES.BETS_PAGE}>
            <ListItem button selected={path === ROUTES.BETS_PAGE}>
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              Trades
            </ListItem>
          </Link>

          <Divider />

          <List disablePadding={true}>
            <Link to={ROUTES.ASSETS_PAGE}>
              <ListItem button selected={path === ROUTES.ASSETS_PAGE}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                Assets
              </ListItem>
            </Link>
            <Divider />

            <Link to={ROUTES.RISK_ANALYSIS_MANAGMENT_PAGE}>
              <ListItem button selected={path === ROUTES.RISK_ANALYSIS_MANAGMENT_PAGE}>
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                {this.state.openNav && 'Risk Matrix'}
              </ListItem>
            </Link>
            <Divider />

            <Link to={ROUTES.USERS_PAGE}>
              <ListItem button selected={path === ROUTES.USERS_PAGE}>
                <ListItemIcon>
                  <Group />
                </ListItemIcon>
                Users
              </ListItem>
            </Link>

            <Divider />
            <Link to={ROUTES.PROFITABILITY_PAGE}>
              <ListItem button selected={path === ROUTES.PROFITABILITY_PAGE}>
                <ListItemIcon>
                  <ChartIcon />
                </ListItemIcon>
                Profitability
              </ListItem>
            </Link>
            <Divider />

            <Link to={ROUTES.BANKROLLER_PAGE}>
              <ListItem button selected={path === ROUTES.BANKROLLER_PAGE}>
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                Syndicate
              </ListItem>
            </Link>
            <Divider />

            <Link to={ROUTES.MAINTENANCE_PAGE}>
              <ListItem button selected={path === ROUTES.MAINTENANCE_PAGE}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                Maintenance
              </ListItem>
            </Link>
            <Divider />
          </List>

          <Divider
            style={{
              marginTop: "auto",
              opacity: this.state.openNav ? "1" : "0",
            }}
          />

          <NavLogo isOpenNav={this.state.openNav}>
            Bitcoin&nbsp;Options&nbsp;
          </NavLogo>
        </MUIDrawer>

        <Content fullScreen={fullScreen}>
          <HeadContent>
            <PageTitle>{title}</PageTitle>

            <User onLogout={() => this.handleLogOut} user={currentUser} />
          </HeadContent>

          {children}
        </Content>
      </LayoutWrapper>
    );
  }
}

const mapState = (state) => {
  return {
    currentUser: state.currentUser,
    timerId: state.currentUser.timerId,
    isTimerEnabled: state.currentUser.isTimerEnabled,
  };
};

const mapDispatch = {
  logOut,
  enableTimer: enableTimerAction,
  disableTimer: disableTimerAction,
  setTimerId: setTimerIdAction,
  removeTimerId: removeTimerIdAction,
  getCurrentUser: getCurrentUserRequest,
  extendExpiryDate: extendExpiryDateRequest,
};

const enhancer = compose(withRouter, connect(mapState, mapDispatch));

export default enhancer(Layout);
