import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import initialState from "../config/initialState";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

const store = createStore(
  rootReducer,
  initialState,
  bindMiddleware([sagaMiddleware])
);

store.runSagaTask = () => {
  store.sagaTask = sagaMiddleware.run(rootSaga);
};

store.runSagaTask();

export default store;
