import styled from "styled-components";

const FormWrap = styled.div`
  margin-bottom: 20px;
  padding: 14px 20px 30px;
  background-color: #fdfcfc;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  
  form {
    display: flex;
    align-items: center;
    margin-right: 10px;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  form > button.MuiButton-root {
    margin-left: 10px;
  }
  
  & > button.MuiButton-root {
    margin-left: 0;
  }
`;

export default FormWrap;
