import styled from "styled-components";

export const MaintananceContainer = styled.div`
  width: 95%;
  margin: auto;
  
  & > div {
    margin: 0 0 20px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TradingStatus = styled.span`
  color: ${({ disabled }) => disabled ? 'red' : '#51e02d'}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-left: 40%;
  color: #fff;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  margin-right: 20px;
`;
