import React from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import NotificationSystem from "react-notification-system";
import NotificationItem from "../components/NotificationMessage";

const style = {
  Containers: {
    DefaultStyle: {
      marginTop: 53,
      width: 20,
      top: "18px"
    },
    tr: {
      width: 355
    },
    tc: {
      width: 450
    }
  },
  MessageWrapper: {
    DefaultStyle: {
      // paddingRight: 33,
      // width: "100%",
      // height: "100%"
    }
  },
  NotificationItem: {
    DefaultStyle: {
      boxShadow: "0 0 10px rgba(0,0,0,0.15)",
      borderRadius: 2,
      // minHeight: 65,
      display: "flex",
      alignItems: "center",
      cursor: "initial",
      padding: 0,
      borderTop: "none",
      backgroundColor: "#fff",
      color: "#79879b"
    },
    success: {
      // borderTop: `3px solid ${GREEN_COLOR}`
    },
    error: {
      // borderTop: `3px solid ${RED_LINE_COLOR}`
    }
  },

  Dismiss: {
    DefaultStyle: {
      backgroundColor: "none",
      fontSize: "28px",
      display: "none"
    }
  }
};

export default function(WrappedComponent) {
  class Notifications extends React.Component {
    notificationSystem = React.createRef();

    showNotification = args => {
      if (this.notificationSystem.current) {
        this.notificationSystem.current.addNotification({
          ...args,
          position: args.position || "tc",
          message: (
            <NotificationItem
              msg={args.message}
              status={args.status || args.level}
            />
          )
        });
      }
    };

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showNotification={this.showNotification}
          />
          <NotificationSystem style={style} ref={this.notificationSystem} />
        </>
      );
    }
  }

  return hoistNonReactStatics(Notifications, WrappedComponent);
}
