import styled, { css } from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MUITextField } from "../../styles";

export const MUITableCell = styled(TableCell)`
  thead & {
    color: #3f51b5;
  }
  
  ${MUITextField} {
    max-height: 100%;
    
    & > div {
      height: 100%;
    }
  }
  
  && {
    font-size: 14px;
    border: 3px solid #dfdfdf;
    border-left: 0.5px dashed #dfdfdf;
    border-right: 0.5px dashed #dfdfdf;
    padding: 10px;
    
    &:first-child {
      border-left: none;
    }

    &:nth-child(3) {
      border-right: 3px solid #bababa;
    }

    &:nth-child(7) {
      border-right: 3px solid #bababa;
    }
    
    &:nth-child(11) {
      border-right: 3px solid #bababa;
    }

    &:last-child {
      border: 3px solid #dfdfdf;
      border-left: 0.5px dashed #dfdfdf;
      border-right: none;
    }

    ${({ bold }) => bold && css`
      font-weight: bold;
      color: #000;
    `}
  }
`;

export const MUITableRow = styled(TableRow)`
  && {
    height: 53px;
    
    thead & {
      ${MUITableCell} {
        color: #fff;  
        position: sticky;
        top: 48px;
        background-color: #7e7b7b;
      }
      
      &:first-child {  
        ${MUITableCell} {
          border: 3px solid #bababa;
          border-top-color: #424242;
          text-align: center;
          background-color: #424242;
          height: 30px;
          top: -2px;
          
          &:first-child {
            border-left: none;
          }
          
          &:last-child {
            border-right: none;
          }
        }
      }
    }

    td:last-child {
      width: 100px;

      div {
        justify-content: center;
      }
    }
    
    ${({ footer }) => footer && `
      background: #e6e4e4;
    `}
  }
`;

const status = {
  default: css`
    color: #000;
  `,
  positive: css`
    color: #45b67e;
  `,
  negative: css`
    color: #c6282f;
  `
};

export const TableVal = styled.span`
  ${props => {
    if (props.theme > 0) return status.positive;
    if (props.theme < 0) return status.negative;
    return status.default;
  }};
`;
