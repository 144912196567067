import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";
import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  DELETE_ALL_USERS_SESSION_SUCCESS,
  DELETE_ALL_USERS_SESSION_FAIL
} from "../actions/users";

export default handleActions(
  {
    [GET_USERS_SUCCESS]: (state, action) => action.payload.users,
    [GET_USERS_FAIL]: () => initialState.users,

    [DELETE_ALL_USERS_SESSION_SUCCESS]: (state) => {
      return [
        ...state
      ];
    },
    [DELETE_ALL_USERS_SESSION_FAIL]: (state) => {
      return [
        ...state
      ];
    },
  },
  initialState.users
);
