/* eslint-disable no-unused-expressions */
import React from "react";
import { format } from "date-fns";
import { Formik, Form } from "formik";
import * as yup from "yup";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  MUIFormControl,
  MUIButton,
  MUIFormRow
} from "../../../../components/Mui";

import * as S from './styles';

let ManagementSearchSchema = yup.object().shape({
  expiries: yup.string(),
  date_from: yup
    .date()
    .typeError("Value must be a date")
    .nullable(),
  date_to: yup
    .date()
    .typeError("Value must be a date")
    .nullable()
    .min(yup.ref("date_from"), "Date To must be later")
});

const ManagementSearchBar = ({ pushToSearch, searchObj, defaultValues }) => {
  const handleClearFilter = () => {
    pushToSearch({
      date_from: defaultValues.date_from,
      date_to: defaultValues.date_to,
    });
  };

  return (
    <Formik
      initialValues={{
        date_from: searchObj.date_from || defaultValues.date_from,
        date_to: searchObj.date_to || defaultValues.date_to
      }}
      validationSchema={ManagementSearchSchema}
      onSubmit={(values) => { pushToSearch({...values}) }}
    >
      {({
          errors,
          setFieldValue,
          handleBlur,
          touched,
          values
        }) => {
        return (
          <S.FormWrap>
            <Form>
              <MUIFormRow container>
                <MUIFormControl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="date-picker-from"
                      name="date_from"
                      label="Date From"
                      helperText={touched.date_from ? errors.date_from : ""}
                      error={touched.date_from && errors.date_from}
                      value={values.date_from}
                      onChange={(value) => setFieldValue("date_from", `${format(value, "yyyy-MM-dd")} 00:00:00`)}
                      onBlur={handleBlur("date_from")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </MUIFormControl>

                <MUIFormControl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="date-picker-to"
                      name="date_to"
                      label="Date To"
                      helperText={touched.date_to ? errors.date_to : ""}
                      error={touched.date_to && errors.date_to}
                      value={values.date_to}
                      onChange={(value) => setFieldValue("date_to", `${format(value, "yyyy-MM-dd")} 23:59:59`)}
                      onBlur={handleBlur("date_to")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </MUIFormControl>

                <div>
                  <MUIButton
                    type="reset"
                    size="medium"
                    variant="contained"
                    onClick={handleClearFilter}
                  >
                    Clear
                  </MUIButton>

                  <MUIButton
                    type="submit"
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setFieldValue("date_from", `${format(new Date, "yyyy-MM-dd")} 00:00:00`);
                      setFieldValue("date_to", `${format(new Date, "yyyy-MM-dd")} 23:59:59`)
                    }}
                  >
                    Today
                  </MUIButton>

                  <MUIButton
                    type="submit"
                    size="medium"
                    variant="contained"
                    color="primary"
                  >
                    Filter
                  </MUIButton>
                </div>
              </MUIFormRow>
            </Form>
          </S.FormWrap>
        );
      }}
    </Formik>
  );
};

export default ManagementSearchBar;
