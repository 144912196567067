import { createAction } from "redux-actions";

const actionTypeName = "@bo/durations/";

export const GET_DURATIONS_REQUEST = `${actionTypeName}GET_DURATIONS_REQUEST`;
export const GET_DURATIONS_SUCCESS = `${actionTypeName}GET_DURATIONS_SUCCESS`;
export const GET_DURATIONS_FAIL = `${actionTypeName}GET_DURATIONS_FAIL`;

export const getDurationsRequest = createAction(GET_DURATIONS_REQUEST);
export const getDurationsSuccess = createAction(GET_DURATIONS_SUCCESS);
export const getDurationsFail = createAction(GET_DURATIONS_FAIL);
