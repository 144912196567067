import * as React from "react";

import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import CheckIcon from "@material-ui/icons/Check";

import styles from "./styles.module.scss";

export default ({ msg, status }) => (
  <div className={styles.notification}>
    <div
      className={`
        ${styles.icon_wrapper}
        ${(status === 'error') || (status === 'lost') ? styles.failure : ''}
        ${(status === 'won') || (status === 'success') ? styles.success : ''}
        ${status === 'drawn' && styles.drawn}
      `}
    >
      {(status === 'error') || (status === 'lost')
        ? <span className={styles.notification_icon}><ErrorIcon/></span>
        : null}

      {(status === 'won') || (status === 'success')
        ?  <span className={styles.notification_icon}><CheckIcon/></span>
        : null}
    </div>

    <span className={styles.notification__content}>{msg}</span>

    <span className={styles.notification_close}>
      <CloseIcon/>
    </span>
  </div>
);
