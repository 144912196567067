import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";
import {
  GET_AFFILIATES_SUCCESS,
  GET_AFFILIATES_FAIL
} from "../actions/affiliates";

export default handleActions(
  {
    [GET_AFFILIATES_SUCCESS]: (state, action) => {
      return {
        ...state,
        stake_profit_sum: action.payload.stake_profit_sum,
        house_profit_sum: action.payload.house_profit_sum,
        items: action.payload.bets
      }
    },
    [GET_AFFILIATES_FAIL]: () => initialState.affiliates
  },
  initialState.affiliates
);
