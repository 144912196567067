import React from "react";
import moment from 'moment';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

import * as S from './styles';

const TableBankRoller = ({ isYear, dataTable }) => {
  const formatNumber = (num) => {
    return num ? num.toFixed(2) : 0;
  };

  const total = (arr, key) => {
    const totalVal = !!arr ? arr.reduce((a, b) => a + (b[key] || 0), 0) : 0;
    return formatNumber(totalVal);
  };

  return (
    <>
      <Table>
        <TableHead>
          <S.MUITableRow>
            <S.MUITableCell colSpan="3">Total</S.MUITableCell>
            <S.MUITableCell colSpan="4">BB</S.MUITableCell>
            <S.MUITableCell colSpan="4">UP</S.MUITableCell>
            <S.MUITableCell colSpan="3">3rd Party</S.MUITableCell>
          </S.MUITableRow>

          <S.MUITableRow>
            <S.MUITableCell>Date/Month</S.MUITableCell>
            <S.MUITableCell>Turnover</S.MUITableCell>
            <S.MUITableCell>Profit</S.MUITableCell>

            <S.MUITableCell>Stake</S.MUITableCell>
            <S.MUITableCell>% of Syndicate</S.MUITableCell>
            <S.MUITableCell>Cut</S.MUITableCell>
            <S.MUITableCell>3rd Party Cut</S.MUITableCell>

            <S.MUITableCell>Stake</S.MUITableCell>
            <S.MUITableCell>% of Syndicate</S.MUITableCell>
            <S.MUITableCell>Cut</S.MUITableCell>
            <S.MUITableCell>3rd Party Cut</S.MUITableCell>

            <S.MUITableCell>Stake</S.MUITableCell>
            <S.MUITableCell>% of Syndicate</S.MUITableCell>
            <S.MUITableCell>Cut</S.MUITableCell>
          </S.MUITableRow>
        </TableHead>

        <TableBody>
          {dataTable.common && dataTable.common.length ? dataTable.common.map(item => {
            const currentDay = item.date_from;
            const commonTd = dataTable.common.find(common => common.date_from === currentDay);
            const flexTd = dataTable.flex.find(flex => flex.date_from === currentDay);
            const upTd = dataTable.up.find(up => up.date_from === currentDay);
            const thirdPartyTd = dataTable.thirdparty.find(thirdparty => thirdparty.date_from === currentDay);

            return (
              <S.MUITableRow
                key={item.date_from}
              >
                <S.MUITableCell>{currentDay ? (isYear ? moment(currentDay).format('MMM') : moment(currentDay).format('DD')) : '-'}</S.MUITableCell>
                <S.MUITableCell>{commonTd && formatNumber(commonTd.turnover)}</S.MUITableCell>
                <S.MUITableCell bold="true">
                  <S.TableVal theme={commonTd.profit}>{commonTd && formatNumber(commonTd.profit)}</S.TableVal>
                </S.MUITableCell>

                <S.MUITableCell>{flexTd && formatNumber(flexTd.turnover)}</S.MUITableCell>
                <S.MUITableCell>{flexTd && `${formatNumber(flexTd.percent)}%`}</S.MUITableCell>
                <S.MUITableCell bold="true">
                  <S.TableVal theme={flexTd.profit}>{flexTd && formatNumber(flexTd.profit)}</S.TableVal>
                </S.MUITableCell>
                <S.MUITableCell>
                  <S.TableVal theme={flexTd.thirdparty_profit}>{flexTd && formatNumber(flexTd.thirdparty_profit)}</S.TableVal>
                </S.MUITableCell>

                <S.MUITableCell>{upTd && formatNumber(upTd.turnover)}</S.MUITableCell>
                <S.MUITableCell>{upTd && `${formatNumber(upTd.percent)}%`}</S.MUITableCell>
                <S.MUITableCell bold="true">
                  <S.TableVal theme={upTd.profit}>{upTd && formatNumber(upTd.profit)}</S.TableVal>
                </S.MUITableCell>
                <S.MUITableCell>
                  <S.TableVal theme={upTd.thirdparty_profit}>{upTd && formatNumber(upTd.thirdparty_profit)}</S.TableVal>
                </S.MUITableCell>

                <S.MUITableCell>{thirdPartyTd && formatNumber(thirdPartyTd.turnover)}</S.MUITableCell>
                <S.MUITableCell>{thirdPartyTd && `${formatNumber(thirdPartyTd.percent)}%`}</S.MUITableCell>
                <S.MUITableCell bold="true">
                  <S.TableVal theme={thirdPartyTd.profit}>{thirdPartyTd && formatNumber(thirdPartyTd.profit)}</S.TableVal>
                </S.MUITableCell>
              </S.MUITableRow>
            )
          }) : null}

          {dataTable.common && dataTable.common.length ? (
            <S.MUITableRow footer>
              <S.MUITableCell>
                <b>Total:</b>
              </S.MUITableCell>
              <S.MUITableCell>
                {total(dataTable.common, 'turnover')}
              </S.MUITableCell>
              <S.MUITableCell bold="true">
                <S.TableVal theme={total(dataTable.common, 'profit')}>
                  {total(dataTable.common, 'profit')}
                </S.TableVal>
              </S.MUITableCell>

              <S.MUITableCell />
              <S.MUITableCell />
              <S.MUITableCell bold="true">
                <S.TableVal theme={total(dataTable.flex, 'profit')}>
                  {total(dataTable.flex, 'profit')}
                </S.TableVal>
              </S.MUITableCell>
              <S.MUITableCell>
                <S.TableVal theme={total(dataTable.flex, 'thirdparty_profit')}>
                  {total(dataTable.flex, 'thirdparty_profit')}
                </S.TableVal>
              </S.MUITableCell>

              <S.MUITableCell />
              <S.MUITableCell />
              <S.MUITableCell bold="true">
                <S.TableVal theme={total(dataTable.up, 'profit')}>
                  {total(dataTable.up, 'profit')}
                </S.TableVal>
              </S.MUITableCell>
              <S.MUITableCell>
                <S.TableVal theme={total(dataTable.up, 'thirdparty_profit')}>
                  {total(dataTable.up, 'thirdparty_profit')}
                </S.TableVal>
              </S.MUITableCell>

              <S.MUITableCell />
              <S.MUITableCell />
              <S.MUITableCell bold="true">
                <S.TableVal theme={total(dataTable.thirdparty, 'profit')}>
                  {total(dataTable.thirdparty, 'profit')}
                </S.TableVal>
              </S.MUITableCell>
            </S.MUITableRow>
          ) : null }
        </TableBody>
      </Table>
    </>
  )
};

export default TableBankRoller;
