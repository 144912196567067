import { createAction } from "redux-actions";

const actionTypeName = "@bo/bets/";

export const GET_BETS_REQUEST = `${actionTypeName}GET_BETS_REQUEST`;
export const GET_BETS_SUCCESS = `${actionTypeName}GET_BETS_SUCCESS`;
export const GET_BETS_FAIL = `${actionTypeName}GET_BETS_FAIL`;

export const GET_BETS_STATE_REQUEST = `${actionTypeName}GET_BETS_STATE_REQUEST`;
export const GET_BETS_STATE_SUCCESS = `${actionTypeName}GET_BETS_STATE_SUCCESS`;
export const GET_BETS_STATE_FAIL = `${actionTypeName}GET_BETS_STATE_FAIL`;

export const TOGGLE_TRADING_STATE_REQUEST = `${actionTypeName}TOGGLE_TRADING_STATE_REQUEST`;
export const TOGGLE_TRADING_STATE_SUCCESS = `${actionTypeName}TOGGLE_TRADING_STATE_SUCCESS`;
export const TOGGLE_TRADING_STATE_FAIL = `${actionTypeName}TOGGLE_TRADING_STATE_FAIL`;

export const getBetsRequest = createAction(GET_BETS_REQUEST);
export const getBetsSuccess = createAction(GET_BETS_SUCCESS);
export const getBetsFail = createAction(GET_BETS_FAIL);

export const getBetsStateRequest = createAction(GET_BETS_STATE_REQUEST);
export const getBetsStateSuccess = createAction(GET_BETS_STATE_SUCCESS);
export const getBetsStateFail = createAction(GET_BETS_STATE_FAIL);

export const toggleTradingStateRequest = createAction(TOGGLE_TRADING_STATE_REQUEST);
export const toggleTradingStateSuccess = createAction(TOGGLE_TRADING_STATE_SUCCESS);
export const toggleTradingStateFail = createAction(TOGGLE_TRADING_STATE_FAIL);

/* create investment actions: */
export const GET_MASTER_WALLET_BALANCE_REQUEST = `${actionTypeName}GET_MASTER_WALLET_BALANCE_REQUEST`;
export const GET_MASTER_WALLET_BALANCE_SUCCESS = `${actionTypeName}GET_MASTER_WALLET_BALANCE_SUCCESS`;
export const GET_MASTER_WALLET_BALANCE_FAIL = `${actionTypeName}GET_MASTER_WALLET_BALANCE_FAIL`;

export const getMasterWalletBalanceRequest = createAction(GET_MASTER_WALLET_BALANCE_REQUEST);
export const getMasterWalletBalanceSuccess = createAction(GET_MASTER_WALLET_BALANCE_SUCCESS);
export const getMasterWalletBalanceFail = createAction(GET_MASTER_WALLET_BALANCE_FAIL);
