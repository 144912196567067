import styled from 'styled-components';

export const Label = styled.label`
  display: inline-block;
  width: 50px;
  min-width: 50px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
`;

export const CheckboxSwitcher = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .05);
  transition: background-color .3s ease-in-out;
  z-index: 2;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &:before {
    border-radius: 50%;
    top: 50%;
    left: 2px;
    width: 16px;
    height: 16px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
    border: solid 0.5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: -10px 0 0;
    position: absolute;
    content: '';
    transition: left .15s ease-in-out;
  }

  &:after {
    content: 'Off';
    border-radius: 50%;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    position: absolute;
    margin: 0;
    opacity: .8;
    z-index: 1;
    font-size: 12px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
  }
  
  
`;

export const Input = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: background-color .3s ease-in-out;
  z-index: -1;
  opacity: 0;
  border: none;
  box-shadow: none;
  margin: 0;

  &:checked + ${CheckboxSwitcher} {
    background-color: #3f51b5;

    &:before {
      left: 28px;
      border: solid 0.5px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    }

    &:after {
      left: 0;
      right: auto;
      content: 'On';
      color: #fff;
    }
  }

  &:disabled + ${CheckboxSwitcher} {
    pointer-events: none;
    opacity: .6;
  }
`;
