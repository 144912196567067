import { takeLatest, call, put, takeEvery, select } from "redux-saga/effects";
import Cookies from "js-cookie";

import { TOKEN_KEY, COOKIE_EXPIRATION_KEY, HTTP_METHODS } from "../../config";

import apiCaller from "../../utils/apiCaller";
import {
  SIGN_IN_REQUEST,
  EXTEND_EXPIRY_DATE_REQUEST,
  LOG_OUT,
} from "../actions/signIn";

import { disableTimerAction } from "../../store/actions/currentUser";

import getExpiriesWithTZ from "../../helpers/getExpiriesWithTZ";

function* signIn({ payload: { data, onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.POST, {
      url: "/api/admin/sessions",
      payload: {
        user: data,
      },
    });

    const {
      data: { token, expiries_at },
    } = response;

    const expiresDate = getExpiriesWithTZ(expiries_at);

    yield call([Cookies, Cookies.set], TOKEN_KEY, token, {
      expires: expiresDate,
    });

    yield call(
      [window.localStorage, window.localStorage.setItem],
      COOKIE_EXPIRATION_KEY,
      expiresDate
    );

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (err) {
    if (onError && err.data) {
      yield call(onError, err.data.errors);
    }
  }
}

function* extendExpiryDate({ payload: { onError, onSuccess } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.PUT, {
      url: "/api/admin/sessions",
      token: true,
      payload: {
        token: Cookies.get("token"),
      },
    });

    const {
      data: { token, expiries_at },
    } = response;

    const expiresDate = getExpiriesWithTZ(expiries_at);

    yield call([Cookies, Cookies.set], TOKEN_KEY, token, {
      expires: expiresDate,
    });

    yield call(
      [window.localStorage, window.localStorage.setItem],
      COOKIE_EXPIRATION_KEY,
      expiresDate
    );

    if (onSuccess) {
      const timerId = yield select((state) => state.currentUser.timerId);

      yield call(onSuccess, timerId);
    }

    const disableTimerSuccess = yield call(disableTimerAction);
    yield put(disableTimerSuccess);

    // Force reload because of bug with the store (except new props -> previous props) in Layout cmp
    window.location.assign(window.location);
  } catch (err) {
    if (onError && err.data) {
      yield call(onError, err.data.errors);
    }
  }
}

function* handleLogOut({ payload: { onSuccess } }) {
  yield call([Cookies, Cookies.remove], TOKEN_KEY);

  yield call(
    [window.localStorage, window.localStorage.removeItem],
    COOKIE_EXPIRATION_KEY
  );

  yield call(onSuccess);
}

export default function*() {
  yield takeLatest(SIGN_IN_REQUEST, signIn);
  yield takeLatest(EXTEND_EXPIRY_DATE_REQUEST, extendExpiryDate);

  yield takeEvery(LOG_OUT, handleLogOut);
}
