import { createAction } from "redux-actions";

const actionTypeName = "@bo/signin/";

export const SIGN_IN_REQUEST = `${actionTypeName}SIGN_IN_REQUEST`;
export const SIGN_IN_SUCCESS = `${actionTypeName}SIGN_IN_SUCCESS`;
export const SIGN_IN_FAIL = `${actionTypeName}SIGN_IN_FAIL`;

export const signInRequest = createAction(SIGN_IN_REQUEST);
export const signInSuccess = createAction(SIGN_IN_SUCCESS);
export const signInFail = createAction(SIGN_IN_FAIL);

export const EXTEND_EXPIRY_DATE_REQUEST = `${actionTypeName}EXTEND_EXPIRY_DATE_REQUEST`;
export const EXTEND_EXPIRY_DATE_SUCCESS = `${actionTypeName}EXTEND_EXPIRY_DATE_SUCCESS`;
export const EXTEND_EXPIRY_DATE_FAIL = `${actionTypeName}EXTEND_EXPIRY_DATE_FAIL`;

export const extendExpiryDateRequest = createAction(EXTEND_EXPIRY_DATE_REQUEST);
export const extendExpiryDateSuccess = createAction(EXTEND_EXPIRY_DATE_SUCCESS);
export const extendExpiryDateFail = createAction(EXTEND_EXPIRY_DATE_FAIL);

export const LOG_OUT = `${actionTypeName}LOG_OUT`;
export const logOut = createAction(LOG_OUT);
