import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";
import {
  GET_INSTRUMENTS_SUCCESS,
  GET_INSTRUMENTS_FAIL
} from "../actions/instruments";

export default handleActions(
  {
    [GET_INSTRUMENTS_SUCCESS]: (state, action) => action.payload.instruments,
    [GET_INSTRUMENTS_FAIL]: () => initialState.instruments
  },
  initialState.instruments
);
