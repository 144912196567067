export default {
  'BFULLL': "CrashCoin",
  'LALLLL': "Shilling",
  'MLMLLL': "SpoofCoin",
  'WKWLLL': "Ker'ching",
  'G100'  : "MemeCoin",
  '5YR'   : "MoonShine",
  '10YR'  : "TwoBit",
  'BMSTM' : "BubbleBit",
  'CRDM'  : "No-Coiner",
  'KNBW'  : "WenLADACoin"
};
