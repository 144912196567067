import React, { Component } from "react";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import { BrowserRouter } from "react-router-dom";

import ReactNotification from "react-notifications-component";

import "react-notifications-component/dist/theme.css";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "material-ui-pickers";

// import other
import routes from "./config/routes";
import store from "./store";

/* eslint-disable */
Number.prototype.toFixedNoRounding = function(n) {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
  const a = this.toString().match(reg)[0];
  const dot = a.indexOf(".");
  if (dot === -1) { // integer, insert decimal dot and pad up zeros
    return a + "." + "0".repeat(n);
  }
  const b = n - (a.length - dot) + 1;
  return b > 0 ? (a + "0".repeat(b)) : a;
};
/* eslint-enable */

class App extends Component {
  render() {
    // setTimeout(this.notify.call(this, store), 3000);
    return (
      <>
        <ReactNotification />
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
          </MuiPickersUtilsProvider>{" "}
        </Provider>
      </>
    );
  }
}

export default App;
