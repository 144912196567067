import React from "react";
import { format } from "date-fns";
import { Formik, Form } from "formik";
import * as yup from "yup";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  MUIFormControl,
  MUITextField,
  MUIButton,
  MUIFormRow
} from "../../../../components/Mui";

import * as S from './styles';

let AffiliatesSearchSchema = yup.object().shape({
  referral_id: yup.string(),
  date_from: yup
    .date()
    .typeError("Value must be a date")
    .nullable(),
  date_to: yup
    .date()
    .typeError("Value must be a date")
    .nullable()
    .min(yup.ref("date_from"), "Date To must be later")
});

const AffiliatesSearchBar = ({ pushToSearch, searchObj, house_profit_sum, stake_profit_sum }) => {
  const handleClearFilter = () => {
    pushToSearch({ order_by: { field: "referral_id", direction: "desc" }});
  };

  return (
    <Formik
      initialValues={{
        referral_id: searchObj.referral_id || "",
        date_from: searchObj.date_from || null,
        date_to: searchObj.date_to || null
      }}
      validationSchema={AffiliatesSearchSchema}
      onSubmit={(values) => { pushToSearch({...values, order_by: { field: "referral_id", direction: "desc" }}) }}
    >
      {({
          errors,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          values
        }) => {
        return (
          <S.FormWrap>
            <Form>
              <MUIFormRow container>
                <MUIFormControl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="date-picker-from"
                      name="date_from"
                      label="Date From"
                      helperText={touched.date_from ? errors.date_from : ""}
                      error={touched.date_from && errors.date_from}
                      value={values.date_from}
                      onChange={(value) => setFieldValue("date_from", `${format(value, "yyyy-MM-dd")} 00:00:00`)}
                      onBlur={handleBlur("date_from")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  {stake_profit_sum || stake_profit_sum === 0
                    ? (<S.HouseProfitSum>
                      <S.HouseProfitSumTitle>Total Staked:</S.HouseProfitSumTitle>
                      <S.HouseProfitSumValue theme={stake_profit_sum}>{stake_profit_sum.toFixed(3)}</S.HouseProfitSumValue>
                    </S.HouseProfitSum>)
                    : null}
                </MUIFormControl>

                <MUIFormControl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="date-picker-to"
                      name="date_to"
                      label="Date To"
                      helperText={touched.date_to ? errors.date_to : ""}
                      error={touched.date_to && errors.date_to}
                      value={values.date_to}
                      onChange={(value) => setFieldValue("date_to", `${format(value, "yyyy-MM-dd")} 23:59:59`)}
                      onBlur={handleBlur("date_to")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  {house_profit_sum || house_profit_sum === 0
                    ? (<S.HouseProfitSum>
                      <S.HouseProfitSumTitle>Total House Profit:</S.HouseProfitSumTitle>
                      <S.HouseProfitSumValue theme={house_profit_sum}>{house_profit_sum.toFixed(3)}</S.HouseProfitSumValue>
                    </S.HouseProfitSum>)
                    : null}
                </MUIFormControl>

                <MUIFormControl style={{ justifyContent: 'flex-end' }}>
                  {/*
                  <MUITextField
                    error={errors.referral_id && touched.referral_id}
                    value={values.referral_id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="referral_id"
                    label="Refferal Id"
                    name="referral_id"
                    helperText={
                      errors.referral_id && touched.referral_id ? errors.referral_id : ""
                    }
                  />
                  */}

                  {house_profit_sum && stake_profit_sum
                    ? (<S.HouseProfitSum>
                      <S.HouseProfitSumTitle>Profit Margin:</S.HouseProfitSumTitle>
                      <S.HouseProfitSumValue theme={(house_profit_sum/stake_profit_sum)}>{(house_profit_sum/stake_profit_sum).toFixed(3)}</S.HouseProfitSumValue>
                    </S.HouseProfitSum>)
                    : null}
                </MUIFormControl>

                <div>
                  <MUIButton
                    type="reset"
                    size="medium"
                    variant="contained"
                    onClick={handleClearFilter}
                  >
                    Clear
                  </MUIButton>

                  <MUIButton
                    type="submit"
                    size="medium"
                    variant="contained"
                    color="primary"
                  >
                    Filter
                  </MUIButton>
                </div>
              </MUIFormRow>
            </Form>
          </S.FormWrap>
        );
      }}
    </Formik>
  );
};

export default AffiliatesSearchBar;
