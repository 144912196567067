import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";
import {
    GET_DURATIONS_SUCCESS,
    GET_DURATIONS_FAIL
} from "../actions/durations";

export default handleActions(
  {
    [GET_DURATIONS_SUCCESS]: (state, action) => action.payload.durations,
    [GET_DURATIONS_FAIL]: () => initialState.durations
  },
  initialState.durations
);
