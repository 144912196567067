import { takeLatest, call, put } from "redux-saga/effects";

import apiCaller from "../../utils/apiCaller";
import {
  GET_BANKROLLER_RATES_REQUEST,
  getBankRollerRatesSuccess,
  getBankRollerRatesFail,
  EDIT_BANKROLLER_RATES_REQUEST,
  editBankRollerRatesSuccess,
  editBankRollerRatesFail,
  GET_BANKROLLER_INVESTMENT_REQUEST,
  getBankRollerInvestmentSuccess,
  getBankRollerInvestmentFail,
  GET_BANKROLLER_SPECIAL_USERS_REQUEST,
  getBankRollerSpecialUsersSuccess,
  getBankRollerSpecialUsersFail,
  SET_ROLE_SPECIAL_USERS_REQUEST,
  setRoleSpecialUsersSuccess,
  setRoleSpecialUsersFail,
  getBankRollerSpecialUsersRequest,
  CURRENT_SPECIAL_STAKES_REQUEST,
  currentSpecialStakesSuccess,
  currentSpecialStakesFail,
  currentSpecialStakesRequest
} from "../actions/bankRoller";
import { HTTP_METHODS } from "../../config";

function* getBankrollerRates({ payload: { onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: "/api/admin/investments/rates",
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getBankRollerRatesSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getBankRollerRatesFail, err);
    yield put(errAction);
  }
}

function* editBankrollerRates({ payload: { id, data, onSuccess, onError } }) {
  try {
    const requestPayload = {
      url: `/api/admin/investments/rates/${id}`,
      payload: {
        ...data
      },
      token: true
    };

    const response = yield call(apiCaller, HTTP_METHODS.PUT, requestPayload);

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(editBankRollerRatesSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(editBankRollerRatesFail, err);
    yield put(errAction);
  }
}

function* getBankrollerInvestment({ payload: { onSuccess, onError, params = "" } }) {
  try {
    const requestPayload = {
      url: `/api/admin/investments/summary${params}`,
      token: true
    };

    const response = yield call(apiCaller, HTTP_METHODS.GET, requestPayload);

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getBankRollerInvestmentSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getBankRollerInvestmentFail, err);
    yield put(errAction);
  }
}

function* getBankrollerSpecialUsers({ payload: { onSuccess, onError } }) {
  try {
    const requestPayload = {
      url: '/api/admin/investments/special-users',
      token: true
    };

    const response = yield call(apiCaller, HTTP_METHODS.GET, requestPayload);

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getBankRollerSpecialUsersSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getBankRollerSpecialUsersFail, err);
    yield put(errAction);
  }
}

function* setRoleSpecialUser({ payload: { data, onSuccess, onError } }) {
  try {
    const requestPayload = {
      url: '/api/admin/investments/set-special-user',
      payload: {
        ...data
      },
      token: true
    };

    const response = yield call(apiCaller, HTTP_METHODS.PUT, requestPayload);

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(setRoleSpecialUsersSuccess, response);
    yield put(successAction);

    const getSpecialUser = yield call(getBankRollerSpecialUsersRequest, response);
    yield put(getSpecialUser);

    const getSpecialStakes = yield call(currentSpecialStakesRequest, response);
    yield put(getSpecialStakes);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(setRoleSpecialUsersFail, err);
    yield put(errAction);
  }
}

function* getCurrentSpecialStakes({ payload: { onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: "/api/admin/investments/current-special-stakes",
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(currentSpecialStakesSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(currentSpecialStakesFail, err);
    yield put(errAction);
  }
}

export default function*() {
  yield takeLatest(GET_BANKROLLER_RATES_REQUEST, getBankrollerRates);
  yield takeLatest(EDIT_BANKROLLER_RATES_REQUEST, editBankrollerRates);
  yield takeLatest(GET_BANKROLLER_INVESTMENT_REQUEST, getBankrollerInvestment);
  yield takeLatest(GET_BANKROLLER_SPECIAL_USERS_REQUEST, getBankrollerSpecialUsers);
  yield takeLatest(SET_ROLE_SPECIAL_USERS_REQUEST, setRoleSpecialUser);
  yield takeLatest(CURRENT_SPECIAL_STAKES_REQUEST, getCurrentSpecialStakes);
}
