import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";
import {
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER_FAIL,
  GET_INVESTMENT_SUCCESS,
  GET_INVESTMENT_ERROR,
  CREATE_INVESTMENT_SUCCESS,
  CREATE_INVESTMENT_ERROR,
  UPDATE_INVESTMENT_SUCCESS,
  UPDATE_INVESTMENT_ERROR,
  ENABLE_TIMER,
  DISABLE_TIMER,
  SET_TIMER_ID,
  REMOVE_TIMER_ID,
} from "../actions/currentUser";
import { LOG_OUT } from "../actions/signIn";

export default handleActions(
  {
    [GET_CURRENT_USER_SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload.data,
    }),
    [GET_CURRENT_USER_FAIL]: () => initialState.currentUser,

    [UPDATE_CURRENT_USER_SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload.data,
    }),
    [UPDATE_CURRENT_USER_FAIL]: () => initialState.currentUser,

    [GET_INVESTMENT_SUCCESS]: (state, action) => {
      const {
        payload: { data },
      } = action;

      return {
        ...state,
        investment: data,
      };
    },
    [GET_INVESTMENT_ERROR]: (state) => {
      return {
        ...state,
        investment: {
          ...state.investment,
          amount: null,
          repeatable: false,
        },
      };
    },

    [CREATE_INVESTMENT_SUCCESS]: (state, action) => {
      const {
        payload: { data },
      } = action;

      return {
        ...state,
        investment: data,
      };
    },
    [CREATE_INVESTMENT_ERROR]: (state, action) => {
      const {
        payload: {
          data: { errors: user_status },
        },
      } = action;
      return {
        ...state,
        error: user_status,
      };
    },

    [UPDATE_INVESTMENT_SUCCESS]: (state, action) => {
      const {
        payload: { data },
      } = action;

      return {
        ...state,
        investment: data,
      };
    },
    [UPDATE_INVESTMENT_ERROR]: (state, action) => {
      const {
        payload: {
          data: { errors: user_status },
        },
      } = action;
      return {
        ...state,
        error: user_status,
      };
    },

    [ENABLE_TIMER]: (state) => ({ ...state, isTimerEnabled: true }),
    [DISABLE_TIMER]: (state) => ({
      ...state,
      isTimerEnabled: false,
      timerId: null,
    }),

    [SET_TIMER_ID]: (state, action) => {
      const {
        payload: { timerId },
      } = action;

      return {
        ...state,
        timerId,
        isTimerEnabled: true,
      };
    },
    [REMOVE_TIMER_ID]: (state) => ({
      ...state,
      timerId: null,
      isTimerEnabled: false,
    }),
    [LOG_OUT]: () => ({ ...initialState }),
  },
  initialState.currentUser
);
