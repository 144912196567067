import { createAction } from "redux-actions";

const actionTypeName = "@bo/bets/";

export const GET_AFFILIATES_REQUEST = `${actionTypeName}GET_AFFILIATES_REQUEST`;
export const GET_AFFILIATES_SUCCESS = `${actionTypeName}GET_AFFILIATES_SUCCESS`;
export const GET_AFFILIATES_FAIL = `${actionTypeName}GET_AFFILIATES_FAIL`;

export const getAffiliatesRequest = createAction(GET_AFFILIATES_REQUEST);
export const getAffiliatesSuccess = createAction(GET_AFFILIATES_SUCCESS);
export const getAffiliatesFail = createAction(GET_AFFILIATES_FAIL);
