import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";
import {
  GET_MANAGEMENT_SUCCESS,
  GET_MANAGEMENT_FAIL,
  GET_MANAGEMENT_DURATION_SUCCESS,
  GET_MANAGEMENT_DURATION_FAIL
} from "../actions/riskAnalysis";

export default handleActions(
  {
    [GET_MANAGEMENT_SUCCESS]: (state, action) => {
      return {
        ...state,
        items: action.payload
      }
    },
    [GET_MANAGEMENT_FAIL]: () => initialState.riskAnalysis,

    [GET_MANAGEMENT_DURATION_SUCCESS]: (state, action) => {
      return {
        ...state,
        durations: action.payload
      }
    },
    [GET_MANAGEMENT_DURATION_FAIL]: () => initialState.riskAnalysis
  },
  initialState.riskAnalysis
);
