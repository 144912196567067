import React, { Component } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import qs from 'qs';

import Preloader from "../../components/Preloader";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "@material-ui/core/Modal";

import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";

import SearchBar from "./components/SearchBar";

import {
  getUsersRequest,
  deleteUserSessionRequest,
  deleteAllUsersSessionRequest,
  deleteUserRequest
} from "../../store/actions/users";

import Layout from "../../components/Layout";
import { SIGN_IN_PAGE } from "../../config/routes";
import {
  TableWrapper,
  MUITableCell,
  MUITableRow,
  MUITableSort,
  TableContent,
  DateWrap,
  MUIButton
} from "../../components/Mui";

import * as S from "./styles";

const tableHeadMap = {
  "User ID": "id",
  "First Name": "first_name",
  "Last Name": "last_name",
  "Email": "email",
  "Confirmed": "confirmed",
  "Balance": "balance",
  "Trades Payout": "total_bets_payout",
  "Trades Amount": "total_bets_amount",
  "Profit": "profit",
  "Last Login": "last_login_date"
};

const pageSize = 10;

class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idOfEditableItem: null,
      currentTableItems: [],
      sortingParameter: "last_login_date",
      sortingReversed: "desc",
      loading: false,
      deleteModalOpen: false,
      deleteUserId: null,
      deletedUsers: [],
      offset: {}
    };
  }

  async componentDidMount() {
    const { getUsers, history, location } = this.props;
    const { sortingParameter, sortingReversed } = this.state;
    const { order_by } = this.search;
    const queryStr = qs.parse(location.search, { ignoreQueryPrefix: true });

    const query = location.search ?
      qs.stringify(queryStr) :
      qs.stringify({
        page: { size: pageSize},
        order_by: { field: sortingParameter, direction: sortingReversed }
      });

    await getUsers({
      params: `?${query}`,
      onSuccess: () => {
        this.setState({
          loading: false
        });
      },
      onError: () => history.push(SIGN_IN_PAGE)
    });

    if (order_by && order_by.field) {
      await this.setState({
        sortingParameter: order_by.field
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location: { search }, getUsers, location } = this.props;
    const { location: { search: prevSearch } } = prevProps;
    const { sortingParameter, sortingReversed } = this.state;
    const { order_by } = this.search;
    const queryStr = qs.parse(location.search, { ignoreQueryPrefix: true });

    const query = location.search ?
      qs.stringify(queryStr) :
      qs.stringify({
        page: { size: pageSize},
        order_by: { field: sortingParameter, direction: sortingReversed }
      });

    if (search !== prevSearch) {
      this.setState({ loading: true });

      getUsers({
        params: `?${query}`,
        onSuccess: () => {
          this.setState({
            currentTableItems: [],
            sortingParameter: order_by.field,
            loading: false
          });
        },
        onError: () => {
          this.setState({ loading: false });
        }
      });
    }
  }

  get search() {
    const { location } = this.props;
    const { order_by } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return { order_by };
  }

  formatNumber(num) {
    return num ? num.toFixed(2) : 0;
  }

  pushToSearch = (searchParams) => {
    const { history } = this.props;
    const search = { ...this.search };

    Object.entries(searchParams).forEach(([key, value]) => {
      if (value) search[key] = value;
      else delete search[key];
    });

    history.push(`?${qs.stringify(search)}`);
  };

  handleOnSort = async (option) => {
    const { sortingParameter } = this.state;
    const { location } = this.props;
    const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });

    const { order_by } = this.search;
    const field = order_by && order_by.field || sortingParameter;
    const currentColumn = field === tableHeadMap[option];
    const directionAsc = (order_by && order_by.direction) === 'asc';
    const reverseDirection = currentColumn && directionAsc ? 'desc' : 'asc';

    if (option === "Profit") {
      return false;
    }

    this.setState({
      currentTableItems: [],
      sortingReversed: reverseDirection,
      loading: true
    });

    const sortOption = tableHeadMap[option];
    await this.pushToSearch({ ...searchObj, order_by: { field: sortOption, direction: reverseDirection } });
  };

  handleScroll = async (e) => {
    const currentOffset = e.target.scrollTop;
    const bottom = e.target.scrollHeight - currentOffset - e.target.clientHeight;

    if (bottom < 50) {
      const { location, items, getUsers } = this.props;
      const { offset, sortingParameter, sortingReversed, currentTableItems } = this.state;

      const directionDown = currentOffset > offset;

      const lastId = items && items.length && items[items.length - 1].id;
      const lastIdParam = lastId > 0 ? `&last_id=${lastId}` : '';

      if (items.length) {
        this.setState({ offset: currentOffset });
      }

      const query = location.search ?
        qs.parse(location.search, { ignoreQueryPrefix: true }) :
        qs.parse({
          page: { size: pageSize},
          order_by: { field: sortingParameter, direction: sortingReversed }
        });

      if (lastId && items.length && directionDown && (bottom < 30)) {
        this.setState({ loading: true });

        getUsers({
          params: `?${qs.stringify(query)}${lastIdParam}`,
          onSuccess: () => {
            const filteredTableItems = currentTableItems
              .filter((x) => items.indexOf(x) < 0)
              .concat(items);

            this.setState({
              loading: false,
              currentTableItems: filteredTableItems,
            });
          },
          onError: () => {
            this.setState({ loading: false });
          }
        });
      }
    }
  };

  handleSessionDelete = (id, e) => {
    const { deleteSession, currentUser } = this.props;
    const { currentTarget } = e;

    deleteSession({
      id,
      onSuccess: () => {
        currentTarget.classList.add('hide');

        if (currentUser.id === id) {
          window.location.reload();
        }
      }
    });
  };

  handleToggleDeleteModal = () => {
    const { deleteModalOpen } = this.state;
    this.setState({ deleteModalOpen: !deleteModalOpen })
  };

  handleUserDelete = async () => {
    const { deleteUser, currentUser } = this.props;
    const { deleteUserId, deletedUsers } = this.state;

    this.setState({
      deletedUsers: [...deletedUsers, deleteUserId]
    });

    await deleteUser({
      id: deleteUserId,
      onSuccess: () => {
        this.setState({
          deletedUsers: [...deletedUsers, deleteUserId]
        });

        if (currentUser.id === deleteUserId) {
          window.location.reload();
        }
      }
    });

    await this.handleToggleDeleteModal();
  };

  render() {
    const {
      sortingParameter,
      sortingReversed,
      loading,
      currentTableItems,
      deleteModalOpen,
      deletedUsers
    } = this.state;
    const { items, getUsers, location, deleteAllUsersSession } = this.props;

    const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });

    const filteredPrevTableItems = items
      ? currentTableItems.filter(x => items.indexOf(x) < 0)
      : currentTableItems;

    const tableItems = currentTableItems ? filteredPrevTableItems.concat(items) : items;

    return (
      <Layout fullScreen title="Users">
        {loading && <Preloader backdrop position="fixed"/>}

        <TableWrapper>
          <SearchBar
            getUsers={getUsers}
            searchObj={searchObj}
            pushToSearch={this.pushToSearch}
            deleteAllUsersSession={deleteAllUsersSession}
          />

          <TableContent theme="users" onScroll={this.handleScroll}>
            <Table>
              <TableHead>
                <MUITableRow>
                  {Object.keys(tableHeadMap).map((tableHead => {
                    const active = (tableHeadMap[tableHead] === sortingParameter) && (sortingReversed === 'desc');

                    return (
                      <MUITableCell
                        key={tableHead}
                        active={tableHeadMap[tableHead] === sortingParameter}
                        onClick={() => this.handleOnSort(tableHead)}
                      >
                        <MUITableSort disableSort={tableHead === "Profit"} sort={active}>{tableHead}</MUITableSort>
                      </MUITableCell>
                    )
                  }))}
                </MUITableRow>
              </TableHead>

              <TableBody>
                {tableItems.length ? tableItems.map((item) => {
                  const deleted = !!deletedUsers && deletedUsers.find(u => u === item.id);
                  return (
                    <MUITableRow
                      key={item.id}
                      hide={deleted}
                    >
                      <MUITableCell
                        padd="true"
                        bold="true"
                      >
                        <S.UserInfo>
                          {item.has_active_session && (
                            <Tooltip
                              arrow
                              title="Delete session"
                              placement="right"
                            >
                              <S.SessionDelete
                                onClick={e => this.handleSessionDelete(item.id, e)}
                                type="button"
                              >
                                <Close color="error" />
                              </S.SessionDelete>
                            </Tooltip>
                          )}

                          <S.UserDelete
                            onClick={() => {
                              this.handleToggleDeleteModal();
                              this.setState({ deleteUserId: item.id })
                            }}
                          >
                            Delete
                          </S.UserDelete>

                          {item.id}
                        </S.UserInfo>
                      </MUITableCell>

                      <MUITableCell>{item.first_name}</MUITableCell>

                      <MUITableCell>{item.last_name}</MUITableCell>

                      <MUITableCell>
                        <div style={{ textTransform: 'none' }}>{item.email}</div>
                      </MUITableCell>

                      <MUITableCell>
                        {item.confirmed ? <Done color="primary" /> : <Close color="error" />}
                      </MUITableCell>

                      <MUITableCell>
                        {this.formatNumber(item.balance)}
                      </MUITableCell>

                      <MUITableCell>
                        {this.formatNumber(item.total_bets_payout)}
                      </MUITableCell>

                      <MUITableCell>
                        {this.formatNumber(item.total_bets_amount)}
                      </MUITableCell>

                      <MUITableCell>
                        {this.formatNumber(this.formatNumber(item.total_bets_payout) - this.formatNumber(item.total_bets_amount))}
                      </MUITableCell>

                      <MUITableCell>
                        {item.last_login_date ? (
                          <DateWrap>{format(new Date(item.last_login_date), "MM/dd/yyyy HH:mm:ss")}&nbsp;UTC</DateWrap>
                        ) : 'New User'}
                      </MUITableCell>
                    </MUITableRow>
                  )
                }) : null}
              </TableBody>
            </Table>
          </TableContent>
        </TableWrapper>

        <Modal
          open={deleteModalOpen}
          onClose={this.handleToggleDeleteModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <S.ModalContent>
            <S.ModalTitle>Are you sure you want to delete this user's account?</S.ModalTitle>

            <S.ModalButtons>
              <MUIButton
                size="medium"
                variant="contained"
                type="button"
                color="secondary"
                onClick={this.handleUserDelete}
              >
                Delete
              </MUIButton>

              <MUIButton
                size="medium"
                variant="contained"
                type="button"
                onClick={this.handleToggleDeleteModal}
              >
                Close
              </MUIButton>
            </S.ModalButtons>
          </S.ModalContent>
        </Modal>
      </Layout>
    );
  }
}

const mapState = (state) => ({
  items: state.users,
  currentUser: state.currentUser
});

const mapDispatch = {
  getUsers: getUsersRequest,
  deleteSession: deleteUserSessionRequest,
  deleteAllUsersSession: deleteAllUsersSessionRequest,
  deleteUser: deleteUserRequest
};

export default connect(mapState, mapDispatch)(UsersPage);
