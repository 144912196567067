export default {
  over: "Pump",
  under: "Dump",
  highline: "Big Pump",
  lowline: "Big Dump",
  hightouch: "Touch Up",
  lowtouch: "Touch Down",
  tunnel: "Flatline",
  double_no_touch: "Double Crash",
  over_tug_of_war: "Cash or Crash",
  under_tug_of_war: "Crash or Cash",
  hi: "Hi-300",
  lo: "Lo-300",
  hilo: "Hi-Lo",
  hitouch: "Hi-T-200",
  lotouch: "Lo-T-200",
  in_or_out: "In Out",
  double_crash: "2x Crash",
  cash_or_crash: "Tug\'o\'War"
};
