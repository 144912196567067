import styled, { css } from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

const DrawerWidthOpen = "200px";
const DrawerWidth = "56px";


export const MUIDrawer = styled(Drawer)`
  && {
    width: ${DrawerWidth};
    height: 100vh;

    & > div {
      width: ${DrawerWidth};
      background-color: #fdfcfc;
      overflow: hidden;
    }
  }
  
  ${({ open }) => open && css`
    && {
      width: ${DrawerWidthOpen};
    
      & > div {
        width: ${DrawerWidthOpen};
        background-color: #fdfcfc;
      }
    }
  `}
`;

export const MUINavButton = styled(Button)`
  && {
    margin: 0;
    min-width: 100%;
    width: 100%;
    border-radius: 0;
    position: relative;
  }
`;

export const Content = styled.main`
  width: 100%;
  
  ${({ fullScreen }) => fullScreen && css`
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `}

  @media (max-width: 600px) {
    padding-top: 56px;
  }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  background-color: #F2F2F2;
`;

export const MUIIconButton = styled(IconButton)`
  && {
    margin-left: auto;
  }
`;

export const PageTitle = styled.h1`
  padding: 0 30px;
  font-family: Helvetica;
`;

export const NavLogo = styled.h4`
  display: block;
  text-align: center;
    
  ${({ isOpenNav }) => !isOpenNav && css`
    transform: rotate(-90deg) translateX(10px)
  `}
`;

export const HeadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px;
  height: 60px;
  min-height: 60px;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #171310;
  
  ${PageTitle} {
    padding: 0;
    margin: 0;
    color: #fff;
  }
`;
