import styled, { css } from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const TableWrapper = styled.div`
  padding: 0 30px 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  
  table {
    position: relative;
    
    th {
      position: sticky;
      top: 0;
    }
  }
`;

export const MUITableSort = styled.span`
  display: inline-block;
  position: relative;
  padding-right: 14px;
  cursor: pointer;
  
  &:before {
    content: ' ';
    position: absolute;
    right: 2px;
    top: 50%;
    margin-top: -2px;
    width: 0;
    height: 0;
    border: 3px solid transparent;
    border-top-color: #fff;
  }
  
  ${({ sort }) => sort && css`    
    &:before {
      border-top-color: transparent;
      border-bottom-color: #fff;
      margin-top: -5px;
    }
  `}
  
  ${({disableSort}) => disableSort && css`
    &:before {
      display: none;
    }
  `}
`;

export const MUITableCell = styled(TableCell)`
  thead & {
    color: #fff;
    white-space: nowrap;
    background-color: #424242;
    position: relative;
    z-index: 2;
    
    &:first-child {
      border-radius: 4px 0 0 0;
    }
    
    &:last-child {
      border-radius: 0 4px 0 0;
    }
  }
  
  && {
    font-size: 13px;
    text-transform: capitalize;
    padding: 8px 6px;
    
    &:first-child {
      padding-left: 10px;
    }

    ${({ bold }) => bold && css`
      font-weight: bold;
    `}
    
    ${({ active }) => active && css`
      font-weight: bold;
      color: #a1a7fa;
    `}
  }
`;

export const MUITextField = styled(TextField)`
  && {
  }
`;

export const MUIFormControl = styled(FormControl)`
  && {
    min-width: 190px;
    margin-right: 15px;
  }
`;

export const MUIButton = styled(Button)`
  && {
    margin-top: 16px;
    margin-left: 30px;
  }
`;

export const MUIFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
  
  ${MUIButton} {
    margin: 16px 0 0 10px;
  }
  
  ${MUIFormControl} {
    margin: 0 20px 0 0;
    flex-grow: 1;
    
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const MUISelect = styled(Select)`
  && {
    text-transform: capitalize;
  }
`;

export const MUIMenuItem = styled(MenuItem)`
  && {
    text-transform: capitalize;
  }
`;

export const MUITableRow = styled(TableRow)`
  && {
    height: 53px;
        
    ${({ hide }) => hide && css`
      display: none;
    `}

    td:last-child {
      width: 100px;

      div {
        justify-content: center;
      }
    }
  }
`;

export const TableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  background-color: #fff;
  
  ${({theme}) => theme === 'trades' && css`
    ${MUITableCell} {
      font-size: 13px;
      
      && {
        font-size: 13px;
      }
    }
    
    thead th, td {
      border: 3px solid #dfdfdf;
      border-left: 0.5px dashed #dfdfdf;
      border-right: 0.5px dashed #dfdfdf;

      &:first-child {
        border: 3px solid #dfdfdf;
        border-left: none;
      }

      &:nth-child(5) {
        border-right: 3px solid #dfdfdf;
      }

      &:nth-child(11) {
        border-right: 3px solid #dfdfdf;
      }

      &:last-child {
        border: 3px solid #dfdfdf;
        border-left: 0.5px dashed #dfdfdf;
        border-right: none;
      }
    }
    
    thead {      
      tr:first-child th {
        border-top: none;
      }
    }
  `}
  
  ${({theme}) => theme === 'users' && css`
    && thead {
      th:first-child {
        padding-left: 40px;
      }
    }
    
    && td:first-child {
      padding-left: 40px;
    }
  `}
  
  ${({theme}) => theme === 'management' && css`
    flex-grow: 0;
    
    td, th {
      border-left: 2px solid #ccc;
      border-bottom: .5px solid rgba(204, 204, 204, 0.5);
      border-top: .5px solid rgba(204, 204, 204, 0.5);
      
      &:first-child {
        border-left: none;
      }
    }
    
    tr:hover {
      opacity: 0.8;

      td:first-child {
        background-color: rgba(0,0,0,.2);
      }
    }
    
    tr.MuiTableRow-root {
      height: 50px;
    }
    
    @media (max-height: 800px) {
      tr.MuiTableRow-root {
        height: 45px;
      }
    }
    
    @media (max-height: 750px) {
      tr.MuiTableRow-root {
        height: 40px;
      }
    }
  `}
`;

export const DateWrap = styled.div`
  display: inline-flex;
  max-width: 87px;
`;

export const MUITableCellHalf = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 60px;
  
  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  span {
    display: flex;
    padding: 10px;
    flex-direction: column;
    width: 50%;
  }

  span:last-child {
    justify-content: flex-end;
  }
`;
