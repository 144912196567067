import { createAction } from "redux-actions";

const actionTypeName = "@bo/assets/";

export const GET_ASSETS_REQUEST = `${actionTypeName}GET_ASSETS_REQUEST`;
export const GET_ASSETS_SUCCESS = `${actionTypeName}GET_ASSETS_SUCCESS`;
export const GET_ASSETS_FAIL = `${actionTypeName}GET_ASSETS_FAIL`;

export const getAssetsRequest = createAction(GET_ASSETS_REQUEST);
export const getAssetsSuccess = createAction(GET_ASSETS_SUCCESS);
export const getAssetsFail = createAction(GET_ASSETS_FAIL);

export const EDIT_ASSET_REQUEST = `${actionTypeName}EDIT_ASSETS_REQUEST`;
export const EDIT_ASSET_SUCCESS = `${actionTypeName}EDIT_ASSET_SUCCESS`;
export const EDIT_ASSET_FAIL = `${actionTypeName}EDIT_ASSET_FAIL`;

export const editAssetRequest = createAction(EDIT_ASSET_REQUEST);
export const editAssetSuccess = createAction(EDIT_ASSET_SUCCESS);
export const editAssetFail = createAction(EDIT_ASSET_FAIL);

export const EDIT_INSTRUMENT_REQUEST = `${actionTypeName}EDIT_INSTRUMENTS_REQUEST`;
export const EDIT_INSTRUMENT_SUCCESS = `${actionTypeName}EDIT_INSTRUMENT_SUCCESS`;
export const EDIT_INSTRUMENT_FAIL = `${actionTypeName}EDIT_INSTRUMENT_FAIL`;

export const editInstrumentRequest = createAction(EDIT_INSTRUMENT_REQUEST);
export const editInstrumentSuccess = createAction(EDIT_INSTRUMENT_SUCCESS);
export const editInstrumentFail = createAction(EDIT_INSTRUMENT_FAIL);
