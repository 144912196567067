import React, { Component } from "react";

import { connect } from "react-redux";
import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import qs from 'qs';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

import { SIGN_IN_PAGE } from "../../config/routes";

import {MUITableCell, MUITableRow, MUITableSort, TableContent, TableWrapper, DateWrap} from "../../components/Mui";
import Layout from "../../components/Layout";
import SearchBar from "./components/SearchBar";
import Preloader from "../../components/Preloader";

import { getAffiliatesRequest } from "../../store/actions/affiliates";
import { getAssetsRequest } from "../../store/actions/assets";

const tableHeadMap = {
  // "Referral Id": "referral_id",
  "Trade ID": "id",
  "User": "first_name",
  "Asset": "asset_name",
  "Type": "instrument_name",
  "Stake": "amount",
  "Time of Trade": "inserted_at",
  "State": "status",
  "Paid Out": "payout",
  "House Profit": "house_profit"
};

const pageSize = 10;

class AffiliatesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idOfEditableItem: null,
      prevTableItems: [],
      sortingParameter: "referral_id",
      sortingReversed: "desc",
      loading: false
    };
  }

  async componentDidMount() {
    const { getAffiliates, history, location, getAssets } = this.props;
    const { sortingParameter, sortingReversed } = this.state;
    const { order_by } = this.search;
    const queryStr = qs.parse(location.search, { ignoreQueryPrefix: true });

    const query = location.search ?
      qs.stringify(queryStr) :
      qs.stringify({
        page: { size: pageSize},
        order_by: { field: sortingParameter, direction: sortingReversed }
      });

    await getAssets({ });

    await getAffiliates({
      params: `?${query}&is_affiliate=true`,
      onSuccess: () => {
        this.setState({
          loading: false
        });
      },
      onError: () => {
        history.push(SIGN_IN_PAGE);
        this.setState({
          loading: false
        });
      }
    });

    if (order_by && order_by.field) {
      await this.setState({
        sortingParameter: order_by.field
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location: { search }, getAffiliates, location } = this.props;
    const { location: { search: prevSearch } } = prevProps;
    const { sortingParameter, sortingReversed } = this.state;
    const { order_by } = this.search;
    const queryStr = qs.parse(location.search, { ignoreQueryPrefix: true });

    const query = location.search ?
      qs.stringify(queryStr) :
      qs.stringify({
        page: { size: pageSize},
        order_by: { field: sortingParameter, direction: sortingReversed }
      });

    if (search !== prevSearch) {
      this.setState({ loading: true });

      getAffiliates({
        params: `?${query}&is_affiliate=true`,
        onSuccess: () => {
          this.setState({
            prevTableItems: [],
            sortingParameter: order_by.field,
            loading: false
          });
        },
        onError: () => {
          this.setState({ loading: false });
        }
      });
    }
  }

  get search() {
    const { location } = this.props;
    const { order_by } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return { order_by };
  }

  pushToSearch = (searchParams) => {
    const { history } = this.props;
    const search = { ...this.search };

    Object.entries(searchParams).forEach(([key, value]) => {
      if (value) search[key] = value;
      else delete search[key];
    });

    history.push(`?${qs.stringify(search)}`);
  };

  handleOnSort = async (option) => {
    const { sortingParameter } = this.state;
    const { location } = this.props;
    const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });

    const { order_by } = this.search;
    const field = order_by && order_by.field || sortingParameter;
    const currentColumn = field === tableHeadMap[option];
    const directionAsc = (order_by && order_by.direction) === 'asc';
    const reverseDirection = currentColumn && directionAsc ? 'desc' : 'asc';

    this.setState({
      prevTableItems: [],
      sortingReversed: reverseDirection,
      loading: true
    });

    const sortOption = tableHeadMap[option];
    await this.pushToSearch({ ...searchObj, order_by: { field: sortOption, direction: reverseDirection } });
  };

  handleScroll = async (e) => {
    const bottom = ((e.target.scrollHeight - e.target.scrollTop) - e.target.clientHeight) < 30;

    if (bottom) {
      this.setState({ loading: true });

      const { location, items, getAffiliates } = this.props;
      const { sortingParameter, sortingReversed } = this.state;
      const lastId = items && items.length && items[items.length - 1].id;
      const lastIdParam = lastId > 0 ? `&last_id=${lastId}` : '';
      const prevItems = items;

      const query = location.search ?
        qs.parse(location.search, { ignoreQueryPrefix: true }) :
        qs.parse({
          page: { size: pageSize},
          order_by: { field: sortingParameter, direction: sortingReversed }
        });


      getAffiliates({
        params: `?${qs.stringify(query)}${lastIdParam}&is_affiliate=true`,
        onSuccess: () => {
          this.setState({
            prevTableItems: prevItems,
            loading: false
          });
        },
        onError: () => {
          this.setState({ loading: false });
        }
      });
    }
  };

  render() {
    const { sortingParameter, sortingReversed, loading, prevTableItems } = this.state;
    const { location, items, house_profit_sum, stake_profit_sum } = this.props;

    const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });
    const filteredPrevTableItems = prevTableItems.filter(x => items.indexOf(x) < 0);
    const tableItems = prevTableItems ? filteredPrevTableItems.concat(items) : items;

    return (
      <Layout fullScreen title="Affiliates">
        {loading && <Preloader backdrop position="fixed"/>}

        <TableWrapper>
          <SearchBar
            searchObj={searchObj}
            pushToSearch={this.pushToSearch}
            house_profit_sum={house_profit_sum}
            stake_profit_sum={stake_profit_sum}
          />

          <TableContent theme="trades" onScroll={this.handleScroll}>
            <Table>
              <TableHead>
                <MUITableRow>
                  {Object.keys(tableHeadMap).map((tableHead => {
                    const active = (tableHeadMap[tableHead] === sortingParameter) && (sortingReversed === 'desc');

                    return (
                      <MUITableCell
                        key={tableHead}
                        active={tableHeadMap[tableHead] === sortingParameter}
                        onClick={() => this.handleOnSort(tableHead)}
                      >
                        <MUITableSort
                          sort={active}
                        >
                          {tableHead}
                        </MUITableSort>
                      </MUITableCell>
                    )
                  }))}
                </MUITableRow>
              </TableHead>

              <TableBody>
                {tableItems.length > 0 ? tableItems.map((item) => (
                  <MUITableRow key={item.id}>
                    {/*
                    <MUITableCell
                      padd="true"
                      bold="true"
                    >
                      <div style={{ textTransform: 'none' }}>
                        {item.user.referral_id ? item.user.referral_id : '-' }
                      </div>
                    </MUITableCell>
                    */}

                    <MUITableCell>
                      {item.id}
                    </MUITableCell>

                    <MUITableCell>
                      <b>{`${item.user.first_name && item.user.first_name} ${item.user.last_name && item.user.last_name}`}</b>
                      <div style={{ textTransform: 'none' }}>{item.user.email && item.user.email}</div>
                    </MUITableCell>

                    <MUITableCell>{item.asset_name}</MUITableCell>

                    <MUITableCell>
                      {item.instrument_name.replace(/_/g, " ")}
                    </MUITableCell>

                    <MUITableCell>{item.amount}</MUITableCell>

                    <MUITableCell>{item.inserted_at && <DateWrap>{format(parseISO(item.inserted_at), "MM/dd/yyyy HH:mm:ss")}&nbsp;UTC</DateWrap>}</MUITableCell>

                    <MUITableCell>{item.status}</MUITableCell>

                    <MUITableCell>
                      {item.payout || item.payout === 0 ? item.payout.toFixedNoRounding(3) : '-'}
                    </MUITableCell>

                    <MUITableCell>
                      {item.house_profit || item.house_profit === 0 ? item.house_profit.toFixed(3) : '-'}
                    </MUITableCell>
                  </MUITableRow>
                )) : null}
              </TableBody>
            </Table>

            {!tableItems.length ? (
              <div style={{ textAlign: 'center', padding: 30, fontSize: 20 }}>There are no records in the list</div>
            ) : null }
          </TableContent>
        </TableWrapper>
      </Layout>
    );
  }
}

const mapState = (state) => ({
  house_profit_sum: state.affiliates.house_profit_sum,
  stake_profit_sum: state.affiliates.stake_profit_sum,
  items: state.affiliates.items,
  assets: state.assets
});

const mapDispatch = {
  getAffiliates: getAffiliatesRequest,
  getAssets: getAssetsRequest
};

export default connect(mapState, mapDispatch)(AffiliatesPage);
