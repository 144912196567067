import React from 'react';
import * as S from './styles';

const Preloader = ({ children, ...rest }) => (
  <S.PreloaderWrapper {...rest}>
    <S.Preloader />
    <span>{children}</span>
  </S.PreloaderWrapper>
);

Preloader.defaultProps = {
  size: '',
  children: null,
  backdrop: false,
  className: '',
  position: 'absolute'
};

export default Preloader;
