import * as Yup from "yup";

export default {
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("This field is required"),
  password: Yup.lazy(value =>
    !value
      ? Yup.string().required("This field is required")
      : Yup.string()
          .min(8, "The password should include minimum 8 characters")
          .matches(
            /[a-z]/,
            "The password should include lowercase letter (a-z)"
          )
          .matches(
            /[A-Z]/,
            "The password should include uppercase letter (A-Z)"
          )
          .matches(
            /[a-zA-Z]+[^a-zA-Z\s]+/,
            `The password should include 1 special character`
          )
          .matches(/[0-9]/, "The password should include digit (0-9)")
  )
};
