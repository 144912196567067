import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { SIGN_IN_PAGE } from "../../config/routes";
import { COOKIE_EXPIRATION_KEY } from "../../config";

import "./styles.scss";

export default ({ expires, extendExpiryDate }) => {
  const calculateTimeLeft = () => {
    const to = expires.getTime();
    const difference = to - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={timeLeft[interval]}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  const to = expires.getTime();
  const difference = to - +new Date();

  return difference < 0 ? (
    window.location.assign(SIGN_IN_PAGE)
  ) : (
    <div className={"timer__container"}>
      <div className={"timer__info"}>{timerComponents} </div>
      <div className={"timer__actions"}>
        <button
          className={"btn"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            extendExpiryDate({
              onSuccess: (id) => store.removeNotification(id),
            });
          }}
        >
          Stay Connected
        </button>

        <button
          className={"btn"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.localStorage.removeItem(COOKIE_EXPIRATION_KEY);
            window.location.assign(SIGN_IN_PAGE);
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};
