import { createAction } from "redux-actions";

const actionTypeName = "@bo/assets/";

// GET rates
export const GET_BANKROLLER_RATES_REQUEST = `${actionTypeName}GET_BANKROLLER_RATES_REQUEST`;
export const GET_BANKROLLER_RATES_SUCCESS = `${actionTypeName}GET_BANKROLLER_RATES_SUCCESS`;
export const GET_BANKROLLER_RATES_FAIL = `${actionTypeName}GET_BANKROLLER_RATES_FAIL`;

export const getBankRollerRatesRequest = createAction(GET_BANKROLLER_RATES_REQUEST);
export const getBankRollerRatesSuccess = createAction(GET_BANKROLLER_RATES_SUCCESS);
export const getBankRollerRatesFail = createAction(GET_BANKROLLER_RATES_FAIL);

// EDIT rates
export const EDIT_BANKROLLER_RATES_REQUEST = `${actionTypeName}EDIT_BANKROLLER_RATES_REQUEST`;
export const EDIT_BANKROLLER_RATES_SUCCESS = `${actionTypeName}EDIT_BANKROLLER_RATES_SUCCESS`;
export const EDIT_BANKROLLER_RATES_FAIL = `${actionTypeName}EDIT_BANKROLLER_RATES_FAIL`;

export const editBankRollerRatesRequest = createAction(EDIT_BANKROLLER_RATES_REQUEST);
export const editBankRollerRatesSuccess = createAction(EDIT_BANKROLLER_RATES_SUCCESS);
export const editBankRollerRatesFail = createAction(EDIT_BANKROLLER_RATES_FAIL);

// GET investments
export const GET_BANKROLLER_INVESTMENT_REQUEST = `${actionTypeName}GET_BANKROLLER_INVESTMENT_REQUEST`;
export const GET_BANKROLLER_INVESTMENT_SUCCESS = `${actionTypeName}GET_BANKROLLER_INVESTMENT_SUCCESS`;
export const GET_BANKROLLER_INVESTMENT_FAIL = `${actionTypeName}GET_BANKROLLER_INVESTMENT_FAIL`;

export const getBankRollerInvestmentRequest = createAction(GET_BANKROLLER_INVESTMENT_REQUEST);
export const getBankRollerInvestmentSuccess = createAction(GET_BANKROLLER_INVESTMENT_SUCCESS);
export const getBankRollerInvestmentFail = createAction(GET_BANKROLLER_INVESTMENT_FAIL);

// GET special users
export const GET_BANKROLLER_SPECIAL_USERS_REQUEST = `${actionTypeName}GET_BANKROLLER_SPECIAL_USERS_REQUEST`;
export const GET_BANKROLLER_SPECIAL_USERS_SUCCESS = `${actionTypeName}GET_BANKROLLER_SPECIAL_USERS_SUCCESS`;
export const GET_BANKROLLER_SPECIAL_USERS_FAIL = `${actionTypeName}GET_BANKROLLER_SPECIAL_USERS_FAIL`;

export const getBankRollerSpecialUsersRequest = createAction(GET_BANKROLLER_SPECIAL_USERS_REQUEST);
export const getBankRollerSpecialUsersSuccess = createAction(GET_BANKROLLER_SPECIAL_USERS_SUCCESS);
export const getBankRollerSpecialUsersFail = createAction(GET_BANKROLLER_SPECIAL_USERS_FAIL);

// PATCH special users
export const SET_ROLE_SPECIAL_USERS_REQUEST = `${actionTypeName}SET_ROLE_SPECIAL_USERS_REQUEST`;
export const SET_ROLE_SPECIAL_USERS_SUCCESS = `${actionTypeName}SET_ROLE_SPECIAL_USERS_SUCCESS`;
export const SET_ROLE_SPECIAL_USERS_FAIL = `${actionTypeName}SET_ROLE_SPECIAL_USERS_FAIL`;

export const setRoleSpecialUsersRequest = createAction(SET_ROLE_SPECIAL_USERS_REQUEST);
export const setRoleSpecialUsersSuccess = createAction(SET_ROLE_SPECIAL_USERS_SUCCESS);
export const setRoleSpecialUsersFail = createAction(SET_ROLE_SPECIAL_USERS_FAIL);

// current-special-stakes
export const CURRENT_SPECIAL_STAKES_REQUEST = `${actionTypeName}CURRENT_SPECIAL_STAKES_REQUEST`;
export const CURRENT_SPECIAL_STAKES_SUCCESS = `${actionTypeName}CURRENT_SPECIAL_STAKES_SUCCESS`;
export const CURRENT_SPECIAL_STAKES_FAIL = `${actionTypeName}CURRENT_SPECIAL_STAKES_FAIL`;

export const currentSpecialStakesRequest = createAction(CURRENT_SPECIAL_STAKES_REQUEST);
export const currentSpecialStakesSuccess = createAction(CURRENT_SPECIAL_STAKES_SUCCESS);
export const currentSpecialStakesFail = createAction(CURRENT_SPECIAL_STAKES_FAIL);
