import React, { useState, useEffect } from "react";
import Input from "@material-ui/core/Input";
import Card from "../Card";
import { MUIButton } from "../../../../components/Mui";
import * as S from './styles';

const User = ({
  userType,
  handleUpdateUsers,
  user,
  currentUser,
  handleCreateInvestment,
  handleUpdateInvestment,
  specialStakes,
  rate,
  handleUpdateRate
}) => {
  const currentEmailVal = user ? user.email : '';
  const currentAmount = currentUser && currentUser.investment ? currentUser.investment.amount : '';

  const [userEmail, setUserEmail] = useState(currentEmailVal);
  const [userAmount, setUserAmount] = useState(currentAmount);

  useEffect(() => {
    setUserEmail(currentEmailVal);
  }, [currentEmailVal]);

  useEffect(() => {
    setUserAmount(currentAmount);
  }, [currentAmount]);

  const updateUser = {
    additional_role: userType,
    email: userEmail
  };

  const currentUserEmail = currentUser && currentUser.email;
  const userFlexUp = currentEmailVal === currentUserEmail;
  const errorControl = currentUser && currentUser.error && currentUser.error[Object.keys(currentUser.error)[0]];

  const title = () => {
    switch (userType) {
      case 'up': return 'UP';
      case 'flex': return 'BB';
      case 'thirdparty': return '3d party';
      default: return '';
    }
  };

  const onUpdateEmail = async () => {
    await handleUpdateUsers(updateUser);

    if (userFlexUp) {
      await handleUpdateInvestment({
        repeatable: false
      });
    }
  };

  const onUpdateAmount = async () => {
    await handleCreateInvestment({
      amount: userAmount,
      pending_amount: userAmount,
      repeatable: true
    });
  };

  return (
    <S.Wrap>
      <S.Card control currentControl={false}>
        <S.Title><span style={{ textTransform: 'capitalize' }}>{title()}</span> Share:</S.Title>

        <S.Content>
          <S.InputWrap percent>
            <Input
              style={{ width: '100%' }}
              type="number"
              value={specialStakes && specialStakes.percentage_ratio > 0 ? (specialStakes.percentage_ratio).toFixed(3) : 0}
              min={0}
              max={100}
            />
          </S.InputWrap>
        </S.Content>
      </S.Card>

      <S.Card control currentControl={userFlexUp}>
        <S.Title>Current <span style={{ textTransform: 'capitalize' }}>{title()}</span> Staking:</S.Title>

        <S.Content>
          <S.InputWrap>
            <Input
              style={{ width: '100%' }}
              type="number"
              value={userFlexUp
                ? `${userAmount}`
                : `${specialStakes && specialStakes.amount > 0 ? (specialStakes.amount).toFixed(3) : 0}`}
              onChange={e => setUserAmount(e.target.value)}
              required={true}
            />

            <S.InputErrorWrap>
              {userFlexUp && errorControl && errorControl.length ? errorControl.map(item => (
                <S.InputError>{item}</S.InputError>
              )) : null}
            </S.InputErrorWrap>
          </S.InputWrap>

          <MUIButton
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            disabled={currentAmount === userAmount || !userAmount}
            onClick={onUpdateAmount}
          >
            Apply
          </MUIButton>
        </S.Content>
      </S.Card>

      {userType !== 'thirdparty' && (
        <S.Card control currentControl={userFlexUp || !currentEmailVal}>
          <S.Title>{title()} Email:</S.Title>

          <S.Content>
            <S.InputWrap>
              <Input
                style={{ width: '100%' }}
                type="email"
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
                required={true}
              />
            </S.InputWrap>

            <MUIButton
              type="button"
              size="medium"
              variant="contained"
              disabled={currentEmailVal === userEmail}
              onClick={() => setUserEmail(currentEmailVal)}
            >
              Reset
            </MUIButton>

            <MUIButton
              type="button"
              size="medium"
              variant="contained"
              color="primary"
              disabled={currentEmailVal === userEmail || !userEmail}
              onClick={onUpdateEmail}
            >
              Update
            </MUIButton>
          </S.Content>
        </S.Card>
      )}

      {userType === 'thirdparty' && (
        <Card
          rate={rate}
          handleUpdateRate={handleUpdateRate}
        />
      )}
    </S.Wrap>
  )
};

export default User;
