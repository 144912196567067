import styled from "styled-components";

export const User = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
`;

export const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
`;

export const UserInfo = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-weight: bold;
`;

export const UserLogOut = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-size: 13px;
  cursor: pointer;
  
  svg {
    margin-right: 4px;
  }
`;
