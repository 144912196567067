import { createAction } from "redux-actions";

const actionTypeName = "@bo/instruments/";

export const GET_INSTRUMENTS_REQUEST = `${actionTypeName}GET_INSTRUMENTS_REQUEST`;
export const GET_INSTRUMENTS_SUCCESS = `${actionTypeName}GET_INSTRUMENTS_SUCCESS`;
export const GET_INSTRUMENTS_FAIL = `${actionTypeName}GET_INSTRUMENTS_FAIL`;

export const getInstrumentsRequest = createAction(GET_INSTRUMENTS_REQUEST);
export const getInstrumentsSuccess = createAction(GET_INSTRUMENTS_SUCCESS);
export const getInstrumentsFail = createAction(GET_INSTRUMENTS_FAIL);
