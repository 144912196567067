import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import apiCaller from "../../utils/apiCaller";
import {
  GET_USERS_REQUEST,
  getUsersSuccess,
  getUsersFail,
  DELETE_USER_SESSION_REQUEST,
  DELETE_ALL_USERS_SESSION_REQUEST,
  deleteAllUsersSessionSuccess,
  deleteAllUsersSessionFail,
  DELETE_USER_REQUEST
} from "../actions/users";
import { HTTP_METHODS } from "../../config";

function* getUsers({ payload: { onSuccess, onError, params = "" } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: `/api/admin/users${params}`,
      token: true,
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getUsersSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getUsersFail, err);
    yield put(errAction);
  }
}

function* deleteSession({ payload: { onSuccess, onError, id = "" } }) {
  try {
    yield call(apiCaller, HTTP_METHODS.DELETE, {
      url: `/api/admin/sessions?user_id=${id}`,
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (err) {
    if (onError) {
      yield call(onError);
    }
  }
}

function* deleteAllSessions({ payload: { onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.DELETE, {
      url: '/api/admin/sessions',
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(deleteAllUsersSessionSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(deleteAllUsersSessionFail, err);
    yield put(errAction);
  }
}

function* deleteUser({ payload: { onSuccess, onError, id = "" } }) {
  try {
    yield call(apiCaller, HTTP_METHODS.DELETE, {
      url: `/api/admin/users/${id}`,
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (err) {
    if (onError) {
      yield call(onError);
    }
  }
}

export default function*() {
  yield takeLatest(GET_USERS_REQUEST, getUsers);
  yield takeEvery(DELETE_USER_SESSION_REQUEST, deleteSession);
  yield takeEvery(DELETE_USER_REQUEST, deleteUser);
  yield takeLatest(DELETE_ALL_USERS_SESSION_REQUEST, deleteAllSessions);
}
