import { takeLatest, call, put } from "redux-saga/effects";

import apiCaller from "../../utils/apiCaller";
import {
  GET_AFFILIATES_REQUEST,
  getAffiliatesSuccess,
  getAffiliatesFail,
} from "../actions/affiliates";
import { HTTP_METHODS } from "../../config";

function* getAffiliates({ payload: { onSuccess, onError, params = "" } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: `/api/admin/bets${params}`,
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getAffiliatesSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getAffiliatesFail, err);
    yield put(errAction);
  }
}

export default function*() {
  yield takeLatest(GET_AFFILIATES_REQUEST, getAffiliates);
}
