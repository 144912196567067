/* eslint-disable no-unused-expressions */
import React from "react";

import { Formik, Form } from "formik";
import * as yup from "yup";

import InputLabel from "@material-ui/core/InputLabel";

import {
  MUIFormControl,
  MUITextField,
  MUISelect,
  MUIMenuItem,
  MUIButton,
} from "../../../../components/Mui";

import FormWrap from './styles';

let UsersSearchSchema = yup.object().shape({
  first_name: yup.string(),
  last_name: yup.string(),
  email: yup.string().email(),
  status: yup.string().nullable(),
});

const UsersSearchBar = ({ pushToSearch, searchObj, deleteAllUsersSession }) => {
  const statuses = ["active", "suspended"];

  const handleClearFilter = () => {
    pushToSearch({ order_by: { field: "id", direction: "desc" }});
  };

  return (
    <Formik
      initialValues={{
        first_name: searchObj.first_name || "",
        last_name: searchObj.last_name || "",
        email: searchObj.email || "",
        status: searchObj.status || "",
      }}
      validationSchema={UsersSearchSchema}
      onSubmit={(values) => { pushToSearch({...values, order_by: { field: "id", direction: "desc" } }) }}
    >
      {({ errors, handleChange, handleBlur, touched, values }) => {
        return (
          <FormWrap>
            <Form>
              <MUIFormControl>
                <MUITextField
                  error={errors.first_name && touched.first_name}
                  value={values.first_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  id="first_name"
                  label="First Name"
                  name="first_name"
                  helperText={
                    errors.first_name && touched.first_name
                      ? errors.first_name
                      : ""
                  }
                />
              </MUIFormControl>

              <MUIFormControl>
                <MUITextField
                  error={errors.last_name && touched.last_name}
                  value={values.last_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  helperText={
                    errors.last_name && touched.last_name
                      ? errors.last_name
                      : ""
                  }
                />
              </MUIFormControl>

              <MUIFormControl>
                <MUITextField
                  error={errors.email && touched.email}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  id="email"
                  label="Email"
                  name="email"
                  helperText={
                    errors.email && touched.email ? errors.email : ""
                  }
                />
              </MUIFormControl>

              <MUIFormControl>
                <InputLabel id="status-label">Status</InputLabel>

                <MUISelect
                  id="status-id"
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                >
                  <MUIMenuItem key={"null"} value="">
                    -
                  </MUIMenuItem>
                  {statuses.map((option) => (
                    <MUIMenuItem key={option} value={option}>
                      {option}
                    </MUIMenuItem>
                  ))}
                </MUISelect>
              </MUIFormControl>

              <div>
                <MUIButton
                  type="reset"
                  size="medium"
                  variant="contained"
                  onClick={handleClearFilter}
                >
                  Clear
                </MUIButton>

                <MUIButton
                  type="submit"
                  size="medium"
                  variant="contained"
                  color="primary"
                >
                  Filter
                </MUIButton>
              </div>
            </Form>

            <MUIButton
              type="button"
              size="medium"
              variant="contained"
              color="secondary"
              onClick={() => {
                deleteAllUsersSession({
                  onSuccess: () => { window.location.reload() }
                });
              }}
            >
              Delete all sessions
            </MUIButton>
          </FormWrap>
        );
      }}
    </Formik>
  );
};

export default UsersSearchBar;
