import styled, { css } from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

export const TableWrapper = styled.div`
  padding: 0 30px;
`;

export const ControlBnts = styled.div`
  display: flex;
  width: 100%;
`;

export const MUITextField = styled(TextField)`
  && {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 44px;
    padding-left: 0;
    margin-left: -5px;

    fieldset {
      width: 80%;
    }

    input {
      padding-left: 5px;
    }
  }
`;

export const MUITableCell = styled(TableCell)`
  thead & {
    color: #3f51b5;
    background-color: #F0EFF9;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
    position: sticky;
    top: 0;
    z-index: 10;
    min-height: 93px;
  }
  
  ${MUITextField} {
    max-height: 100%;
    
    & > div {
      height: 100%;
    }
  }
  
  && {
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    
    &:first-child {
      padding-left: 20px;
    }
    
    &:nth-child(2) {
      text-align: left;
    }

    ${({ width }) =>
      width &&
      css`
        width: ${width};
      `}

    ${({ bold }) =>
      bold &&
      css`
        font-weight: bold;
      `}
  }
`;

export const MUITableRow = styled(TableRow)`
  && {
    height: 53px;

    td:last-child {
      width: 100px;

      div {
        justify-content: center;
      }
    }
  }
`;

export const DropdownControlText = styled.div`
  opacity: .5;
  transition: .3s;
`;

export const DropdownControl = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  
  & > svg {
    transition: .3s;
  }
  
  ${({ active }) => active && css`
    ${DropdownControlText} {
      opacity: 1;
    }
  `}
  
  ${({ opened }) => opened && css`
    & > svg {
      transform: rotate(180deg);
    }
  `}
`;
