import React, { Component } from "react";
import { connect } from "react-redux";
import qs from "qs";
import moment from 'moment';

import {
  getBankRollerRatesRequest,
  editBankRollerRatesRequest,
  getBankRollerInvestmentRequest,
  getBankRollerSpecialUsersRequest,
  setRoleSpecialUsersRequest,
  currentSpecialStakesRequest
} from "../../store/actions/bankRoller";
import {
  getInvestmentRequestAction,
  createInvestmentRequestAction,
  updateInvestmentRequestAction
} from "../../store/actions/currentUser";
import Layout from "../../components/Layout";
import { MUIButton } from "../../components/Mui";
import User from "./components/User";
import TableBankRoller from "./components/TableBankRoller";
import TableSwitcher from "./components/TableSwitcher";

import * as S from "./styles";

const increment = 'increment';
const decrement = 'decrement';

class BankRollerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idOfEditableItem: null,
      prevTableItems: [],
      investmentGranulation: 'month',
      investmentYear: moment().format('YYYY'),
      investmentMonth:  moment().format('MM'),
      loading: false,
      isYear: false
    };
  }

  async componentDidMount() {
    const {
      getBankRollerRates,
      getInvestmentRequest,
      location,
      getBankRollerInvestment,
      getBankRollerSpecialUsers,
      currentSpecialStakes
    } = this.props;
    const { investmentGranulation, investmentYear, investmentMonth } = this.state;
    const queryStr = qs.parse(location.search, { ignoreQueryPrefix: true });
    const queryYear = queryStr.granulation === 'year';

    const query = location.search ?
      qs.stringify(queryStr) :
      qs.stringify({ granulation: investmentGranulation, year: investmentYear, month: investmentMonth});

    if (location.search) {
      await this.setState({
        investmentGranulation: queryStr.granulation,
        investmentYear: queryStr.year,
        investmentMonth: queryStr.month || '',
        isYear: queryYear
      });
    }

    await getBankRollerRates({ });

    await getInvestmentRequest({ });

    await getBankRollerSpecialUsers({ });

    await currentSpecialStakes({ });

    await getBankRollerInvestment({
      params: `?${query}`,
      onSuccess: () => {},
      onError: () => {}
    })
  }

  componentDidUpdate(prevProps) {
    const { location: { search }, getBankRollerInvestment, location } = this.props;
    const { location: { search: prevSearch } } = prevProps;
    const { investmentGranulation, investmentYear } = this.state;
    const { granulation, year, month } = this.search;
    const queryStr = qs.parse(location.search, { ignoreQueryPrefix: true });

    const query = location.search ?
      qs.stringify(queryStr) :
      qs.stringify({ granulation: investmentGranulation, year: investmentYear });

    if (search !== prevSearch) {
      this.setState({ loading: true });

      getBankRollerInvestment({
        params: `?${query}`,
        onSuccess: () => {
          this.setState({
            prevTableItems: [],
            investmentGranulation: granulation,
            investmentYear: year,
            investmentMonth: month,
            loading: false
          });
        },
        onError: () => {}
      })
    }
  }

  get search() {
    const { location } = this.props;
    const { granulation, year, month } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return { granulation, year, month };
  }

  pushToSearch = (searchParams) => {
    const { history } = this.props;
    const search = { ...this.search };

    Object.entries(searchParams).forEach(([key, value]) => {
      if (value) search[key] = value;
      else delete search[key];
    });

    history.push(`?${qs.stringify(search)}`);
  };

  handleUpdateRate = async (id, data) => {
    const { editBankRollerRates, getBankRollerRates } = this.props;

    await editBankRollerRates({id, data});
    await getBankRollerRates({ });
  };

  handleUpdateUsers = async (data) => {
    const { setRoleSpecialUsers } = this.props;

    await setRoleSpecialUsers({ data });
  };

  handleCreateInvestment = (data) => {
    const { createInvestmentRequest } = this.props;

    createInvestmentRequest({ data });
  };

  handleUpdateInvestment = (data) => {
    const { updateInvestmentRequest } = this.props;

    updateInvestmentRequest({ data });
  };

  handleTableSwitch = () => {
    const { location } = this.props;
    const { investmentGranulation, isYear } = this.state;
    const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });
    const investmentGranulationYear = investmentGranulation === 'year';

    const yearValue = moment().format('YYYY');
    const monthValue = moment().format('MM');
    const granulationValue = investmentGranulationYear ? 'month' : 'year';

    const query = {
      granulation: granulationValue,
      year: yearValue,
      month: investmentGranulationYear ? monthValue : null
    };

    this.setState({
      investmentGranulation: granulationValue,
      investmentYear: yearValue,
      investmentMonth: monthValue,
      isYear: !isYear
    });

    this.pushToSearch({ ...searchObj, ...query });
  };

  handleDateChange = (date, operations) => {
    const { location } = this.props;
    const { investmentGranulation, isYear } = this.state;
    const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });
    const investmentGranulationYear = investmentGranulation === 'year';

    const yearValue = date ? moment(date).format('YYYY') : moment().format('YYYY');
    const monthValue = date ? moment(date).format('MM') : moment().format('MM');

    const currentYear = () => {
      if (operations === increment) {
        return +yearValue + 1;
      } else if (operations === decrement) {
        return +yearValue - 1;
      } else {
        return +yearValue;
      }
    };

    const currentMonth = () => {
      if (operations === increment) {
        const numbIncrement = +monthValue + 1;
        return numbIncrement.length > 1 ? numbIncrement : `0${numbIncrement}`
      } else if (operations === decrement) {
        const numbDecrement = +monthValue - 1;
        return numbDecrement.length > 1 ? numbDecrement : `0${numbDecrement}`
      } else {
        return +monthValue;
      }
    };

    const query = {
      granulation: investmentGranulation,
      year: investmentGranulationYear ? currentYear() : yearValue,
      month: investmentGranulationYear ? null : currentMonth()
    };

    this.setState({
      investmentYear: yearValue,
      investmentMonth: monthValue
    });

    this.pushToSearch({ ...searchObj, ...query });
  };

  render() {
    const { bankRoller, currentUser } = this.props;
    const { isYear, investmentYear } = this.state;
    const tableDate = bankRoller.investments && bankRoller.investments.common && bankRoller.investments.common[0].date_from;
    const flexUser = bankRoller.users && bankRoller.users.length && bankRoller.users.find(user => user.additional_role === 'flex');
    const upUser = bankRoller.users && bankRoller.users.length && bankRoller.users.find(user => user.additional_role === 'up');
    const thirdpartyUser = bankRoller.users && bankRoller.users.length && bankRoller.users.find(user => user.additional_role === 'thirdparty');
    const thirdpartyRate = bankRoller.rates.length && bankRoller.rates.find(rate => rate.id === 'profit_ratio');

    return (
      <Layout title="BankRoller">
        <S.TableWrapper>
          <S.SpecialUsers>
            <User
              handleUpdateUsers={this.handleUpdateUsers}
              handleCreateInvestment={this.handleCreateInvestment}
              handleUpdateInvestment={this.handleUpdateInvestment}
              userType="flex"
              currentUser={currentUser}
              user={flexUser}
              specialStakes={bankRoller.specialStakes.flex}
            />

            <User
              handleUpdateUsers={this.handleUpdateUsers}
              handleCreateInvestment={this.handleCreateInvestment}
              handleUpdateInvestment={this.handleUpdateInvestment}
              userType="thirdparty"
              currentUser={currentUser}
              user={thirdpartyUser}
              specialStakes={bankRoller.specialStakes.thirdparty}
              handleUpdateRate={this.handleUpdateRate}
              rate={thirdpartyRate}
            />

            <User
              handleUpdateUsers={this.handleUpdateUsers}
              handleCreateInvestment={this.handleCreateInvestment}
              handleUpdateInvestment={this.handleUpdateInvestment}
              userType="up"
              currentUser={currentUser}
              user={upUser}
              specialStakes={bankRoller.specialStakes.up}
            />
          </S.SpecialUsers>

          <S.TableControl>
            <S.TablePeriod>
              <MUIButton
                type="button"
                size="small"
                variant="contained"
                color="primary"
                onClick={() => this.handleDateChange(tableDate, decrement)}
              >
                Prev
              </MUIButton>

              <S.TablePeriodInfo>
                {isYear ?
                  investmentYear :
                  tableDate ? `${moment(tableDate).format('MMM')} ${investmentYear}` : `${moment().format('MMM')} ${investmentYear}`}
              </S.TablePeriodInfo>

              <MUIButton
                type="button"
                size="small"
                variant="contained"
                color="primary"
                onClick={() => this.handleDateChange(tableDate, increment)}
              >
                Next
              </MUIButton>
            </S.TablePeriod>

            <TableSwitcher
              isYear={isYear}
              handleTableSwitch={this.handleTableSwitch}
            />
          </S.TableControl>

          <TableBankRoller
            isYear={isYear}
            dataTable={bankRoller && bankRoller.investments}
          />
        </S.TableWrapper>
      </Layout>
    );
  }
}

const mapState = state => ({
  bankRoller: state.bankRoller,
  currentUser: state.currentUser
});

const mapDispatch = {
  getInvestmentRequest: getInvestmentRequestAction,
  createInvestmentRequest: createInvestmentRequestAction,
  updateInvestmentRequest: updateInvestmentRequestAction,
  getBankRollerRates: getBankRollerRatesRequest,
  editBankRollerRates: editBankRollerRatesRequest,
  getBankRollerInvestment: getBankRollerInvestmentRequest,
  getBankRollerSpecialUsers: getBankRollerSpecialUsersRequest,
  setRoleSpecialUsers: setRoleSpecialUsersRequest,
  currentSpecialStakes: currentSpecialStakesRequest
};

export default connect(
  mapState,
  mapDispatch
)(BankRollerPage);
