import { takeLatest, call, put } from "redux-saga/effects";

import apiCaller from "../../utils/apiCaller";
import {
  GET_MANAGEMENT_REQUEST,
  getManagementSuccess,
  getManagementFail,
  GET_MANAGEMENT_DURATION_REQUEST,
  getManagementDurationSuccess,
  getManagementDurationFail
} from "../actions/riskAnalysis";
import { HTTP_METHODS } from "../../config";

function* getAnalysisManagement({ payload: { onSuccess, onError, params = "" } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: `/api/admin/bet/risk-analysis${params}`,
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getManagementSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getManagementFail, err);
    yield put(errAction);
  }
}

function* getDurationManagement({ payload: { onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: '/api/admin/bet/durations',
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getManagementDurationSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getManagementDurationFail, err);
    yield put(errAction);
  }
}

export default function*() {
  yield takeLatest(GET_MANAGEMENT_REQUEST, getAnalysisManagement);
  yield takeLatest(GET_MANAGEMENT_DURATION_REQUEST, getDurationManagement);
}
