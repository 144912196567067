import React, { Component } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";

// import MUI
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ASSETS_PAGE } from "../../config/routes";

import { signInRequest } from "../../store/actions/signIn";
import yupValidationSchema from "../../utils/validation";
import {
  MUIInput,
  Main,
  SignInWrapper,
  FormWrapper,
  FormControls,
  Form,
  Error,
} from "./styles";

class SignInPage extends Component {
  state = {
    responseErrors: null,
    first: null,
  };

  handleSubmit = (values) => {
    const { signIn, history } = this.props;

    signIn({
      data: { ...values, email: values.email.toLowerCase() },
      onSuccess: () => history.push(ASSETS_PAGE),
      onError: (responseErrors) => this.setState({ responseErrors }),
    });
  };

  render() {
    const { responseErrors } = this.state;

    return (
      <Main>
        <SignInWrapper>
          <Paper>
            <FormWrapper>
              <Avatar>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              {responseErrors && <Error>{responseErrors}</Error>}
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  email: yupValidationSchema.email,
                  password: yupValidationSchema.password,
                })}
                onSubmit={this.handleSubmit}
                render={({ errors, touched, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormControls>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                          name="email"
                          render={({ field }) => (
                            <>
                              <InputLabel htmlFor="email">
                                Email Address
                              </InputLabel>
                              <MUIInput
                                {...field}
                                error={touched.email && !!errors.email}
                                id="email"
                                name="email"
                                autoComplete="email"
                              />
                              <FormHelperText error>
                                {touched.email && !!errors.email
                                  ? errors.email
                                  : ""}
                              </FormHelperText>
                            </>
                          )}
                        />
                      </FormControl>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                          name="password"
                          render={({ field }) => (
                            <>
                              <InputLabel htmlFor="password">
                                Password
                              </InputLabel>
                              <Input
                                {...field}
                                error={touched.password && !!errors.password}
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                              />
                              <FormHelperText error>
                                {touched.password && !!errors.password
                                  ? errors.password
                                  : ""}
                              </FormHelperText>
                            </>
                          )}
                        />
                      </FormControl>
                    </FormControls>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Sign in
                    </Button>
                  </Form>
                )}
              />
            </FormWrapper>
          </Paper>
        </SignInWrapper>
      </Main>
    );
  }
}

const mapDispatch = {
  signIn: signInRequest,
};

export default connect(null, mapDispatch)(SignInPage);
