const orderPairs = [
  'BTCUSDT',
  'ETHUSDT',
  'ETHBTC',
  'SXPUSDT',
  'SXPBTC',
  'EOSUSDT',
  'EOSBTC',
  'BCHUSDT',
  'BCHBTC',
  'LINKUSDT',
  'LINKBTC',
];

export default function(pairs) {
  let otherPairs = [];

  const sortPairs = pairs.length
    ? pairs.filter(p => orderPairs.indexOf(p.name) >= 0).sort(function(a, b) {
      return orderPairs.indexOf(a.name) - orderPairs.indexOf(b.name);
    })
    : [];

  if (sortPairs.length !== pairs.length) {
    otherPairs = pairs.filter(p => orderPairs.indexOf(p.name) < 0);
  }

  return [...sortPairs, ...otherPairs];
}
