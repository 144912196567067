import * as React from 'react';
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
import LogOut from "@material-ui/icons/ExitToApp";

import * as S from './styles';

const User = ({ user, onLogout }) => (
  <S.User>
    {user && user.url ? (
      <Avatar
        alt={user && user.first_name}
        src={user && user.url}
      />
    ) : (
      <Avatar>
        <Person />
      </Avatar>
    )}

    <S.UserContent>
      <S.UserInfo>
        {`${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`}
      </S.UserInfo>

      <S.UserLogOut onClick={onLogout()}>
        <LogOut style={{ color: '#45B67E' }} /> Logout
      </S.UserLogOut>
    </S.UserContent>
  </S.User>
);

export default User;
