import { createAction } from "redux-actions";

const actionTypeName = "@bo/profitability/";

export const GET_PROFITABILITY_REQUEST = `${actionTypeName}GET_PROFITABILITY_REQUEST`;
export const GET_PROFITABILITY_SUCCESS = `${actionTypeName}GET_PROFITABILITY_SUCCESS`;
export const GET_PROFITABILITY_FAIL = `${actionTypeName}GET_PROFITABILITY_FAIL`;

export const GET_PROFITABILITY_SUMMARY_REQUEST = `${actionTypeName}GET_PROFITABILITY_SUMMARY_REQUEST`;
export const GET_PROFITABILITY_SUMMARY_SUCCESS = `${actionTypeName}GET_PROFITABILITY_SUMMARY_SUCCESS`;
export const GET_PROFITABILITY_SUMMARY_FAIL = `${actionTypeName}GET_PROFITABILITY_SUMMARY_FAIL`;

export const getProfitabilityRequest = createAction(GET_PROFITABILITY_REQUEST);
export const getProfitabilitySuccess = createAction(GET_PROFITABILITY_SUCCESS);
export const getProfitabilityFail = createAction(GET_PROFITABILITY_FAIL);

export const getProfitabilitySummaryRequest = createAction(GET_PROFITABILITY_SUMMARY_REQUEST);
export const getProfitabilitySummarySuccess = createAction(GET_PROFITABILITY_SUMMARY_SUCCESS);
export const getProfitabilitySummaryFail = createAction(GET_PROFITABILITY_SUMMARY_FAIL);
