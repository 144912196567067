import { takeLatest, call, put } from "redux-saga/effects";

import apiCaller from "../../utils/apiCaller";
import {
  GET_CURRENT_USER_REQUEST,
  getCurrentUserSuccess,
  getCurrentUserFail,

  UPDATE_CURRENT_USER_REQUEST,
  updateCurrentUserSuccess,
  updateCurrentUserFail,

  GET_INVESTMENT_REQUEST,
  getInvestmentSuccess,
  getInvestmentError,

  CREATE_INVESTMENT_REQUEST,
  createInvestmentSuccessAction,
  createInvestmentErrorAction,

  UPDATE_INVESTMENT_REQUEST,
  updateInvestmentSuccessAction,
  updateInvestmentErrorAction
} from "../actions/currentUser";
import { HTTP_METHODS } from "../../config";

function* getCurrentUser({ payload: { onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: '/api/profile',
      token: true,
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getCurrentUserSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getCurrentUserFail, err);
    yield put(errAction);
  }
}

function* updateCurrentUser({ payload: { data, onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.PUT, {
      url: '/api/profile',
      payload: {
        ...data
      },
      token: true,
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(updateCurrentUserSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(updateCurrentUserFail, err);
    yield put(errAction);
  }
}

function* getInvestment({ payload: { onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: '/api/investment',
      token: true,
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getInvestmentSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getInvestmentError, err);
    yield put(errAction);
  }
}

function* createInvestment({ payload: { data, onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.POST, {
      url: '/api/investment',
      payload: {
        ...data
      },
      token: true,
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(createInvestmentSuccessAction, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(createInvestmentErrorAction, err);
    yield put(errAction);
  }
}

function* updateInvestment({ payload: { data, onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.PUT, {
      url: '/api/investment',
      payload: {
        ...data
      },
      token: true,
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(updateInvestmentSuccessAction, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(updateInvestmentErrorAction, err);
    yield put(errAction);
  }
}

export default function*() {
  yield takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser);
  yield takeLatest(UPDATE_CURRENT_USER_REQUEST, updateCurrentUser);
  yield takeLatest(GET_INVESTMENT_REQUEST, getInvestment);
  yield takeLatest(CREATE_INVESTMENT_REQUEST, createInvestment);
  yield takeLatest(UPDATE_INVESTMENT_REQUEST, updateInvestment);
}
