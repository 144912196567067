import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";
import {
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAIL,
  EDIT_ASSET_SUCCESS,
  EDIT_INSTRUMENT_SUCCESS
} from "../actions/assets";

export default handleActions(
  {
    [GET_ASSETS_SUCCESS]: (state, action) => action.payload.assets,
    [GET_ASSETS_FAIL]: () => initialState.assets,
    [EDIT_ASSET_SUCCESS]: (state, action) => {
      return state.map(asset => {
        if (asset.id === action.payload.asset.id) {
          return action.payload.asset;
        }

        return asset;
      });
    },
    [EDIT_INSTRUMENT_SUCCESS]: (state, action) => {
      return state.map(asset => {
        if (asset.id === action.payload.asset.id) {
          return action.payload.asset;
        }

        return asset;
      });
    }
  },
  initialState.assets
);
