import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

export const TableWrapper = styled.div`
  padding: 0 30px;
`;

export const MUITextField = styled(TextField)`
  && {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 44px;
    padding-left: 0;
    margin-left: -5px;

    fieldset {
      width: 80%;
    }

    input {
      padding-left: 5px;
    }
  }
`;

export const CardWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  
  & > * {
    flex-grow: 1;
    max-width: 400px;
    width: 100%;
    margin: 0 20px 10px;
    box-sizing: border-box;
  }
`;

export const TableControl = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TablePeriod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-left: 150px;
  
  & > button {
    margin: 0!important;
  }
`;

export const TablePeriodInfo = styled.div`
  margin: 0 15px;
  font-size: 30px;
`;

export const SpecialUsers = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  
  & > * {
    flex-grow: 1;
    max-width: 400px;
    width: 100%;
    margin: 0 20px 10px;
  }
`;
