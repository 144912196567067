import { format } from 'date-fns';

export default (obj) => {
  let query = "?";

  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key] instanceof Date) {
      const value = format(obj[key], "yyyy-MM-dd'T'HH:mm:ss");
      return (query += `${key}=${value}&`);
    }

    if (obj[key]) {
      return (query += `${key}=${obj[key]}&`);
    }
  });

  return query;
};
