import styled, { css } from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

export const TableWrapper = styled.div`
  padding: 0 30px;
`;

export const ControlBnts = styled.div`
  display: flex;
  width: 100%;
`;

export const MUITableCell = styled(TableCell)`
  thead && {
    color: #3d4ea8;
    background-color: #F0EFF9;
    font-size: 14px;
    position: sticky;
    top: 93px;
    z-index: 10;
  }
  
  tbody & {
    border-bottom: none;
  }
  
  && {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 2px solid rgb(224, 224, 224);
    background-color: #fcfbfe;
    text-align: center;
    
    &:first-child {
      text-align: left;
    }
    
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6) {
      border-right: .5px dashed rgb(224, 224, 224);
    }
    
    &:last-child {
      border-right: none;
    }

    ${({ width }) =>
      width &&
      css`
        width: ${width};
      `}

    ${({ bold }) =>
      bold &&
      css`
        font-weight: bold;
      `}
  }
`;

export const MUITableRow = styled(TableRow)`
  && {
    height: 53px;

    td:last-child {
      width: 100px;

      div {
        justify-content: center;
      }
    }
  }
`;

export const MUITextField = styled(TextField)`
  && {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 44px;
    padding-left: 0;
    margin-left: -5px;
    
    & > div {
      height: 100%;
    }

    fieldset {
      width: 80%;
    }

    input {
      padding-left: 5px;
    }
  }
`;

export const NameInstrument = styled.div`
  white-space: nowrap;
`;
