import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import apiCaller from "../../utils/apiCaller";
import {
  GET_BETS_REQUEST,
  getBetsSuccess,
  getBetsFail,
  GET_BETS_STATE_REQUEST,
  getBetsStateSuccess,
  getBetsStateFail,
  TOGGLE_TRADING_STATE_REQUEST,
  toggleTradingStateSuccess,
  toggleTradingStateFail,
  GET_MASTER_WALLET_BALANCE_REQUEST,
  getMasterWalletBalanceSuccess,
  getMasterWalletBalanceFail
} from "../actions/bets";
import { HTTP_METHODS } from "../../config";

function* getBets({ payload: { onSuccess, onError, params = "" } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: `/api/admin/bets${params}`,
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getBetsSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getBetsFail, err);
    yield put(errAction);
  }
}

function* getBetsState({ payload: { onSuccess, onError, params = "" } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: '/api/admin/bet/get-disabling-state',
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getBetsStateSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getBetsStateFail, err);
    yield put(errAction);
  }
}

function* toggleTradingState({ payload: { onSuccess, onError, params = "" } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.PUT, {
      url: '/api/admin/bet/toggle-disabling-state',
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(toggleTradingStateSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(toggleTradingStateFail, err);
    yield put(errAction);
  }
}

function* getMasterWalletBalance({ payload: { onSuccess, onError, params = "" } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: '/api/admin/master-wallet-balance',
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getMasterWalletBalanceSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getMasterWalletBalanceFail, err);
    yield put(errAction);
  }
}

export default function*() {
  yield takeLatest(GET_BETS_REQUEST, getBets);
  yield takeLatest(GET_MASTER_WALLET_BALANCE_REQUEST, getMasterWalletBalance);
  yield takeEvery(GET_BETS_STATE_REQUEST, getBetsState);
  yield takeEvery(TOGGLE_TRADING_STATE_REQUEST, toggleTradingState);
}
