import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Bar } from 'react-chartjs-2';

import { format, } from 'date-fns';

const dailyDataset = {
  type: 'bar',
  label: "Profit by days",
  data: [],
  fill: false,
  backgroundColor: '#71B37C',
  borderColor: '#71B37C',
  hoverBackgroundColor: '#71B37C',
  hoverBorderColor: '#71B37C',
  yAxisID: 'y-axis-1'
};

const cumulativeDataset = {
  label: 'Cumulative profit',
  type: 'line',
  data: [],
  fill: true,
  borderColor: '#47b5ff',
  backgroundColor: 'rgba(71,181,255, 0.15)',
  pointBorderColor: '#47b5ff',
  pointBackgroundColor: '#47b5ff',
  pointHoverBackgroundColor: '#47b5ff',
  pointHoverBorderColor: '#47b5ff',
  yAxisID: 'y-axis-1'
};

const chartOptions = {
  responsive: true,
  tooltips: {
    mode: 'point'
  },
  elements: {
    line: {
      fill: true,
      tension: 0,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
        },
        labels: [],
      },
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        gridLines: {
          display: true,
        },
      },
    ],
  },
};


class ProfitabilityChart extends Component {
  componentDidMount() {
    const { init, props } = this;
    init(props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  init = props => {
    this.buildLabels(props);
  };

  buildLabels = props => {
    const { selectedMonth, stagesCount } = props;

    chartOptions.scales.xAxes[0].labels = Array(stagesCount)
      .fill(1)
      .map((i, index) => `${index + 1}/${format(new Date(selectedMonth), 'MM')}`);
  };

  processDataset = props => {
    this.datasets = [];
    this.injectWorkedTimeTotalLine(props);
    this.injectWorkedTimeDayLine(props);
  };

  render() {
    const { profitabilitySummary } = this.props;

    const formattedDailyDataset = {
      ...dailyDataset,
      data: profitabilitySummary ? profitabilitySummary.stages.map(v => v.toFixed(3)) : []
    };

    const formattedCumulativeDataset = { 
      ...cumulativeDataset,
      data: profitabilitySummary ? profitabilitySummary.stages
      .reduce((acc, value) => {
        const last = acc.length ? acc[acc.length - 1] : 0;
        return [...acc, last + value];
      }, [])
      .map(v => v.toFixed(3)) : []
    }

    return profitabilitySummary ? (
      <Grid container spacing={8}>
      <Grid item lg={1} />
      <Grid item xs={12} md={11} lg={10}>
        <Bar
          ref={ref => {
            this.chartClass = ref;
          }}
          data={{ datasets: [formattedDailyDataset, formattedCumulativeDataset] }}
          options={chartOptions}
        />
      </Grid>
      </Grid>
    ) : <div>Loading...</div>
  }
}

export default ProfitabilityChart;
