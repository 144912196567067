import styled, {css} from "styled-components";

const role = {
  default: css`
    order: 3;
  `,
  profit: css`
    order: 1;
  `,
  flex: css`
    order: 0;
  `,
  up: css`
    order: 2;
  `
};

export const Card = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: .6;
  `}
  
  ${props => {
    if (props.role === 'profit_ratio') return role.profit;
    if (props.role === 'flex_cut') return role.flex;
    if (props.role === 'up_cut') return role.up;
    return role.default;
  }};
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #2296f3;
  margin-right: 10px;
  white-space: nowrap;
`;

export const Content = styled.div`
  color: #bdbdbd;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  
  & > button {
    margin: 0 10px 0 0!important;
    
    &:last-child { margin: 0!important; }
  }
`;

export const InputWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
  
  &:last-child {
    margin-right: 0;
  }
  
  &:after {
    content: '%';
    position: absolute;
    right: 0;
    top: 8px;
    z-index: 2;
  }
  
  input {
    padding-right: 30px;
    height: 27px;
    font-size: 20px;
  }
`;
