import fetch from "isomorphic-unfetch";
import Cookies from "js-cookie";
import { HTTP_METHODS, TOKEN_KEY, BACKEND_URL } from "../config";

export default async (method, { url, payload, token = false }) => {
  try {
    if (token && !Cookies.get(TOKEN_KEY)) {
      throw new Error();
    }

    const config = {
      ...(method !== HTTP_METHODS.GET && { body: JSON.stringify(payload) }),
      method: method,
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Token token=${Cookies.get(TOKEN_KEY)}` })
      }
    };

    const res = await fetch(`${BACKEND_URL}${url}`, config);

    const data = await res.json().catch(() => ({ data: {} }));
    if (!res.ok) {
      throw {
        status: res.status,
        data
      };
    }
    return data;
  } catch (error) {
    throw error;
  }
};
