import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";

const getLastDayPeriod = () => {
  const date_from = new Date();
  const date_to = new Date();

  date_to.setHours(0, 0, 0, 0);
  date_from.setHours(0, 0, 0, 0);

  date_from.setDate(date_to.getDate() - 1);
  return { date_to, date_from };
};

const getLastWeekPeriod = () => {
  let date_from = new Date();
  let date_to;

  date_from = startOfWeek(date_from, { weekStartsOn: 1 });
  date_to = endOfWeek(date_from, { weekStartsOn: 1 });

  return { date_from, date_to };
};

const getLastMonthPeriod = () => {
  let date_from = new Date();
  let date_to;

  date_from = startOfMonth(date_from);
  date_to = endOfMonth(date_from);

  return { date_from, date_to };
};

export { getLastDayPeriod, getLastWeekPeriod, getLastMonthPeriod };
