import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";
import {
  GET_BETS_SUCCESS,
  GET_BETS_FAIL,
  GET_BETS_STATE_SUCCESS,
  GET_MASTER_WALLET_BALANCE_SUCCESS,
  GET_MASTER_WALLET_BALANCE_FAIL
} from "../actions/bets";

export default handleActions(
  {
    [GET_BETS_SUCCESS]: (state, action) => {
      return {
        ...state,
        stake_profit_sum: action.payload.stake_profit_sum,
        house_profit_sum: action.payload.house_profit_sum,
        items: action.payload.bets
      }
    },
    [GET_BETS_FAIL]: () => initialState.bets,
    [GET_BETS_STATE_SUCCESS]: (state, action) => {
      return {
        ...state,
        activeBets: {
          disabled: action.payload.disable,
          count: action.payload.active_bets_count,
        }
      }
    },

    [GET_MASTER_WALLET_BALANCE_SUCCESS]: (state, action) => {
      return {
        ...state,
        masterWalletBalance: action.payload.flex,
        masterWalletAddress: action.payload.flex_address
      }
    },
    [GET_MASTER_WALLET_BALANCE_FAIL]: () => initialState.bets
  },
  initialState.bets
);
