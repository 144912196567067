import React from "react";

import { store as s } from "react-notifications-component";
import Counter from "../components/Counter";

export default (
  expires,
  duration,
  extendExpiryDate,
  removeTimerId,
  storeTimerId
) => {
  const id = s.addNotification({
    title: "Your session is about to expiry",
    message: (
      <Counter
        expires={expires}
        extendExpiryDate={extendExpiryDate}
        removeTimerId={removeTimerId}
        storeTimerId={storeTimerId}
      />
    ),
    type: "warning",
    insert: "top",
    container: "bottom-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration,
      onScreen: true,
    },
  });

  return id;
};
