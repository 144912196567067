/* eslint-disable no-unused-expressions */
import React from "react";
import { format } from "date-fns";
import { Formik, Form } from "formik";
import * as yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  MUIFormControl,
  MUITextField,
  MUISelect,
  MUIMenuItem,
  MUIButton,
  MUIFormRow
} from "../../../../components/Mui";
import { renderInstrumentName } from '../../../../helpers/renderInstrumentsName';

import * as S from './styles';

let BetsSearchSchema = yup.object().shape({
  email: yup.string().email(),
  status: yup.string(),
  instrument: yup.string(),
  duration_id: yup.string(),
  asset_id: yup.string(),
  date_from: yup
    .date()
    .typeError("Value must be a date")
    .nullable(),
  date_to: yup
    .date()
    .typeError("Value must be a date")
    .nullable()
    .min(yup.ref("date_from"), "Date To must be later"),
  paid_from: yup
    .number()
    .typeError("Value must be a number")
    .positive("Number must be greater than zero"),
  paid_to: yup
    .number()
    .typeError("Value must be a number")
    .positive("Number must be greater than zero")
    .min(yup.ref("paid_from"), "Paid To must be later"),
});

const BetsSearchBar = ({ pushToSearch, searchObj, durations, instruments, house_profit_sum, stake_profit_sum, defaultValues }) => {
  const statuses = ["won", "lost", "drawn", "cancelled"];

  const handleClearFilter = () => {
    pushToSearch({
      order_by: { field: "id", direction: "desc" },
      date_from: defaultValues.date_from,
      date_to: defaultValues.date_to
    });
  };

  return (
    <Formik
      initialValues={{
        email: searchObj.email || "",
        status: searchObj.status || "",
        instrument_id: searchObj.instrument_id || "",
        duration_id: searchObj.duration_id || "",
        asset_id: searchObj.asset_id || "",
        date_from: searchObj.date_from || defaultValues.date_from,
        date_to: searchObj.date_to || defaultValues.date_to,
        paid_from: searchObj.paid_from || "",
        paid_to: searchObj.paid_to || "",
      }}
      validationSchema={BetsSearchSchema}
      onSubmit={(values) => { pushToSearch({...values, order_by: { field: "id", direction: "desc" }}) }}
    >
      {({
          errors,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          values
        }) => {
        return (
          <S.FormWrap>
            <Form>
              <MUIFormRow container>
                <MUIFormControl>
                  <MUITextField
                    error={errors.email && touched.email}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="email"
                    label="Email Address"
                    name="email"
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                  />
                </MUIFormControl>

                <MUIFormControl>
                  <InputLabel id="status-label">Status</InputLabel>

                  <MUISelect
                    id="status-id"
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                  >
                    <MUIMenuItem key={"null"} value="">
                      -
                    </MUIMenuItem>
                    {statuses.map((option) => (
                      <MUIMenuItem key={option} value={option}>
                        {option}
                      </MUIMenuItem>
                    ))}
                  </MUISelect>
                </MUIFormControl>

                <MUIFormControl>
                  <InputLabel id="instrument-label">Bet Type</InputLabel>

                  <MUISelect
                    id="instrument-id"
                    name="instrument_id"
                    value={values.instrument_id}
                    onChange={handleChange}
                  >
                    <MUIMenuItem key={"null"} value="">
                      -
                    </MUIMenuItem>
                    {instruments.map((option, i) => (
                      <MUIMenuItem key={i} value={option.id}>
                        {renderInstrumentName(option.name)}
                      </MUIMenuItem>
                    ))}
                  </MUISelect>
                </MUIFormControl>

                <MUIFormControl>
                  <InputLabel id="duration-label">Duration</InputLabel>

                  <MUISelect
                    id="duration-id"
                    name="duration_id"
                    value={values.duration_id}
                    onChange={handleChange}
                  >
                    <MUIMenuItem key={"null"} value="">
                      -
                    </MUIMenuItem>
                    {durations.map((option) => (
                      <MUIMenuItem key={option.id} value={option.id}>
                        {option.count + " " + option.unit + (option.count > 1 ? "s" : "")}
                      </MUIMenuItem>
                    ))}
                  </MUISelect>
                </MUIFormControl>
              </MUIFormRow>

              <MUIFormRow container>
                <MUIFormControl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="date-picker-from"
                      name="date_from"
                      label="Date From"
                      helperText={touched.date_from ? errors.date_from : ""}
                      error={touched.date_from && errors.date_from}
                      value={values.date_from}
                      onChange={(value) => setFieldValue("date_from", `${format(value, "yyyy-MM-dd")} 00:00:00`)}
                      onBlur={handleBlur("date_from")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </MUIFormControl>

                <MUIFormControl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="date-picker-to"
                      name="date_to"
                      label="Date To"
                      helperText={touched.date_to ? errors.date_to : ""}
                      error={touched.date_to && errors.date_to}
                      value={values.date_to}
                      onChange={(value) => setFieldValue("date_to", `${format(value, "yyyy-MM-dd")} 23:59:59`)}
                      onBlur={handleBlur("date_to")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  {stake_profit_sum || stake_profit_sum === 0
                    ? (<S.HouseProfitSum>
                      <S.HouseProfitSumTitle>Total Staked:</S.HouseProfitSumTitle>
                      <S.HouseProfitSumValue theme={stake_profit_sum}>{stake_profit_sum.toFixed(3)}</S.HouseProfitSumValue>
                    </S.HouseProfitSum>)
                    : null}
                </MUIFormControl>

                <MUIFormControl>
                  <MUITextField
                    error={errors.paid_from && touched.paid_from}
                    value={values.paid_from}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="paid_from"
                    label="Paid From"
                    name="paid_from"
                    helperText={
                      errors.paid_from && touched.paid_from
                        ? errors.paid_from
                        : ""
                    }
                  />

                  {house_profit_sum || house_profit_sum === 0
                    ? (<S.HouseProfitSum>
                      <S.HouseProfitSumTitle>Total House Profit:</S.HouseProfitSumTitle>
                      <S.HouseProfitSumValue theme={house_profit_sum}>{house_profit_sum.toFixed(3)}</S.HouseProfitSumValue>
                    </S.HouseProfitSum>)
                    : null}
                </MUIFormControl>

                <MUIFormControl>
                  <MUITextField
                    error={errors.paid_to && touched.paid_to}
                    value={values.paid_to}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id="paid_to"
                    label="Paid To"
                    name="paid_to"
                    helperText={
                      errors.paid_to && touched.paid_to ? errors.paid_to : ""
                    }
                  />

                  {house_profit_sum && stake_profit_sum
                    ? (<S.HouseProfitSum>
                      <S.HouseProfitSumTitle>Profit Margin:</S.HouseProfitSumTitle>
                      <S.HouseProfitSumValue theme={(house_profit_sum/stake_profit_sum*100)}>{(house_profit_sum/stake_profit_sum*100).toFixed(1)}%</S.HouseProfitSumValue>
                    </S.HouseProfitSum>)
                    : null}
                </MUIFormControl>

                <div>
                  <MUIButton
                    type="reset"
                    size="medium"
                    variant="contained"
                    onClick={handleClearFilter}
                  >
                    Clear
                  </MUIButton>

                  <MUIButton
                    type="submit"
                    size="medium"
                    variant="contained"
                    color="primary"
                  >
                    Filter
                  </MUIButton>
                </div>
              </MUIFormRow>
            </Form>
          </S.FormWrap>
        );
      }}
    </Formik>
  );
};

export default BetsSearchBar;
