import styled from "styled-components";
import { white } from "../../styles/colors";

export const SessionDelete = styled.button`
  box-shadow: none;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  position: relative;
  background-color: #a79b9a;
  cursor: pointer;
  margin-right: 10px;
  outline: none;
  z-index: 2;
  
  &.hide {
    display: none;
  }
  
  &:hover {
    background-color: #f48f70;
    outline: none;
  }
  
  &:focus,
  &:active {
    outline: none;
  }
  
  svg {
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  
  ${SessionDelete} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
  }
`;

export const UserDelete = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: ${white};
  padding: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
`;

export const ModalContent = styled.div`
  max-width: 300px;
  margin: auto;
  background-color: ${white};
  padding: 30px 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  outline: none;
`;

export const ModalTitle = styled.h3`
  text-align: center;
  margin: 0 0 30px;
`;

export const ModalButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  
  & > button {
    margin: 10px!important;
  }
`;
