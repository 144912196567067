import { takeLatest, call, put } from "redux-saga/effects";

import apiCaller from "../../utils/apiCaller";
import {
  GET_ASSETS_REQUEST,
  EDIT_ASSET_REQUEST,
  getAssetsSuccess,
  getAssetsFail,
  editAssetSuccess,
  editAssetFail,
  EDIT_INSTRUMENT_REQUEST,
  editInstrumentSuccess,
  editInstrumentFail
} from "../actions/assets";
import { HTTP_METHODS } from "../../config";

function* getAssets({ payload: { onSuccess, onError } }) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: "/api/admin/assets",
      token: true
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getAssetsSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getAssetsFail, err);
    yield put(errAction);
  }
}

function* editAsset({ payload: { id, data, onSuccess, onError } }) {
  try {
    const requestPayload = {
      url: `/api/admin/assets/${id}`,
      payload: {
        asset: data
      },
      token: true
    };

    const response = yield call(apiCaller, HTTP_METHODS.PUT, requestPayload);

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(editAssetSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(editAssetFail, err);
    yield put(errAction);
  }
}

function* editInstrument({ payload: { id, data, onSuccess, onError } }) {
  try {
    const requestPayload = {
      url: `/api/admin/instrument-configurations/${id}`,
      payload: {
        instrument_configuration: data
      },
      token: true
    };

    const response = yield call(apiCaller, HTTP_METHODS.PUT, requestPayload);

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(editInstrumentSuccess, response);
    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(editInstrumentFail, err);
    yield put(errAction);
  }
}

export default function*() {
  yield takeLatest(GET_ASSETS_REQUEST, getAssets);
  yield takeLatest(EDIT_ASSET_REQUEST, editAsset);
  yield takeLatest(EDIT_INSTRUMENT_REQUEST, editInstrument);
}
