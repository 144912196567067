import React, { useState, useEffect } from "react";

// import MUI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Edit from "@material-ui/icons/Edit";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import INSTRUMENTS from "../../../../config/instrumentsName";

import {
  ControlBnts,
  MUITableCell,
  MUITableRow,
  MUITextField,
  NameInstrument
} from "./styles";

const InstrumentsTable = ({ items, editInstrument, getAssets }) => {
  const [idOfEditableItem, setEditableItem] = useState(null);
  const [newItems, setNewItems] = useState(items || []);

  useEffect(() => {
    setNewItems(items);
  }, [items]);

  const title = (instrumentName) => {
    switch (instrumentName) {
      case INSTRUMENTS.OVER: return 'Pump';
      case INSTRUMENTS.UNDER: return 'Dump';
      case INSTRUMENTS.HIGHLINE: return 'Big Pump';
      case INSTRUMENTS.LOWLINE: return 'Big Dump';
      case INSTRUMENTS.HIGHTOUCH: return 'Touch Up';
      case INSTRUMENTS.LOWTOUCH: return 'Touch Down';
      case INSTRUMENTS.DOUBLE_NO_TOUCH: return 'Double Crash';
      case INSTRUMENTS.TUNNEL: return 'Flatline';
      case INSTRUMENTS.OVER_TUG_OF_WAR: return 'Cash or Crash';
      case INSTRUMENTS.UNDER_TUG_OF_WAR: return 'Crash or Cash';
      case INSTRUMENTS.HI: return 'Hi-300';
      case INSTRUMENTS.LO: return 'Lo-300';
      case INSTRUMENTS.HILO: return 'Hi-Lo';
      case INSTRUMENTS.HITOUCH: return 'Hi-T-200';
      case INSTRUMENTS.LOTOUCH: return 'Lo-T-200';
      case INSTRUMENTS.IN_OR_OUT: return 'In Out';
      case INSTRUMENTS.DOUBLE_CRASH: return '2x Crash';
      case INSTRUMENTS.CASH_OR_CRASH: return 'Tug\'o\'War';
      default: return '';
    }
  };

  const empyCell = () => <b style={{ color: 'red' }}>N/A</b>;

  const orderInstrument = {
    over: 1,
    under: 2,
    highline: 3,
    lowline: 4,
    hightouch: 5,
    lowtouch: 6,
    double_no_touch: 7,
    tunnel: 8,
    over_tug_of_war: 9,
    under_tug_of_war: 10,
    hi: 11,
    lo: 12,
    hilo: 13,
    hitouch: 14,
    lotouch: 15,
    in_or_out: 16,
    double_crash: 17,
    cash_or_crash: 18
  };

  const handleSaveInstrument = () => {
    const newData = newItems.find(item => item.id === idOfEditableItem);

    editInstrument({
      id: newData.id,
      data: newData,
      onSuccess: () => {
        setEditableItem(null);
        getAssets({ });
      }
    });
  };

  const handleInputChange = (event, key) => {
    const updatedItems = !!newItems ? newItems.map(item => {
      if (idOfEditableItem === item.id) {
        return {
          ...item,
          [key]: event.target.value
        };
      }

      return item;
    }) : [];

    setNewItems(updatedItems)
  };

  const intrumentsArr = [...newItems];
  const newIntrumentsArr = intrumentsArr.sort((l,r) =>  orderInstrument[l.name] - orderInstrument[r.name]);

  return (
    <Table>
      <TableHead>
        <MUITableRow>
          <MUITableCell width="12%">Name</MUITableCell>

          <MUITableCell width="10%" padding="none">
            Initial profit margin
          </MUITableCell>
          <MUITableCell width="10%" padding="none">
            Final profit margin
          </MUITableCell>

          <MUITableCell width="10%" padding="none">
            Initial base percent return
          </MUITableCell>
          <MUITableCell width="8%" padding="none">
            Final base percent return
          </MUITableCell>

          <MUITableCell width="15%" padding="none">
            Min Percent Return
          </MUITableCell>
          <MUITableCell width="10%" padding="none">
            Max percent return
          </MUITableCell>

          <MUITableCell width="15%" padding="none">
            Probability of touching upper strike
          </MUITableCell>

          <MUITableCell width="10%" />
        </MUITableRow>
      </TableHead>

      <TableBody>
        {!!newIntrumentsArr && newIntrumentsArr.map(item => (
          <MUITableRow
            hover={idOfEditableItem !== item.id}
            key={item.id}
          >
            <MUITableCell bold="true">
              <NameInstrument>{title(item.name)}</NameInstrument>
            </MUITableCell>

            <MUITableCell padding="none">
              {[
                INSTRUMENTS.OVER,
                INSTRUMENTS.UNDER,
                INSTRUMENTS.OVER_TUG_OF_WAR,
                INSTRUMENTS.UNDER_TUG_OF_WAR,
              ].find(i => i === item.name) ? empyCell() : (
                <>
                  {idOfEditableItem === item.id ? (
                    <MUITextField
                      value={item.initial_profit_margin}
                      margin="normal"
                      type="number"
                      onChange={e => handleInputChange(e, "initial_profit_margin")}
                      variant="outlined"
                    />
                  ) : (
                    item.initial_profit_margin
                  )}
                </>
              )}
            </MUITableCell>

            <MUITableCell padding="none">
              {[
                INSTRUMENTS.OVER,
                INSTRUMENTS.UNDER,
                INSTRUMENTS.OVER_TUG_OF_WAR,
                INSTRUMENTS.UNDER_TUG_OF_WAR,
                INSTRUMENTS.HILO,
                INSTRUMENTS.HI,
                INSTRUMENTS.LO,
                INSTRUMENTS.IN_OR_OUT,
                INSTRUMENTS.DOUBLE_CRASH,
                INSTRUMENTS.CASH_OR_CRASH
              ].find(i => i === item.name) ? empyCell() : (
                <>
                  {idOfEditableItem === item.id ? (
                    <MUITextField
                      value={item.final_profit_margin}
                      margin="normal"
                      type="number"
                      onChange={e => handleInputChange(e, "final_profit_margin")}
                      variant="outlined"
                    />
                  ) : (
                    item.final_profit_margin
                  )}
                </>
              )}
            </MUITableCell>

            <MUITableCell padding="none">
              {[
                INSTRUMENTS.HILO
              ].find(i => i === item.name) ? empyCell() : (
                <>
                  {idOfEditableItem === item.id ? (
                    <MUITextField
                      value={item.initial_base_percent_return}
                      margin="normal"
                      type="number"
                      onChange={e => handleInputChange(e, "initial_base_percent_return")}
                      variant="outlined"
                    />
                  ) : (
                    item.initial_base_percent_return
                  )}
                </>
              )}
            </MUITableCell>

            <MUITableCell padding="none">
              {[
                INSTRUMENTS.HIGHLINE,
                INSTRUMENTS.LOWLINE,
                INSTRUMENTS.HIGHTOUCH,
                INSTRUMENTS.LOWTOUCH,
                INSTRUMENTS.TUNNEL,
                INSTRUMENTS.DOUBLE_NO_TOUCH,
                INSTRUMENTS.HI,
                INSTRUMENTS.LO,
                INSTRUMENTS.HILO,
                INSTRUMENTS.HITOUCH,
                INSTRUMENTS.LOTOUCH,
                INSTRUMENTS.IN_OR_OUT,
                INSTRUMENTS.DOUBLE_CRASH
              ].find(i => i === item.name) ? empyCell() : (
                <>
                  {idOfEditableItem === item.id ? (
                    <MUITextField
                      value={item.final_base_percent_return}
                      margin="normal"
                      type="number"
                      onChange={e => handleInputChange(e, "final_base_percent_return")}
                      variant="outlined"
                    />
                  ) : (
                    item.final_base_percent_return
                  )}
                </>
              )}
            </MUITableCell>

            <MUITableCell padding="none">
              {[
                INSTRUMENTS.TUNNEL,
                INSTRUMENTS.DOUBLE_NO_TOUCH,
                INSTRUMENTS.HI,
                INSTRUMENTS.LO,
                INSTRUMENTS.HILO,
                INSTRUMENTS.HITOUCH,
                INSTRUMENTS.LOTOUCH,
                INSTRUMENTS.IN_OR_OUT,
                INSTRUMENTS.DOUBLE_CRASH
              ].find(i => i === item.name) ? empyCell() : (
                <>
                  {idOfEditableItem === item.id ? (
                    <MUITextField
                      value={item.min_percent_return}
                      margin="normal"
                      type="number"
                      onChange={e => handleInputChange(e, "min_percent_return")}
                      variant="outlined"
                    />
                  ) : (
                    item.min_percent_return
                  )}
                </>
              )}
            </MUITableCell>

            <MUITableCell padding="none">
              {[
                INSTRUMENTS.TUNNEL,
                INSTRUMENTS.DOUBLE_NO_TOUCH,
                INSTRUMENTS.HI,
                INSTRUMENTS.LO,
                INSTRUMENTS.HILO,
                INSTRUMENTS.HITOUCH,
                INSTRUMENTS.LOTOUCH,
                INSTRUMENTS.IN_OR_OUT,
                INSTRUMENTS.DOUBLE_CRASH
              ].find(i => i === item.name) ? empyCell() : (
                <>
                  {idOfEditableItem === item.id ? (
                    <MUITextField
                      value={item.max_percent_return}
                      margin="normal"
                      type="number"
                      onChange={e => handleInputChange(e, "max_percent_return")}
                      variant="outlined"
                    />
                  ) : (
                    item.max_percent_return
                  )}
                </>
              )}
            </MUITableCell>

            <MUITableCell padding="none">
              {[
                INSTRUMENTS.OVER,
                INSTRUMENTS.UNDER,
                INSTRUMENTS.HIGHLINE,
                INSTRUMENTS.LOWLINE,
                INSTRUMENTS.HIGHTOUCH,
                INSTRUMENTS.LOWTOUCH,
                INSTRUMENTS.TUNNEL,
                INSTRUMENTS.DOUBLE_NO_TOUCH,
                INSTRUMENTS.HI,
                INSTRUMENTS.LO,
                INSTRUMENTS.HILO,
                INSTRUMENTS.HITOUCH,
                INSTRUMENTS.LOTOUCH,
                INSTRUMENTS.IN_OR_OUT,
                INSTRUMENTS.DOUBLE_CRASH
              ].find(i => i === item.name) ? empyCell() : (
                <>
                  {idOfEditableItem === item.id ? (
                    <MUITextField
                      value={item.probability_of_touching_upper_strike}
                      margin="normal"
                      type="number"
                      onChange={e => handleInputChange(e, "probability_of_touching_upper_strike")}
                      variant="outlined"
                    />
                  ) : (
                    item.probability_of_touching_upper_strike
                  )}
                </>
              )}
            </MUITableCell>

            <MUITableCell padding="none">
              <ControlBnts>
                {idOfEditableItem === item.id ? (
                  <>
                    <IconButton onClick={handleSaveInstrument}>
                      <CheckCircle />
                    </IconButton>

                    <IconButton onClick={() => setEditableItem(null)}>
                      <Cancel />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    onClick={() => setEditableItem(item.id)}
                  >
                    <Edit />
                  </IconButton>
                )}
              </ControlBnts>
            </MUITableCell>
          </MUITableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default InstrumentsTable;
