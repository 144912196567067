import React, { Component } from "react";
import { connect } from "react-redux";

// import MUI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Edit from "@material-ui/icons/Edit";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import sortPairsFunc from '../../helpers/sortPairs';
import { renderAssetName } from '../../helpers/renderAssetName';
import { getAssetsRequest, editAssetRequest, editInstrumentRequest } from "../../store/actions/assets";
import Layout from "../../components/Layout";
import Switcher from "../../components/Switcher";
import { SIGN_IN_PAGE } from "../../config/routes";

import InstrumentsTable from "./components/Instruments";

import {
  TableWrapper,
  ControlBnts,
  MUITableCell,
  MUITableRow,
  MUITextField,
  DropdownControl,
  DropdownControlText
} from "./styles";

class AssetsPage extends Component {
  constructor(props) {
    super(props);

    const { items } = props;

    this.state = {
      idOfEditableItem: null,
      idOfInstrumentAsset: null,
      newItems: items
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      newItems: nextProps.items
    });
  }

  handleEditItem = idOfEditableItem => this.setState({ idOfEditableItem });

  cancelEditItem = () => this.setState({ idOfEditableItem: null });

  handleOpenInstrument = id => id === this.state.idOfInstrumentAsset
    ? this.setState({ idOfInstrumentAsset: null })
    : this.setState({ idOfInstrumentAsset: id });

  handleSaveAsset = () => {
    const { editAsset } = this.props;
    const { idOfEditableItem, newItems } = this.state;

    const newData = newItems.find(item => item.id === idOfEditableItem);

    editAsset({
      id: idOfEditableItem,
      data: newData,
      onSuccess: this.cancelEditItem
    });
  };

  handleInputChange = (event, key) => {
    const { idOfEditableItem } = this.state;

    this.setState({
      newItems: this.state.newItems.map(item => {
        if (idOfEditableItem === item.id) {
          return {
            ...item,
            [key]: event.target.value
          };
        }

        return item;
      })
    });
  };

  componentDidMount() {
    const { getAssets, history } = this.props;

    getAssets({
      onError: () => history.push(SIGN_IN_PAGE)
    });
  }

  render() {
    const { idOfEditableItem, newItems, idOfInstrumentAsset } = this.state;
    const { editAsset, editInstrument, getAssets } = this.props;

    const sortPairs = newItems && newItems.length ? sortPairsFunc(newItems) : [];

    return (
      <Layout title="Assets / Instruments">
        <TableWrapper>
          <Paper>
            <Table>
              <TableHead>
                <MUITableRow>
                  <MUITableCell width="4%" />
                  <MUITableCell bold="true" width="11%">Name</MUITableCell>
                  <MUITableCell width="10%" padding="none">
                    Max trade size
                  </MUITableCell>
                  <MUITableCell width="11%" padding="none">
                    Min tick movement
                  </MUITableCell>
                  <MUITableCell width="10%" padding="none">
                    Minutes to expiry for fop adjustment
                  </MUITableCell>
                  <MUITableCell width="10%" padding="none">
                    Minutes to Expiry for Strike Adjustments
                  </MUITableCell>
                  <MUITableCell width="10%" padding="none">
                    Rebate percentage
                  </MUITableCell>
                  <MUITableCell width="10%" padding="none">
                    Risk analysis increment
                  </MUITableCell>
                  <MUITableCell width="10%" padding="none">
                    Risk Divisor
                  </MUITableCell>
                  <MUITableCell width="9%" padding="none">
                    Risk Range
                  </MUITableCell>
                  <MUITableCell width="8%" padding="none">
                    Round to
                  </MUITableCell>
                  <MUITableCell width="10%" />
                </MUITableRow>
              </TableHead>

              <TableBody>
                {sortPairs && sortPairs.length ? sortPairs.map(item => (
                  <>
                    <MUITableRow
                      hover={idOfEditableItem !== item.id}
                      key={`${item.id}_${item.name}`}
                    >
                      <MUITableCell bold="true">
                        <Switcher
                          isChecked={item.active}
                          handleChange={() => {
                            editAsset({
                              id: item.id,
                              data: {
                                ...item,
                                active: !item.active
                              }
                            })
                          }}
                        />
                      </MUITableCell>

                      <MUITableCell bold="true">
                        <DropdownControl
                          active={item.active}
                          onClick={() => this.handleOpenInstrument(item.id)}
                          opened={idOfInstrumentAsset === item.id}
                        >
                          <ExpandMoreIcon color={"primary"} />
                          <DropdownControlText>{renderAssetName(item.name)}</DropdownControlText>
                        </DropdownControl>
                      </MUITableCell>

                      <MUITableCell padding="none">
                        {idOfEditableItem === item.id ? (
                          <MUITextField
                            value={item.trade_size_limit}
                            margin="normal"
                            type="number"
                            onChange={event =>
                              this.handleInputChange(event, "trade_size_limit")
                            }
                            variant="outlined"
                          />
                        ) : (
                          item.trade_size_limit
                        )}
                      </MUITableCell>

                      <MUITableCell padding="none">
                        {idOfEditableItem === item.id ? (
                          <MUITextField
                            value={item.min_tick_movement}
                            margin="normal"
                            type="number"
                            onChange={event =>
                              this.handleInputChange(event, "min_tick_movement")
                            }
                            variant="outlined"
                          />
                        ) : (
                          item.min_tick_movement
                        )}
                      </MUITableCell>

                      <MUITableCell padding="none">
                        {idOfEditableItem === item.id ? (
                          <MUITextField
                            value={item.minutes_to_expiry_for_fop_adjustment}
                            margin="normal"
                            type="number"
                            onChange={event =>
                              this.handleInputChange(
                                event,
                                "minutes_to_expiry_for_fop_adjustment"
                              )
                            }
                            variant="outlined"
                          />
                        ) : (
                          item.minutes_to_expiry_for_fop_adjustment
                        )}
                      </MUITableCell>

                      <MUITableCell padding="none">
                        {idOfEditableItem === item.id ? (
                          <MUITextField
                            value={item.minutes_to_expiry_for_strike_adjustment}
                            margin="normal"
                            type="number"
                            onChange={event =>
                              this.handleInputChange(
                                event,
                                "minutes_to_expiry_for_strike_adjustment"
                              )
                            }
                            variant="outlined"
                          />
                        ) : (
                          item.minutes_to_expiry_for_strike_adjustment
                        )}
                      </MUITableCell>

                      <MUITableCell padding="none">
                        {idOfEditableItem === item.id ? (
                          <MUITextField
                            value={item.rebate_percentage}
                            margin="normal"
                            type="number"
                            onChange={event =>
                              this.handleInputChange(event, "rebate_percentage")
                            }
                            variant="outlined"
                          />
                        ) : (
                          item.rebate_percentage
                        )}
                      </MUITableCell>

                      <MUITableCell padding="none">
                        {idOfEditableItem === item.id ? (
                          <MUITextField
                            value={item.risk_analysis_increment}
                            margin="normal"
                            type="number"
                            onChange={event =>
                              this.handleInputChange(
                                event,
                                "risk_analysis_increment"
                              )
                            }
                            variant="outlined"
                          />
                        ) : (
                          item.risk_analysis_increment
                        )}
                      </MUITableCell>

                      <MUITableCell padding="none">
                        {idOfEditableItem === item.id ? (
                          <MUITextField
                            value={item.risk_divisor}
                            margin="normal"
                            type="number"
                            onChange={event =>
                              this.handleInputChange(event, "risk_divisor")
                            }
                            variant="outlined"
                          />
                        ) : (
                          item.risk_divisor
                        )}
                      </MUITableCell>

                      <MUITableCell padding="none">
                        {idOfEditableItem === item.id ? (
                          <MUITextField
                            value={item.risk_range}
                            margin="normal"
                            type="number"
                            onChange={event =>
                              this.handleInputChange(event, "risk_range")
                            }
                            variant="outlined"
                          />
                        ) : (
                          item.risk_range
                        )}
                      </MUITableCell>

                      <MUITableCell padding="none">
                        {idOfEditableItem === item.id ? (
                          <MUITextField
                            value={item.round_to}
                            margin="normal"
                            type="number"
                            onChange={event =>
                              this.handleInputChange(event, "round_to")
                            }
                            variant="outlined"
                          />
                        ) : (
                          item.round_to
                        )}
                      </MUITableCell>

                      <MUITableCell padding="none">
                        <ControlBnts>
                          {idOfEditableItem === item.id ? (
                            <>
                              <IconButton onClick={this.handleSaveAsset}>
                                <CheckCircle />
                              </IconButton>
                              <IconButton onClick={this.cancelEditItem}>
                                <Cancel />
                              </IconButton>
                            </>
                          ) : (
                            <IconButton
                              onClick={() => this.handleEditItem(item.id)}
                            >
                              <Edit />
                            </IconButton>
                          )}
                        </ControlBnts>
                      </MUITableCell>
                    </MUITableRow>

                    {idOfInstrumentAsset === item.id ? (
                      <MUITableRow key={`${item.id}`}>
                        <MUITableCell colSpan="12">
                          <InstrumentsTable
                            items={item.configurations}
                            editInstrument={editInstrument}
                            getAssets={getAssets}
                          />
                        </MUITableCell>
                      </MUITableRow>
                    ) : null}
                  </>
                )) : null}
              </TableBody>
            </Table>
          </Paper>
        </TableWrapper>
      </Layout>
    );
  }
}

const mapState = state => ({
  items: state.assets
});

const mapDispatch = {
  getAssets: getAssetsRequest,
  editAsset: editAssetRequest,
  editInstrument: editInstrumentRequest
};

export default connect(
  mapState,
  mapDispatch
)(AssetsPage);
