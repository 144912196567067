import React, {Component} from "react";

import {connect} from "react-redux";
import {format} from "date-fns";
import parseISO from "date-fns/parseISO";
import qs from 'qs';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

import {SIGN_IN_PAGE} from "../../config/routes";
import {probabilityToFixedOdds} from '../../helpers/probability';
import getAccurency from '../../helpers/minTickToAccurancy';
import { renderInstrumentName } from '../../helpers/renderInstrumentsName';
import {MUITableCell, MUITableRow, MUITableSort, TableContent, TableWrapper, DateWrap} from "../../components/Mui";
import Layout from "../../components/Layout";
import SearchBar from "./components/SearchBar";
import Preloader from "../../components/Preloader";

import {getBetsRequest} from "../../store/actions/bets";
import {getInstrumentsRequest} from "../../store/actions/instruments";
import {getDurationsRequest} from "../../store/actions/durations";
import {getAssetsRequest} from "../../store/actions/assets";

const tableHeadMap = {
  "Bet ID": "id",
  "Player": "first_name",
  "Bet Type": "instrument_id",
  "Bet Choice": "outcome",
  "Duration": "duration",
  "Finishing Line": "expires_at",
  "Price": "initial_price",
  "Target 1": "strike_1",
  "Target 2": "strike_2",
  "Stake": "amount",
  "Odds": "initial_probability",
  "Time of Bet": "inserted_at",
  "State": "status",
  "Final Price": "final_price",
  "Paid Out": "payout",
  "House Profit": "house_profit",
  "Bet Ended": "expired_at",
};

const pageSize = 10;

class BetsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idOfEditableItem: null,
      currentTableItems: [],
      sortingParameter: "id",
      sortingReversed: "desc",
      loading: false,
      offset: {}
    };
  }

  async componentDidMount() {
    const { getBets, history, location, getAssets, getDurations, getInstruments } = this.props;
    const { sortingParameter, sortingReversed } = this.state;
    const { order_by } = this.search;
    const queryStr = qs.parse(location.search, { ignoreQueryPrefix: true });

    const query = location.search ?
      qs.stringify(queryStr) :
      qs.stringify({
        page: { size: pageSize},
        order_by: { field: sortingParameter, direction: sortingReversed },
        date_from: `${format(new Date, "yyyy-MM-dd")} 00:00:00`,
        date_to:  `${format(new Date, "yyyy-MM-dd")} 23:59:59`
      });

    await getAssets({ });
    await getDurations({ });
    await getInstruments({ });

    await getBets({
      params: `?${query}`,
      onSuccess: () => {
        this.setState({
          loading: false
        });
      },
      onError: () => {
        history.push(SIGN_IN_PAGE);
        this.setState({
          loading: false
        });
      }
    });

    if (order_by && order_by.field) {
      await this.setState({
        sortingParameter: order_by.field
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location: { search }, getBets, location } = this.props;
    const { location: { search: prevSearch } } = prevProps;
    const { sortingParameter, sortingReversed } = this.state;
    const { order_by } = this.search;
    const queryStr = qs.parse(location.search, { ignoreQueryPrefix: true });

    const query = location.search ?
      qs.stringify(queryStr) :
      qs.stringify({
        page: { size: pageSize},
        order_by: { field: sortingParameter, direction: sortingReversed },
        date_from: `${format(new Date, "yyyy-MM-dd")} 00:00:00`,
        date_to:  `${format(new Date, "yyyy-MM-dd")} 23:59:59`
      });

    if (search !== prevSearch) {
      this.setState({ loading: true });

      getBets({
        params: `?${query}`,
        onSuccess: () => {
          this.setState({
            currentTableItems: [],
            sortingParameter: order_by.field,
            loading: false
          });
        },
        onError: () => {
          this.setState({ loading: false });
        }
      });
    }
  }

  get search() {
    const { location } = this.props;
    const { order_by } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return { order_by };
  }

  pushToSearch = (searchParams) => {
    const { history } = this.props;
    const search = { ...this.search };

    Object.entries(searchParams).forEach(([key, value]) => {
      if (value) search[key] = value;
      else delete search[key];
    });

    history.push(`?${qs.stringify(search)}`);
  };

  handleOnSort = async (option) => {
    const { sortingParameter } = this.state;
    const { location } = this.props;
    const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });

    const { order_by } = this.search;
    const field = order_by && order_by.field || sortingParameter;
    const currentColumn = field === tableHeadMap[option];
    const directionAsc = (order_by && order_by.direction) === 'asc';
    const reverseDirection = currentColumn && directionAsc ? 'desc' : 'asc';

    this.setState({
      currentTableItems: [],
      sortingReversed: reverseDirection,
      loading: true
    });

    const sortOption = tableHeadMap[option];
    await this.pushToSearch({ ...searchObj, order_by: { field: sortOption, direction: reverseDirection } });
  };

  handleScroll = async (e) => {
    const currentOffset = e.target.scrollTop;
    const bottom = e.target.scrollHeight - currentOffset - e.target.clientHeight;

    if (bottom < 50) {
      const { location, items, getBets } = this.props;
      const { offset, sortingParameter, sortingReversed, currentTableItems } = this.state;

      const directionDown = currentOffset > offset;

      const lastId = items && items.length && items[items.length - 1].id;
      const lastIdParam = lastId > 0 ? `&last_id=${lastId}` : '';

      if (items.length) {
        this.setState({ offset: currentOffset });
      }

      const query = location.search ?
        qs.parse(location.search, { ignoreQueryPrefix: true }) :
        qs.parse({
          page: { size: pageSize},
          order_by: { field: sortingParameter, direction: sortingReversed }
        });

      if (lastId && items.length && directionDown && (bottom < 30)) {
        this.setState({ loading: true });

        getBets({
          params: `?${qs.stringify(query)}${lastIdParam}`,
          onSuccess: () => {
            const filteredTableItems = currentTableItems
              .filter((x) => items.indexOf(x) < 0)
              .concat(items);

            this.setState({
              loading: false,
              currentTableItems: filteredTableItems,
            });
          },
          onError: () => {
            this.setState({ loading: false });
          }
        });
      }
    }
  };

  render() {
    const { sortingParameter, sortingReversed, loading, currentTableItems } = this.state;
    const { getBets, location, items, instruments, assets, durations, house_profit_sum, stake_profit_sum, priceFormat } = this.props;

    const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });

    const filteredPrevTableItems = items
      ? currentTableItems.filter(x => items.indexOf(x) < 0)
      : currentTableItems;

    const tableItems = currentTableItems ? filteredPrevTableItems.concat(items) : items;

    const currentAccuracy = (asset) => {
      const currentAsset = assets.find(a => a.name === asset);
      return getAccurency((currentAsset && currentAsset.min_tick_movement) || 0);
    };

    const formatStrike = (strike, accuracy, instrument) => {
      const addToStrike = instrument === "over" || instrument === "under" ? 0 : 2;
      return !strike ? '-' : Number(strike).toFixedNoRounding(accuracy + addToStrike);
    };

    return (
      <Layout fullScreen title="Bets">
        {loading && <Preloader backdrop position="fixed"/>}

        <TableWrapper>
          <SearchBar
            getBets={getBets}
            searchObj={searchObj}
            pushToSearch={this.pushToSearch}
            instruments={instruments || []}
            durations={durations || []}
            house_profit_sum={house_profit_sum}
            stake_profit_sum={stake_profit_sum}
            defaultValues={{
              date_from: `${format(new Date, "yyyy-MM-dd")} 00:00:00`,
              date_to: `${format(new Date, "yyyy-MM-dd")} 23:59:59`
            }}
          />

          <TableContent theme="trades" onScroll={this.handleScroll}>
            <Table>
              <TableHead>
                <MUITableRow>
                  {Object.keys(tableHeadMap).map((tableHead => {
                    const active = (tableHeadMap[tableHead] === sortingParameter) && (sortingReversed === 'desc');

                    return (
                      <MUITableCell
                        key={tableHead}
                        active={tableHeadMap[tableHead] === sortingParameter}
                        onClick={() => this.handleOnSort(tableHead)}
                      >
                        <MUITableSort
                          sort={active}
                        >
                          {tableHead}
                        </MUITableSort>
                      </MUITableCell>
                    )
                  }))}
                </MUITableRow>
              </TableHead>

              <TableBody>
                {tableItems.length > 0 ? tableItems.map((item) => (
                  <MUITableRow key={item.id}>
                    <MUITableCell
                      padd="true"
                      bold="true"
                    >
                      {item.id}
                    </MUITableCell>

                    <MUITableCell>
                      <b>{`${item.user.first_name && item.user.first_name} ${item.user.last_name && item.user.last_name}`}</b>
                      <div style={{ textTransform: 'none' }}>{item.user.email && item.user.email}</div>
                    </MUITableCell>

                    <MUITableCell>
                      {renderInstrumentName(item.instrument_name)}
                    </MUITableCell>

                    <MUITableCell>
                      {item.outcome}
                    </MUITableCell>

                    <MUITableCell>
                      {item.duration && `${item.duration.count} ${item.duration.unit}`}
                    </MUITableCell>

                    <MUITableCell>
                      {item.expires_at && <DateWrap>{format(parseISO(item.expires_at), "MM/dd/yyyy HH:mm:ss")}&nbsp;UTC</DateWrap>}
                    </MUITableCell>

                    <MUITableCell>
                      {item.initial_price ? item.initial_price.toFixedNoRounding(currentAccuracy(item.asset_name)) : '-'}
                    </MUITableCell>

                    <MUITableCell>
                      {!item.strike_1 || item.strike_1 === '-'
                        ? '-'
                        : formatStrike(item.strike_1, currentAccuracy(item.asset_name), item.instrument_name)}
                    </MUITableCell>

                    <MUITableCell>
                      {!item.strike_2 || item.strike_2 === '-'
                        ? '-'
                        : formatStrike(item.strike_2, currentAccuracy(item.asset_name), item.instrument_name)}
                    </MUITableCell>

                    <MUITableCell>{item.amount}</MUITableCell>

                    <MUITableCell>
                      {probabilityToFixedOdds(item.initial_probability, item.probability_format || 'percent')}
                    </MUITableCell>

                    <MUITableCell>{item.inserted_at && <DateWrap>{format(parseISO(item.inserted_at), "MM/dd/yyyy HH:mm:ss")}&nbsp;UTC</DateWrap>}</MUITableCell>

                    <MUITableCell>{item.status}</MUITableCell>

                    <MUITableCell>
                      {item.final_price ? item.final_price.toFixedNoRounding(currentAccuracy(item.asset_name)) : '-'}
                    </MUITableCell>

                    <MUITableCell>
                      {item.payout || item.payout === 0 ? item.payout.toFixedNoRounding(3) : '-'}
                    </MUITableCell>

                    <MUITableCell>
                      {item.house_profit || item.house_profit === 0 ? item.house_profit.toFixed(3) : '-'}
                    </MUITableCell>

                    <MUITableCell>
                      {item.expired_at && <DateWrap>{format(parseISO(item.expired_at), "MM/dd/yyyy HH:mm:ss")}&nbsp;UTC</DateWrap>}
                    </MUITableCell>
                  </MUITableRow>
                )) : null}
              </TableBody>
            </Table>
          </TableContent>
        </TableWrapper>
      </Layout>
    );
  }
}

const mapState = (state) => ({
  house_profit_sum: state.bets.house_profit_sum,
  stake_profit_sum: state.bets.stake_profit_sum,
  items: state.bets.items,
  instruments: state.instruments,
  durations: state.durations,
  assets: state.assets,
  priceFormat: state.currentUser.probability_format
});

const mapDispatch = {
  getBets: getBetsRequest,
  getAssets: getAssetsRequest,
  getDurations: getDurationsRequest,
  getInstruments: getInstrumentsRequest
};

export default connect(mapState, mapDispatch)(BetsPage);
