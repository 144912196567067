import { handleActions } from "redux-actions";
import initialState from "../../config/initialState";

import {
  GET_BANKROLLER_RATES_SUCCESS,
  GET_BANKROLLER_RATES_FAIL,
  EDIT_BANKROLLER_RATES_SUCCESS,
  EDIT_BANKROLLER_RATES_FAIL,
  GET_BANKROLLER_INVESTMENT_SUCCESS,
  GET_BANKROLLER_INVESTMENT_FAIL,
  GET_BANKROLLER_SPECIAL_USERS_SUCCESS,
  GET_BANKROLLER_SPECIAL_USERS_FAIL,
  SET_ROLE_SPECIAL_USERS_SUCCESS,
  SET_ROLE_SPECIAL_USERS_FAIL,
  CURRENT_SPECIAL_STAKES_SUCCESS,
  CURRENT_SPECIAL_STAKES_FAIL
} from "../actions/bankRoller";

export default handleActions(
  {
    [GET_BANKROLLER_RATES_SUCCESS]: (state, action) => {
      const { payload: { data } } = action;

      return {
        ...state,
        rates: data
      };
    },
    [GET_BANKROLLER_RATES_FAIL]: () => initialState.bankRoller,

    [EDIT_BANKROLLER_RATES_SUCCESS]: (state, action) => {
      const { payload } = action;
      const newRates = state.rates.map(rate => rate.id === payload.id ? payload : rate);

      return {
        ...state,
        rates: [
          ...newRates
        ]
      };
    },
    [EDIT_BANKROLLER_RATES_FAIL]: (state) => {
      return {
        ...state
      };
    },

    [GET_BANKROLLER_INVESTMENT_SUCCESS]: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        investments: payload
      };
    },
    [GET_BANKROLLER_INVESTMENT_FAIL]: (state) => {
      return {
        ...state
      };
    },

    [GET_BANKROLLER_SPECIAL_USERS_SUCCESS]: (state, action) => {
      const { payload: { data } } = action;

      return {
        ...state,
        users: data
      };
    },
    [GET_BANKROLLER_SPECIAL_USERS_FAIL]: (state) => {
      return {
        ...state
      };
    },

    [SET_ROLE_SPECIAL_USERS_SUCCESS]: (state) => {
      return {
        ...state
      };
    },
    [SET_ROLE_SPECIAL_USERS_FAIL]: (state) => {
      return {
        ...state
      };
    },

    [CURRENT_SPECIAL_STAKES_SUCCESS]: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        specialStakes: payload
      };
    },
    [CURRENT_SPECIAL_STAKES_FAIL]: (state) => {
      return {
        ...state
      };
    },
  },
  initialState.bankRoller
);
