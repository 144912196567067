import { takeEvery, call, put } from "redux-saga/effects";

import apiCaller from "../../utils/apiCaller";
import {
  GET_PROFITABILITY_REQUEST,
  getProfitabilitySuccess,
  getProfitabilityFail,
  GET_PROFITABILITY_SUMMARY_REQUEST,
  getProfitabilitySummarySuccess,
  getProfitabilitySummaryFail,
} from "../actions/profitability";
import { HTTP_METHODS } from "../../config";

function* getProfitability({
  payload: { onSuccess, onError, params = "", type = "last_day" },
}) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: `/api/admin/bets/profit${params}`,
      token: true,
    });

    response["type"] = type;

    if (onSuccess) {
      yield call(onSuccess);
    }
    const successAction = yield call(getProfitabilitySuccess, response);

    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getProfitabilityFail, err);
    yield put(errAction);
  }
}


function* getProfitabilitySummary({
  payload: { onSuccess, onError, params = "" },
}) {
  try {
    const response = yield call(apiCaller, HTTP_METHODS.GET, {
      url: `/api/admin/bets/profit/stages${params}`,
      token: true,
    });

    if (onSuccess) {
      yield call(onSuccess);
    }

    const successAction = yield call(getProfitabilitySummarySuccess, response);

    yield put(successAction);
  } catch (err) {
    if (onError) {
      yield call(onError);
    }

    const errAction = yield call(getProfitabilitySummaryFail, err);
    yield put(errAction);
  }
}

export default function*() {
  yield takeEvery(GET_PROFITABILITY_REQUEST, getProfitability);
  yield takeEvery(GET_PROFITABILITY_SUMMARY_REQUEST, getProfitabilitySummary);
}
