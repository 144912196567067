import React from "react";

import "./styles.scss";

const Card = ({ title, amount }) => (
  <div className="card">
    <div className="card__title">{title}:</div>
    <div className="card__value">
      {amount.toFixed(3)} <div className="card__value_flex">USDT</div>
    </div>
  </div>
);

export default Card;
