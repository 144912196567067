import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  && {
    color: rgba(0, 0, 0, 0.87);
    font-family: Helvetica;
  }
`;

export default props => <StyledLink {...props} />;
