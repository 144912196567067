import React, { Component } from "react";
import {connect} from "react-redux";
import qs from "qs";
import { format } from "date-fns";

import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";

import { getManagementRequest, getManagementDurationRequest } from "../../store/actions/riskAnalysis";
import { getInstrumentsRequest } from "../../store/actions/instruments";
import { getAssetsRequest } from "../../store/actions/assets";

import INSTRUMENTS from "../../config/instrumentsName";
import sortPairsFunc from '../../helpers/sortPairs';
import { renderAssetName } from '../../helpers/renderAssetName';

import {
  TableWrapper,
  MUITableCell,
  MUITableRow,
  TableContent,
  MUITableCellHalf
} from "../../components/Mui";
import Layout from "../../components/Layout";
import Preloader from "../../components/Preloader";
import SearchBar from "./components/SearchBar";

const orderInstrument = {
  pump: 1,
  dump: 2,
  big_pump: 3,
  big_dump: 4,
  touch_up: 5,
  touch_down: 6,
  double_crash: 7,
  flatline: 8,
  сash_or_сrash: 9,
  crash_or_cash: 10
};

const title = (instrumentName) => {
  switch (instrumentName) {
    case INSTRUMENTS.OVER: return 'Pump';
    case INSTRUMENTS.UNDER: return 'Dump';
    case INSTRUMENTS.HIGHLINE: return 'Big Pump';
    case INSTRUMENTS.LOWLINE: return 'Big Dump';
    case INSTRUMENTS.HIGHTOUCH: return 'Touch Up';
    case INSTRUMENTS.LOWTOUCH: return 'Touch Down';
    case INSTRUMENTS.DOUBLE_NO_TOUCH: return 'Double Crash';
    case INSTRUMENTS.TUNNEL: return 'Flatline';
    case INSTRUMENTS.OVER_TUG_OF_WAR: return 'Cash or Crash';
    case INSTRUMENTS.UNDER_TUG_OF_WAR: return 'Crash or Cash';
    default: return '';
  }
};

class RiskAnalysisManagement extends Component {
  state = {
    loading: false
  };

  async componentDidMount() {
    const { getInstruments, getAssets } = this.props;

    await getInstruments({ });
    await getAssets({ });

    await this.fetchRiskMatrix();

    this.fetchMatrixInterval = setInterval(() => {
      this.fetchRiskMatrix();
    }, 15000);
  }

  componentDidUpdate(prevProps) {
    const { location: { search } } = this.props;
    const { location: { search: prevSearch } } = prevProps;

    if (search !== prevSearch) {
      this.fetchRiskMatrix();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchMatrixInterval);
  }

  get search() {
    const { location } = this.props;
    const { order_by } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return { order_by };
  }

  fetchRiskMatrix = () => {
    const { location, getManagement } = this.props;
    const queryStr = qs.parse(location.search, { ignoreQueryPrefix: true });

    const query = location.search ?
      qs.stringify(queryStr) :
      qs.stringify({
        date_from: `${format(new Date, "yyyy-MM-dd")} 00:00:00`,
        date_to:  `${format(new Date, "yyyy-MM-dd")} 23:59:59`
      });

    getManagement({
      params: `?${query}`,
      onSuccess: () => {
        this.setState({ loading: false });
      },
      onError: () => {
        this.setState({ loading: false });
      }
    });
  };

  pushToSearch = (searchParams) => {
    const { history } = this.props;
    const search = { ...this.search };

    Object.entries(searchParams).forEach(([key, value]) => {
      if (value) search[key] = value;
      else delete search[key];
    });

    history.push(`?${qs.stringify(search)}`);
  };

  render() {
    const { instruments, assets, location, items } = this.props;
    const { loading } = this.state;
    const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });

    const sortPairs = assets && assets.length ? sortPairsFunc(assets) : [];

    const sortInstrument = instruments && instruments.length
      ? instruments.sort((l,r) =>  orderInstrument[l.name] - orderInstrument[r.name])
      : [];

    const newIntrumentsArr = sortInstrument.map(instrument => {
      const assetsArr = sortPairs.map(asset => {
        const assetObj = items && items.length ? items.find(item => item.asset === asset.name) : [];
        const instrumentObj = assetObj && assetObj.instruments && assetObj.instruments.find(r => r.name === instrument.name);
        const riskVal = instrumentObj ? instrumentObj.risk : 0;
        const houseProfit = instrumentObj ? instrumentObj.house_profit : 0;

        return ({
          name: asset.name,
          houseProfit: houseProfit,
          riskVal: `${riskVal}`
        })
      });

      return ({
        ...instrument,
        assets: [...assetsArr]
      })
    });

    return (
      <Layout fullScreen title="Risk Matrix">
        {loading && <Preloader backdrop position="fixed"/>}

        <TableWrapper>
          <SearchBar
            searchObj={searchObj}
            pushToSearch={this.pushToSearch}
            defaultValues={{
              date_from: `${format(new Date, "yyyy-MM-dd")} 00:00:00`,
              date_to: `${format(new Date, "yyyy-MM-dd")} 23:59:59`
            }}
          />

          <TableContent theme="management">
            <Table>
              <TableHead>
                <MUITableRow>
                  <MUITableCell />

                  {sortPairs.length > 0 ? sortPairs.map((pair) => (
                    <MUITableCell style={{ textAlign: 'center' }} key={pair.id}>
                      {renderAssetName(pair.name)}
                    </MUITableCell>
                  )) : null}
                </MUITableRow>
              </TableHead>

              <TableBody>
                {newIntrumentsArr.length ? newIntrumentsArr.map((instrument) => (
                  <MUITableRow key={instrument.id}>
                    <MUITableCell
                      padd="true"
                      bold="true"
                      style={{ textAlign: 'left', minWidth: 100, width: 150 }}
                    >
                      {title(instrument.name)}
                    </MUITableCell>

                    {instrument.assets && instrument.assets.length > 0 ? instrument.assets.map((asset) => {
                      const colorTableCell = () => {
                        if (asset.riskVal > 0.1) {
                          return 'rgb(19, 127, 171)'
                        } else if (asset.riskVal > 0) {
                          return 'rgb(67, 189, 238)'
                        } else if (asset.riskVal <  -0.1) {
                          return 'rgb(173, 29, 29)'
                        } else if (asset.riskVal < 0) {
                          return 'rgb(249, 59, 59)'
                        } else if (asset.riskVal === 0) {
                          return 'rgb(98 183 142)'
                        } else {
                          return 'rgb(98 183 142)'
                        }
                      };

                      return (
                        <MUITableCell
                          style={{
                            textAlign: 'center',
                            backgroundColor: `${colorTableCell()}`,
                            color: '#fff',
                            padding: 0,
                            width: 'auto'
                          }}
                          key={asset.name}>
                          <MUITableCellHalf>
                            <svg>
                              <line
                                strokeDasharray="5, 5"
                                x1="0"
                                y1="100%"
                                x2="100%"
                                y2="0"
                                style={{ stroke: '#ccc', strokeWidth: 1 }}/>
                            </svg>

                            <span>
                              {asset.riskVal || asset.riskVal === 0
                                ? `${(asset.riskVal * 100).toFixed()}%`
                                : '-'}
                            </span>

                            <span>{(asset.houseProfit && asset.houseProfit.toFixed(1)) || 0}</span>
                          </MUITableCellHalf>
                        </MUITableCell>
                      )
                    }) : null}
                  </MUITableRow>
                )) : null}
              </TableBody>
            </Table>
          </TableContent>
        </TableWrapper>
      </Layout>
    );
  }
}

const mapState = (state) => ({
  instruments: state.instruments,
  assets: state.assets,
  items: state.riskAnalysis.items
});

const mapDispatch = {
  getInstruments: getInstrumentsRequest,
  getAssets: getAssetsRequest,
  getManagement: getManagementRequest,
  getManagementDuration: getManagementDurationRequest
};

export default connect(mapState, mapDispatch)(RiskAnalysisManagement);
