export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};
// 2m
export const LOGOUT_OFFSET = 2 * 60 * 1000;

export const TOKEN_KEY = "token";
export const COOKIE_EXPIRATION_KEY = "cookie_expiration_date";
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "";
