import { COOKIE_EXPIRATION_KEY, LOGOUT_OFFSET } from "../config";

import countDownTimer from "../helpers/countDownTimer";

export default (
  setTimerId,
  removeTimerId,
  extendExpiryDate,
  timerId,
  enableTimer
) => {
  const expirationDate = new Date(
    window.localStorage.getItem(COOKIE_EXPIRATION_KEY)
  );
  let timeoutId = null;
  const expirationDateMs = expirationDate.getTime();
  const storeTimerId = timerId;

  const difference = expirationDateMs - +new Date();
  if (expirationDateMs > 0 && difference > 0 && difference < LOGOUT_OFFSET) {
    const timerId = countDownTimer(
      expirationDate,
      difference,
      extendExpiryDate,
      removeTimerId,
      storeTimerId
    );
    setTimerId({ timerId });
  }
  if (expirationDateMs > 0 && difference > 0 && difference > LOGOUT_OFFSET) {
    const timeoutOffset = expirationDateMs - new Date() - LOGOUT_OFFSET;

    timeoutId = setTimeout(() => {
      const timerId = countDownTimer(
        expirationDate,
        LOGOUT_OFFSET,
        extendExpiryDate,
        removeTimerId,
        storeTimerId
      );
      setTimerId({ timerId });
    }, timeoutOffset);
    enableTimer({});
  }

  return timeoutId;
};
