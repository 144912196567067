import { all, spawn } from "redux-saga/effects";
import SignIn from "./signIn";
import assets from "./assets";
import instruments from "./instruments";
import durations from "./durations";
import bets from "./bets";
import users from "./users";
import profitability from "./profitability";
import bankRoller from "./bankRoller";
import currentUser from "./currentUser";
import affiliates from "./affiliates";
import riskAnalysis from "./riskAnalysis";

function* rootSaga() {
  yield all([
    spawn(SignIn),
    spawn(assets),
    spawn(instruments),
    spawn(durations),
    spawn(bets),
    spawn(users),
    spawn(profitability),
    spawn(bankRoller),
    spawn(currentUser),
    spawn(affiliates),
    spawn(riskAnalysis),
  ]);
}

export default rootSaga;
