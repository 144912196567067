import styled from "styled-components";

export const FormWrap = styled.div`
  margin-bottom: 20px;
  padding: 14px 20px 20px;
  background-color: #fdfcfc;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;

export const HouseProfitSum = styled.div`
  display: inline-flex;
  align-items: baseline;
  margin: 15px 0 0;
  line-height: 24px;
`;

export const HouseProfitSumTitle = styled.span`
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-weight: bold;
`;

export const HouseProfitSumValue = styled.span`
  ${({ theme }) => theme > 0 && `color: green`}
  ${({ theme }) => theme < 0 && `color: red`}
`;
