export default {
  'OVER'            : 'over',
  'UNDER'           : 'under',
  'HIGHLINE'        : 'highline',
  'LOWLINE'         : 'lowline',
  'HIGHTOUCH'       : 'hightouch',
  'LOWTOUCH'        : 'lowtouch',
  'TUNNEL'          : 'tunnel',
  'DOUBLE_NO_TOUCH' : 'double_no_touch',
  'OVER_TUG_OF_WAR' : 'over_tug_of_war',
  'UNDER_TUG_OF_WAR': 'under_tug_of_war',
  'HI'              : 'hi',
  'LO'              : 'lo',
  'HILO'            : 'hilo',
  'HITOUCH'         : 'hitouch',
  'LOTOUCH'         : 'lotouch',
  'IN_OR_OUT'       : 'in_or_out',
  'DOUBLE_CRASH'    : 'double_crash',
  'CASH_OR_CRASH'   : 'cash_or_crash'
};
