import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getBetsStateRequest,
  toggleTradingStateRequest,
  getMasterWalletBalanceRequest
} from "../../store/actions/bets";

import { updateCurrentUserRequest } from "../../store/actions/currentUser";
import withNotifications from "../../helpers/withNotifications";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Layout from "../../components/Layout";

import * as S from "./styles";

class Maintenance extends Component {
  componentDidMount() {
    const { getBetsState, getMasterWalletBalance } = this.props;

    getBetsState({});
    getMasterWalletBalance({});
  }

  onToogleTradingClick = () => {
    const {activeBets, toggleTradingState} = this.props;

    if (activeBets.disabled) {
      toggleTradingState({
        onSuccess: () => {
          window.location.reload()
        }
      });
    } else {
      const confirmed = window.confirm('This will disable creation of new bets until the system is re-deployed. Continue?');
      if (confirmed) {
        toggleTradingState({
          onSuccess: () => {
            window.location.reload()
          }
        });
      }
    }
  };

  render() {
    const { activeBets, masterWalletBalance, masterWalletAddress } = this.props;

    return (
      <Layout title="Maintenance">
        <S.MaintananceContainer>
          {masterWalletBalance ? (
            <Card style={{ padding: 16 }}>
              <Typography variant="h6">
                Master Balance <b>{Number(masterWalletBalance).toFixedNoRounding(4)}</b> USDT
              </Typography>

              {masterWalletAddress && (
                <Typography variant="h6">
                  Master Address: <a href={`https://explorer.bitcoin.com/bch/address/${masterWalletAddress}`} target="_blank">{masterWalletAddress}</a>
                </Typography>
              )}
            </Card>
          ) : null}

          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Trading
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Status:{" "}
                  <S.TradingStatus disabled={activeBets.disabled}>
                    {activeBets.disabled ? "disabled" : "allowed"}
                  </S.TradingStatus>
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Active trades: {activeBets.count}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                type="button"
                size="small"
                variant="contained"
                onClick={this.onToogleTradingClick}
                color={activeBets.disabled ? "primary" : "secondary"}
              >
                {activeBets.disabled ? "Enable trading" : "Disable trading"}
              </Button>
            </CardActions>
          </Card>
        </S.MaintananceContainer>
      </Layout>
    );
  }
}

const mapState = (state) => ({
  activeBets: state.bets.activeBets,
  masterWalletBalance: state.bets.masterWalletBalance,
  masterWalletAddress: state.bets.masterWalletAddress
});

const mapDispatch = {
  getBetsState: getBetsStateRequest,
  toggleTradingState: toggleTradingStateRequest,
  getMasterWalletBalance: getMasterWalletBalanceRequest,
  updateCurrentUser: updateCurrentUserRequest
};

export default withNotifications(connect(mapState, mapDispatch)(Maintenance));
