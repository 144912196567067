export default {
  assets: [],
  instruments: [],
  durations: [],
  affiliates: {
    items: [],
    house_profit_sum: 0,
    stake_profit_sum: 0,
  },
  bets: {
    items: [],
    house_profit_sum: 0,
    stake_profit_sum: 0,
    activeBets: {
      disabled: false,
      count: 0,
    },
    masterWalletBalance: 0,
    masterWalletAddress: null
  },
  users: [],
  currentUser: {
    isTimerEnabled: false,
    timerId: null,
    probability_format: null
  },
  profitability: {
    last_day: 0,
    last_week: 0,
    last_month: 0,
  },
  bankRoller: {
    rates: [],
    investments: {},
    users: [],
    specialStakes: {},
  },
  riskAnalysis: {
    items: [],
    durations: []
  }
};
