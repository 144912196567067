import React from 'react';
import * as S from './styles';

const Switcher = ({ isChecked, handleChange }) => (
  <S.Label onClick={handleChange}>
    <S.Input defaultChecked={isChecked} />
    <S.CheckboxSwitcher />
  </S.Label>
);

Switcher.defaultProps = {
  isChecked: false
};

export default Switcher;
