// @flow

const probabilityToFixedOdds = (probability: number, format: string = 'percent'): string => {
  if (format.toLowerCase() === 'percent') {
    const calculated = probability <= 0.5 ? Math.round(100 * (1 / probability - 1)) : (100 * (1 / probability - 1)).toFixed(1);
    return `${typeof calculated === 'string' ? calculated.replace('.0', '') : calculated}%`;
  }

  if (format.toLowerCase() === 'multiplier') {
    const calculated = probability <= 0.5 ? Math.round(100 * (1 / probability - 1)) : (100 * (1 / probability - 1)).toFixedNoRounding(2);
    return `${((Number(calculated) + 100) / 100).toFixed(2)}x`;
  }

  if (format.toLowerCase() === 'uk') {
    let fixedOdds;
    /* eslint-disable-next-line */
    probability = +probability.toFixed(10);

    if (probability < 0.000999001) fixedOdds = '1000/1';

    if (probability >= 0.0009990011 && probability <= 0.001109878) fixedOdds = '900/1';
    if (probability >= 0.0011098781 && probability <= 0.0012484396) fixedOdds = '800/1';
    if (probability >= 0.0012484397 && probability <= 0.0014265336) fixedOdds = '700/1';
    if (probability >= 0.0014265337 && probability <= 0.0016638936) fixedOdds = '600/1';

    if (probability >= 0.0016638937 && probability <= 0.0019960081) fixedOdds = '500/1';
    if (probability >= 0.0019960082 && probability <= 0.002217295) fixedOdds = '450/1';
    if (probability >= 0.0022172951 && probability <= 0.0024937657) fixedOdds = '400/1';
    if (probability >= 0.0024937658 && probability <= 0.0028490029) fixedOdds = '350/1';

    if (probability >= 0.002849003 && probability <= 0.0033222592) fixedOdds = '300/1';
    if (probability >= 0.0033222593 && probability <= 0.0039840638) fixedOdds = '250/1';
    if (probability >= 0.0039840639 && probability <= 0.0049751245) fixedOdds = '200/1';
    if (probability >= 0.0049751246 && probability <= 0.0066225167) fixedOdds = '150/1';

    if (probability >= 0.0066225168 && probability <= 0.0099009902) fixedOdds = '100/1';
    if (probability >= 0.0099009903 && probability <= 0.0131578948) fixedOdds = '75/1';
    if (probability >= 0.0131578949 && probability <= 0.0196078432) fixedOdds = '50/1';
    if (probability >= 0.0196078433 && probability <= 0.024390244) fixedOdds = '40/1';

    if (probability >= 0.0243902441 && probability <= 0.0322580646) fixedOdds = '30/1';
    if (probability >= 0.0322580647 && probability <= 0.0384615386) fixedOdds = '25/1';
    if (probability >= 0.0384615387 && probability <= 0.0476190477) fixedOdds = '20/1';
    if (probability >= 0.0476190478 && probability <= 0.0625000001) fixedOdds = '15/1';

    if (probability >= 0.0625000002 && probability <= 0.0666666668) fixedOdds = "14/1"
    if (probability >= 0.0666666669 && probability <= 0.0714285715) fixedOdds = "13/1"
    if (probability >= 0.0714285716 && probability <= 0.076923077) fixedOdds = "12/1"
    if (probability >= 0.0769230771 && probability <= 0.0833333334) fixedOdds = "11/1"

    if (probability >= 0.0833333335 && probability <= 0.090909091) fixedOdds = "10/1"
    if (probability >= 0.0909090911 && probability <= 0.1000000029) fixedOdds = "9/1"
    if (probability >= 0.100000003 && probability <= 0.1111111112) fixedOdds = "8/1"
    if (probability >= 0.1111111113 && probability <= 0.1250000001) fixedOdds = "7/1"

    if (probability >= 0.1250000002 && probability <= 0.1428571429) fixedOdds = "6/1"
    if (probability >= 0.142857143 && probability <= 0.1666666668) fixedOdds = "5/1"
    if (probability >= 0.1666666669 && probability <= 0.1818181819) fixedOdds = "9/2"
    if (probability >= 0.181818182 && probability <= 0.2000000001) fixedOdds = "4/1"

    if (probability >= 0.2000000002 && probability <= 0.2222222223) fixedOdds = "7/2"
    if (probability >= 0.2222222224 && probability <= 0.2352941176) fixedOdds = "13/4"
    if (probability >= 0.2352941177 && probability <= 0.2500000001) fixedOdds = "3/1"
    if (probability >= 0.2500000002 && probability <= 0.2666666668) fixedOdds = "11/4"

    if (probability >= 0.2666666669 && probability <= 0.2857142857) fixedOdds = "5/2"
    if (probability >= 0.2857142858 && probability <= 0.3076923077) fixedOdds = "9/4"
    if (probability >= 0.3076923078 && probability <= 0.3333333334) fixedOdds = "2/1"
    if (probability >= 0.3333333335 && probability <= 0.347826087) fixedOdds = "15/8"

    if (probability >= 0.3478260871 && probability <= 0.3571428571) fixedOdds = "9/5"
    if (probability >= 0.3571428572 && probability <= 0.3636363636) fixedOdds = "7/4"
    if (probability >= 0.3636363637 && probability <= 0.375) fixedOdds = "5/3"
    if (probability >= 0.3750000001 && probability <= 0.380952381) fixedOdds = "13/8"

    if (probability >= 0.3809523811 && probability <= 0.3846153846) fixedOdds = "8/5"
    if (probability >= 0.3846153847 && probability <= 0.4) fixedOdds = "6/4"
    if (probability >= 0.4000000001 && probability <= 0.4166666667) fixedOdds = "7/5"
    if (probability >= 0.4166666668 && probability <= 0.4210526316) fixedOdds = "11/8"

    if (probability >= 0.4210526317 && probability <= 0.4285714286) fixedOdds = "4/3"
    if (probability >= 0.4285714287 && probability <= 0.4347826087) fixedOdds = "13/10"
    if (probability >= 0.4347826088 && probability <= 0.4444444444) fixedOdds = "5/4"
    if (probability >= 0.4444444445 && probability <= 0.4545454545) fixedOdds = "6/5"

    if (probability >= 0.4545454546 && probability <= 0.4615384615) fixedOdds = "7/6"
    if (probability >= 0.4615384616 && probability <= 0.4705882353) fixedOdds = "9/8"
    if (probability >= 0.4705882354 && probability <= 0.4761904762) fixedOdds = "11/10"
    if (probability >= 0.4761904763 && probability <= 0.487804878) fixedOdds = "21/20"

    if (probability >= 0.4878048781 && probability <= 0.5) fixedOdds = "Evens"

    if (probability >= 0.5000000001 && probability <= 0.512195122) fixedOdds = "20/21"
    if (probability >= 0.5121951221 && probability <= 0.5238095238) fixedOdds = "10/11"
    if (probability >= 0.5238095239 && probability <= 0.5294117647) fixedOdds = "8/9"
    if (probability >= 0.5294117648 && probability <= 0.5384615385) fixedOdds = "6/7"

    if (probability >= 0.5384615386 && probability <= 0.5454545455) fixedOdds = "5/6"
    if (probability >= 0.5454545456 && probability <= 0.5555555556) fixedOdds = "4/5"
    if (probability >= 0.5555555557 && probability <= 0.5652173913) fixedOdds = "10/13"
    if (probability >= 0.5652173914 && probability <= 0.5714285714) fixedOdds = "3/4"

    if (probability >= 0.5714285715 && probability <= 0.5789473684) fixedOdds = "8/11"
    if (probability >= 0.5789473685 && probability <= 0.5833333333) fixedOdds = "5/7"
    if (probability >= 0.5833333334 && probability <= 0.6) fixedOdds = "4/6"
    if (probability >= 0.6000000001 && probability <= 0.6153846154) fixedOdds = "5/8"

    if (probability >= 0.6153846155 && probability <= 0.619047619) fixedOdds = "8/13"
    if (probability >= 0.6190476191 && probability <= 0.625) fixedOdds = "3/5"
    if (probability >= 0.6250000001 && probability <= 0.6363636364) fixedOdds = "4/7"
    if (probability >= 0.6363636365 && probability <= 0.6428571429) fixedOdds = "5/9"

    if (probability >= 0.642857143 && probability <= 0.652173913) fixedOdds = "8/15"
    if (probability >= 0.6521739131 && probability <= 0.6666666667) fixedOdds = "1/2"
    if (probability >= 0.6666666668 && probability <= 0.6923076923) fixedOdds = "4/9"
    if (probability >= 0.6923076924 && probability <= 0.7142857143) fixedOdds = "2/5"

    if (probability >= 0.7142857144 && probability <= 0.7333333333) fixedOdds = "4/11"
    if (probability >= 0.7333333334 && probability <= 0.75) fixedOdds = "1/3"
    if (probability >= 0.7500000001 && probability <= 0.7647058824) fixedOdds = "4/13"
    if (probability >= 0.7647058825 && probability <= 0.7777777778) fixedOdds = "2/7"

    if (probability >= 0.7777777779 && probability <= 0.8) fixedOdds = "1/4"
    if (probability >= 0.8000000001 && probability <= 0.8181818182) fixedOdds = "2/9"
    if (probability >= 0.8181818182 && probability <= 0.8333333333) fixedOdds = "1/5"
    if (probability >= 0.8333333334 && probability <= 0.8571428571) fixedOdds = "1/6"

    if (probability >= 0.8571428572 && probability <= 0.875) fixedOdds = "1/7"
    if (probability >= 0.8750000001 && probability <= 0.8888888889) fixedOdds = "1/8"
    if (probability >= 0.888888889 && probability <= 0.9) fixedOdds = "1/9"
    if (probability >= 0.9000000001 && probability <= 0.9090909091) fixedOdds = "1/10"

    if (probability >= 0.9090909092 && probability <= 0.9166666667) fixedOdds = "1/11"
    if (probability >= 0.9166666668 && probability <= 0.9230769231) fixedOdds = "1/12"
    if (probability >= 0.9230769232 && probability <= 0.9285714286) fixedOdds = "1/13"
    if (probability >= 0.9285714287 && probability <= 0.9333333333) fixedOdds = "1/14"

    if (probability >= 0.9333333334 && probability <= 0.9375) fixedOdds = "1/15"
    if (probability >= 0.9375000001 && probability <= 0.9523809524) fixedOdds = "1/20"
    if (probability >= 0.9523809525 && probability <= 0.9615384615) fixedOdds = "1/25"
    if (probability >= 0.9615384616 && probability <= 0.9677419355) fixedOdds = "1/30"

    if (probability >= 0.9677419356 && probability <= 0.9756097561) fixedOdds = "1/40"
    if (probability >= 0.9756097562 && probability <= 0.9803921569) fixedOdds = "1/50"
    if (probability >= 0.980392157 && probability <= 0.9868421053) fixedOdds = "1/75"
    if (probability >= 0.9868421053 && probability <= 0.9900990099) fixedOdds = "1/100"

    if (probability >= 0.99009901 && probability <= 0.9933774834) fixedOdds = "1/150"
    if (probability >= 0.9933774835 && probability <= 0.9950248756) fixedOdds = "1/200"
    if (probability >= 0.9950248757 && probability <= 0.9960159363) fixedOdds = "1/250"
    if (probability >= 0.9960159364 && probability <= 0.9966777409) fixedOdds = "1/300"

    if (probability >= 0.996677741 && probability <= 0.9971509972) fixedOdds = "1/350"
    if (probability >= 0.9971509973 && probability <= 0.9975062344) fixedOdds = "1/400"
    if (probability >= 0.9975062345 && probability <= 0.9977827051) fixedOdds = "1/450"
    if (probability >= 0.9977827052 && probability <= 0.998003992) fixedOdds = "1/500"

    if (probability >= 0.9980039921 && probability <= 0.9983361065) fixedOdds = "1/600"

    if (probability >= 0.9983361066 && probability <= 0.9985734665) fixedOdds = "1/700"
    if (probability >= 0.9985734666 && probability <= 0.9987515605) fixedOdds = "1/800"
    if (probability >= 0.9987515606 && probability <= 0.9988901221) fixedOdds = "1/900"

    if (probability >= 0.9988901222) fixedOdds = "1/1000";

    return fixedOdds || 'Unable to calculate fixedOdds';
  }
}

export {
  probabilityToFixedOdds,
}
