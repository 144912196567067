import React, { useState, useEffect } from "react";
import Input from "@material-ui/core/Input";
import { MUIButton } from "../../../../components/Mui";
import * as S from './styles';

const Card = ({ rate, handleUpdateRate }) => {
  const currentValue = (rate.value * 100).toFixed(0);
  const [rateValue, setRate] = useState(currentValue || 0);
  
  useEffect(() => {
    const currentValue = (rate.value * 100).toFixed(0);
    setRate(currentValue);
  }, [rate.value]);

  const newRate = {
    id: rate.id,
    value: (rateValue / 100)
  };

  const title = () => {
    switch (rate.id) {
      case 'profit_ratio': return '3d Party Cut';
      case 'flex_cut': return 'FLEX';
      case 'up_cut': return 'UP';
      default: return '';
    }
  };

  const onUpdate = async () => {
    await handleUpdateRate(rate.id, newRate);

    if (rate.id === 'flex_cut') {
      const rateVal = 100 - rateValue;
      await handleUpdateRate('up_cut', {
        id: 'up_cut',
        value: (rateVal / 100)
      });
    } else if (rate.id === 'up_cut') {
      const rateVal = 100 - rateValue;
      await handleUpdateRate('flex_cut', {
        id: 'flex_cut',
        value: (rateVal / 100)
      });
    } else {
      return false
    }
  };

  const handleUpdate = (e) => {
    let valFilter = e.target.value.replace(/\D/,'');
    let val = +valFilter;

    if (val > 100) {
      val = 100
    } else if (val && val < 0) {
      val = 0
    }

    setRate(val);
  };

  const validateCard = (currentValue === rateValue) || (rateValue > 100) || (rateValue < 0) || !rateValue;

  return (
    <>
      {rate ? (
        <S.Card role={rate.id}>
          <S.Title>{title()}:</S.Title>

          <S.Content>
            <S.InputWrap>
              <Input
                type="text"
                pattern="[0-9]*"
                value={rateValue}
                onChange={handleUpdate}
              />
            </S.InputWrap>

            <MUIButton
              type="button"
              size="medium"
              variant="contained"
              disabled={currentValue === rateValue}
              onClick={() => setRate(currentValue)}
            >
              Reset
            </MUIButton>

            <MUIButton
              type="button"
              size="medium"
              variant="contained"
              color="primary"
              disabled={validateCard}
              onClick={onUpdate}
            >
              Update
            </MUIButton>
          </S.Content>
        </S.Card>
      ) : null}
    </>
  )
};

export default Card;
