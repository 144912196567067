import styled, { css } from "styled-components";

export const Card = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  
  ${({ control, currentControl }) => control && !currentControl && css`
    pointer-events: none;
    opacity: .6;
  `}
  
  &:last-child { margin-bottom: 0; }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #2296f3;
  margin-right: 10px;
  white-space: nowrap;
`;

export const Content = styled.div`
  color: #bdbdbd;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  flex-grow: 1;
  
  & > button {
    margin: 0 10px 0 0!important;
    
    &:last-child { margin: 0!important; }
  }
`;

export const InputWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex-grow: 1;
  
  &:last-child {
    margin-right: 0;
  }
  
  input {
    height: 27px;
    font-size: 14px;
    width: 100%;
  }
  
  ${({ percent }) => percent && css`
    position: relative;
    
    input {
      padding-right: 30px;
    }
    
    &:after {
      content: '%';
      position: absolute;
      right: 0;
      top: 8px;
      z-index: 2;
    }
  `}
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputErrorWrap = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 13px;
  white-space: nowrap;
`;

export const InputError = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
  color: #c6282f;
  
  &:last-child {
    margin-bottom: 0;
  }
`;
